import React, {FunctionComponent} from "react";
import {roundNumber} from "../../../../utils/EshopUtils";
import {Currency} from "../../../../types/Common";
import {join} from "../../../../utils";

interface SelectedVariationStatusProps {
    selectedVariation: any;
    wholesalePrice: any;
    currency: Currency;
}

export const SelectedVariationStatus: FunctionComponent<SelectedVariationStatusProps> = ({
                                                                                             selectedVariation,
                                                                                             wholesalePrice,
                                                                                             currency
                                                                                         }) => {
    return (
        <div
            className="flex items-center justify-between pb-2 border-b-2 border-black">
            <h4 className="text-lg text-gray-900 font-medium">
                {join([join([selectedVariation.volume?.amount, selectedVariation.volume?.unit?.data?.attributes?.code], ' '), selectedVariation.color?.data?.attributes?.name], ', ')}
            </h4>
            <h4 className="text-2xl text-gray-900 font-bold">{roundNumber(wholesalePrice ? selectedVariation.price?.amountWholesale : selectedVariation.price?.amount)} {currency.name}</h4>
        </div>
    )
}