import {SectionHeader} from "../common/SectionHeader";
import {Container} from "../common/Container";
import React, {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import {MAGAZINE_ROUTE} from "../../app";
import Color from 'color';
import {CkEditorDiv} from "../../utils";
import {Product} from "../eshop/product/Product";
import {hasVariationWithStockQuantity} from "../eshop/Eshop";
import {DATE_FULL_OPTIONS, toLocaleDateString, toLocaleTimeStringWithOption} from "../../utils/DateTimeUtils";
import {H3} from "../common/H3";

interface Props {
    currency: any;
    data: any;
}

const ArticleFull: FunctionComponent<Props> = ({currency, data}) => {
    const currentArticle = data.findSlug.data.attributes;

    const sanitizedArticle = DOMPurify.sanitize(currentArticle.text);
    const formattedDate = toLocaleTimeStringWithOption(currentArticle.publishedAt, DATE_FULL_OPTIONS);

    return (
        <Container>
            <Link id={"article"} className={"mb-4 mr-auto ml-0 flex items-center"}
                  to={"/" + MAGAZINE_ROUTE}><ChevronLeftIcon className={"w-7 h-7 mr-1"}/>Naspäť na magazín</Link>
            <header className="mb-6 not-format mx-0 sm:mx-12 md:mx-20 lg:mx-32">
                <address className="flex items-center mb-4 not-italic">
                    <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                        <img className="mr-4 w-20 h-20 rounded-full shadow-md"
                             src="https://res.cloudinary.com/gara/image/upload/v1664308657/media_29_d8c26cabfd.jpg"
                             alt="Zuzana Mikulová"/>
                        <div>
                            <span
                                className="text-xl font-bold text-gray-900 dark:text-white font-sans">Zuzana Mikulová</span>
                            <p className="text-base font-light text-gray-700">Zakladateľka</p>
                            <p className="text-base font-light text-gray-700">
                                <time dateTime={toLocaleDateString(currentArticle.publishedAt)}
                                      title={formattedDate}>{formattedDate}
                                </time>
                            </p>
                        </div>
                    </div>
                </address>
                <div className={"grid gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 mb-8 max-w-md"}>
                    {
                        currentArticle.types.data.map((type: any) => {
                            const color = Color(type.attributes.color);
                            return (
                                <span key={nanoid()} style={{background: type.attributes.color}}
                                      className={"text-xs inline-flex font-medium items-center px-2.5 py-0.5 rounded break-words " + (color?.luminosity() < 0.5 ? "text-white" : "text-black")}>
                                        <img className={"mr-1 w-3 h-3"} src={type.attributes.img.data.attributes.url}
                                             alt={type.attributes.img.data.attributes.alternativeText}/>
                                    {type.attributes.name}
                                    </span>
                            )
                        })
                    }
                </div>
                <SectionHeader heading={currentArticle.heading}/>
            </header>
            <div className={"mx-0 sm:mx-12 md:mx-20 lg:mx-32"}>
                <CkEditorDiv>
                    {parse(sanitizedArticle)}
                </CkEditorDiv>
                {
                    currentArticle.products?.data?.length > 0 ?
                        <>
                            <H3 className={"mt-4 mb-2 text-center"}>Produkty na zakúpenie</H3>
                            <div
                                className={"grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8"}>
                                {currentArticle.products.data.map((product: any) => (
                                    hasVariationWithStockQuantity(product) ?
                                        <Product currency={currency} key={nanoid()}
                                                 getProductsForGivenCategory={null}
                                                 product={product.attributes}
                                                 productId={product.id}/> : null
                                ))}
                            </div>
                        </> : null
                }
            </div>
        </Container>
    );
}

export default ArticleFull;
