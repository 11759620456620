import {SectionHeader} from "../common/SectionHeader";
import React, {FunctionComponent} from "react";
import {Container} from "../common/Container";
import {HeaderLoader} from "../loading/HeaderLoader";
import {ContactLoader} from "../loading/ContactLoader";
import {useQuery} from "@apollo/client";
import {GQL_CONTACT} from "../../api/GraphQL";
import {nanoid} from "nanoid";
import {OPENING_HOURS} from "./Contact";

interface Props {
}


const Contact: FunctionComponent<Props> = () => {

    const {data, loading, error} = useQuery(GQL_CONTACT);

    return (
        <Container>
            {
                loading ?
                    <>
                        <HeaderLoader addDescription={false}/>
                        <ContactLoader/>
                    </> :
                    <>
                        <SectionHeader heading={data.contact.data.attributes.header.heading}
                                       subHeading={data.contact.data.attributes.header.subHeading}
                                       description={data.contact.data.attributes.header.description}/>

                        <div className="mx-auto flex md:flex-nowrap flex-wrap sm:mt-12">
                            <div className="w-full md:w-1/3 flex flex-col md:ml-auto w-full md:mb-8 mt-8 sm:mt-0">
                                <div className="px-4 sm:pl-6 lg:pl-8 lg:col-span-2 mb-12 md:mb-0">
                                    <div className="mx-auto">
                                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Kontaktné
                                            informácie</h2>

                                        <p className="mt-3 text-base leading-6 text-gray-500">V prípade akýchkoľvek
                                            otázok nás
                                            neváhajte kontaktovať. Sme pripravení pomôcť každému, kto sa stratil vo
                                            svojom živote.
                                            Každému, kto sa hľadá a chýba mu ten správny nadhľad. Každému kto má odvahu
                                            a rozhodol
                                            sa vziať svoj život do vlastných rúk.</p>

                                        <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Kontaktné
                                            údaje</h3>
                                        <dl className="mt-2 text-base text-gray-500">
                                            <div>
                                                <dt className="sr-only">Adresa</dt>
                                                <dd>
                                                    <p className={"font-bold text-gray-500"}>Centrum zdravia a
                                                        vzdelávania GARA</p>
                                                    <>Topoľová 812</>
                                                    <p>018 51 Nová Dubnica</p>
                                                </dd>
                                            </div>
                                            <div className="mt-6">
                                                <dt className="sr-only">Telefónne číslo</dt>
                                                <dd className="flex">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              strokeWidth="2"
                                                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                                    </svg>
                                                    <a href="tel:+421903535523" className="no-underline"><span
                                                        className="ml-3">+421 903 535 523</span></a>
                                                </dd>
                                            </div>
                                            <div className="mt-3">
                                                <dt className="sr-only">Telefónne číslo</dt>
                                                <dd className="flex">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              strokeWidth="2"
                                                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                                    </svg>
                                                    <a href="tel:+421908748304" className="no-underline"><span
                                                        className="ml-3">+421 908 748 304</span></a>
                                                </dd>
                                            </div>
                                            <div className="mt-3">
                                                <dt className="sr-only">Email</dt>
                                                <dd className="flex">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              strokeWidth="2"
                                                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                                    </svg>
                                                    <a href="mailto:zuzanamikulova179@gmail.com"
                                                       className="no-underline"><span
                                                        className="ml-3">zuzanamikulova179@gmail.com</span></a>
                                                </dd>
                                            </div>
                                        </dl>

                                        <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Obchodné
                                            informácie</h3>
                                        <dl className="mt-2 text-base text-gray-500">
                                            <div>
                                                <dt className="sr-only">Adresa</dt>
                                                <dd>
                                                    <p className={"font-bold text-gray-500"}>Centrum GARA s.r.o.</p>
                                                    <>Prejtská 228/255</>
                                                    <p className={"mb-2"}>01841 Dubnica nad Váhom</p>

                                                    <p>IČO : 53 428 943</p>
                                                    <p>IČ DPH : SK2121 3747 61</p>
                                                </dd>
                                            </div>
                                        </dl>

                                        <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Otváracie
                                            hodiny</h3>

                                        {OPENING_HOURS.map((oh) => (
                                            <p className={"mt-0.5 text-[1.07rem] text-gray-500"} key={nanoid()}>{oh}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="min-h-[28rem] max-h-[40rem] w-full md:w-2/3 bg-gray-300 rounded-lg overflow-hidden flex items-end justify-start relative">
                                <iframe className="absolute inset-0"
                                        title="map"
                                        src={data.contact.data.attributes.location.map}
                                        width="100%" height="100%"></iframe>
                            </div>
                        </div>
                    </>}
        </Container>
    )
}

export default Contact;
