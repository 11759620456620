import {
    LOGOUT_USER,
    SET_USER,
    USER_ADDRESS,
    USER_CITY,
    USER_COMPANY_DIC,
    USER_COMPANY_IC_DPH,
    USER_COMPANY_ICO,
    USER_COMPANY_NAME,
    USER_CONTACT_EMAIL,
    USER_CURRENT_PASSWORD,
    USER_EMAIL,
    USER_FIRST_NAME,
    USER_JWT,
    USER_LAST_NAME,
    USER_MEMBERSHIP,
    USER_NEW_PASSWORD,
    USER_NEW_PASSWORD_CONFIRMATION,
    USER_REMOVE_PASSWORD_STATES,
    USER_STATE,
    USER_TELEPHONE,
    USER_ZIPCODE,
    VALIDATE_CHANGE_PASSWORD,
    VALIDATE_USER_CHANGE
} from "../actions/action_types";
import {adjustValue, checkForErrors, clearState, trimValues} from "./checkout";
import {getDefaultUserProfileStructure} from "./index";

function checkIfExistAndAddIfExists(valuesAndAttributesNames: any[], toCheckArray: any[]) {
    for (const valAtr of valuesAndAttributesNames) {
        if (valAtr.object.value) {
            toCheckArray.push(valAtr.attributeName);
        }
    }
}

export function userReducer(state: any, action: any) {
    switch (action.type) {
        case SET_USER:
            if (!action.user) {
                return {...state, userDetailsLoaded: true}
            }

            let companyDetails = {}
            if (action.user.companyDetails) {
                companyDetails = {
                    companyName: adjustValue(state.companyName, action.user.companyDetails.name),
                    companyIco: adjustValue(state.companyIco, action.user.companyDetails.ico),
                    companyDic: adjustValue(state.companyDic, action.user.companyDetails.dic),
                    companyIcDph: adjustValue(state.companyIcDph, action.user.companyDetails.icDph)
                }
            }
            return {
                ...state,
                ...companyDetails,
                firstName: adjustValue(state.firstName, action.user.userDetails.firstName),
                lastName: adjustValue(state.lastName, action.user.userDetails.lastName),
                membershipIdentifier: adjustValue(state.membershipIdentifier, action.user.membershipIdentifier),
                contactEmail: adjustValue(state.contactEmail, action.user.userDetails.email),
                email: adjustValue(state.email, action.user.email),
                telephone: adjustValue(state.telephone, action.user.userDetails.telephone),
                address: adjustValue(state.address, action.user.userDetails.address),
                city: adjustValue(state.city, action.user.userDetails.city),
                state: adjustValue(state.state, action.user.userDetails.state),
                zipcode: adjustValue(state.zipcode, action.user.userDetails.zipcode),
                userDetailsLoaded: true,
                jwt: action.jwt
            }
        case LOGOUT_USER:
            return {...state, ...getDefaultUserProfileStructure(), userDetailsLoaded: true};
        case USER_FIRST_NAME:
            return {...state, firstName: adjustValue(state.firstName, action.value)};
        case USER_MEMBERSHIP:
            return {...state, membershipIdentifier: adjustValue(state.membershipIdentifier, action.value)};
        case USER_CONTACT_EMAIL:
            return {...state, contactEmail: adjustValue(state.contactEmail, action.value)};
        case USER_LAST_NAME:
            return {...state, lastName: adjustValue(state.lastName, action.value)};
        case USER_EMAIL:
            return {...state, email: adjustValue(state.email, action.value)};
        case USER_CURRENT_PASSWORD:
            return {...state, password: adjustValue(state.password, action.value)};
        case USER_NEW_PASSWORD:
            return {...state, newPassword: adjustValue(state.newPassword, action.value)};
        case USER_NEW_PASSWORD_CONFIRMATION:
            return {...state, newPasswordConfirmation: adjustValue(state.newPasswordConfirmation, action.value)};
        case USER_TELEPHONE:
            return {...state, telephone: adjustValue(state.telephone, action.value)};
        case USER_ADDRESS:
            return {...state, address: adjustValue(state.address, action.value)};
        case USER_CITY:
            return {...state, city: adjustValue(state.city, action.value)};
        case USER_STATE:
            return {...state, state: adjustValue(state.state, action.value)};
        case USER_ZIPCODE:
            return {...state, zipcode: adjustValue(state.zipcode, action.value)};
        case USER_COMPANY_NAME:
            return {...state, companyName: adjustValue(state.companyName, action.value)};
        case USER_COMPANY_ICO:
            return {...state, companyIco: adjustValue(state.companyIco, action.value)};
        case USER_COMPANY_DIC:
            return {...state, companyDic: adjustValue(state.companyDic, action.value)};
        case USER_COMPANY_IC_DPH:
            return {...state, companyIcDph: adjustValue(state.companyIcDph, action.value)};
        case USER_JWT:
            return {...state, jwt: adjustValue(state.jwt, action.value)};
        case USER_REMOVE_PASSWORD_STATES:
            return {
                ...state,
                password: clearState(state.password),
                newPassword: clearState(state.newPassword),
                newPasswordConfirmation: clearState(state.newPasswordConfirmation)
            };
        case VALIDATE_CHANGE_PASSWORD:
            trimValues(state);
            const toCheckPasswordAttributes = ["newPassword", "newPasswordConfirmation"];
            checkForErrors(state, toCheckPasswordAttributes);

            if (!state.newPassword.error && !state.newPasswordConfirmation.error) {
                if (state.newPassword.value !== state.newPasswordConfirmation.value) {
                    state.newPasswordConfirmation = {...state.newPasswordConfirmation, error: "Heslá sa nezhodujú."}
                }
            }
            return {...state};
        case VALIDATE_USER_CHANGE:
            trimValues(state);
            const toCheck = ["firstName", "lastName", "contactEmail", "address", "telephone", "state", "zipcode", "city"];

            checkIfExistAndAddIfExists(
                [
                    {object: state.companyName, attributeName: "companyName"},
                    {object: state.companyIco, attributeName: "companyIco"},
                    {object: state.companyIcDph, attributeName: "companyIcDph"},
                    {object: state.companyDic, attributeName: "companyDic"},
                    {object: state.membershipIdentifier, attributeName: "membershipIdentifier"},
                ], toCheck);

            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}