import React, {FunctionComponent} from "react";
import {COMPANY_NAME_ALT} from "../../config";
import {BackHomeLink, HeadingError} from "./PageNotFound";

interface Props {
}

export const Maintenance: FunctionComponent<Props> = () => {
    return (
        <div className={"flex items-center justify-center w-full h-screen"}>
            <div className="container flex flex-col items-center mx-auto">
                <img className={"w-40 h-auto pb-12"} alt={COMPANY_NAME_ALT} src={"/logo.png"}/>
                <div className={"text-center"}>
                    <HeadingError>503 error</HeadingError>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Služba nie je k dispozícií.</p>
                    <p className="mt-4 max-w-2xl text-md text-gray-500 lg:mx-auto">
                        Na webovej stránke momentálne prebieha údržba. Prosim skúste to znovu neskôr.
                    </p>
                    <p className="mt-4 max-w-2xl text-md text-gray-500 lg:mx-auto">
                        The website is undergoing maintenance. Please try to access the webpage later.
                    </p>
                    <BackHomeLink href={"/"}>Znovu načítať stránku
                        <span className={"ml-2 text-xl"}
                              aria-hidden="true"> &rarr;</span>
                    </BackHomeLink>
                </div>
            </div>
        </div>
    );
}