import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {ProductDetailsFullPage} from "../components/eshop/product/ProductDetailsFullPage";

interface Props {
    className?: string;
}

export const ProductSection: FunctionComponent<Props> = () => {

    return (
        <NavFooterWrapperSection>
            <ProductDetailsFullPage/>
        </NavFooterWrapperSection>
    )
}
