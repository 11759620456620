import React, {FunctionComponent} from "react";
import {OrderStateType} from "../../../types/order-state";
import {
    ArrowPathIcon,
    ArrowUturnLeftIcon,
    CheckIcon,
    ClipboardDocumentListIcon,
    CreditCardIcon,
    CubeIcon,
    HomeIcon,
    TruckIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";

interface Props {
    state: any;
}

export const OrderState: FunctionComponent<Props> = ({state}) => {

    const step: number = getStepBasedOnState(state);

    function getStepBasedOnState(state: string) {
        switch (state) {
            case OrderStateType.CREATED:
                return 0;
            case OrderStateType.ACCEPTED:
            case OrderStateType.PAYED:
            case OrderStateType.PENDING:
                return 1;
            case OrderStateType.IN_TRANSFER:
            case OrderStateType.PICKUP:
                return 2;
            case OrderStateType.DELIVERED:
            case OrderStateType.CANCELLED:
            case OrderStateType.RETURNED:
                return 3;
            default:
                return 0;
        }
    }

    function getStepTranslationBasedOnState(state: string) {
        switch (state) {
            case OrderStateType.CREATED:
                return "Vytvorená";
            case OrderStateType.ACCEPTED:
                return "Akceptovaná";
            case OrderStateType.PENDING:
                return "Čakajúca na platbu";
            case OrderStateType.PAYED:
                return "Zaplatená";
            case OrderStateType.IN_TRANSFER:
                return "V preprave"
            case OrderStateType.PICKUP:
                return "Pripravená na vyzdvihnutie"
            case OrderStateType.DELIVERED:
                return "Doručená"
            case OrderStateType.CANCELLED:
                return "Zrušená"
            case OrderStateType.RETURNED:
                return "Vratená"
            default:
                return "";
        }
    }

    function getStepIconBasedOnState(state: string) {
        switch (state) {
            case OrderStateType.CREATED:
                return <ClipboardDocumentListIcon className={"w-6 h-6 text-gray-600"}
                                                  title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.ACCEPTED:
                return <CheckIcon className={"w-6 h-6 text-gray-600"}
                                  title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.PENDING:
                return <ArrowPathIcon className={"w-6 h-6 text-gray-600"}
                                      title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.PAYED:
                return <CreditCardIcon className={"w-6 h-6 text-gray-600"}
                                       title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.IN_TRANSFER:
                return <TruckIcon className={"w-6 h-6 text-gray-600"} title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.PICKUP:
                return <CubeIcon className={"w-6 h-6 text-gray-600"}
                                 title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.DELIVERED:
                return <HomeIcon className={"w-6 h-6 text-gray-700"} title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.CANCELLED:
                return <XMarkIcon className={"w-6 h-6 text-gray-600"}
                                  title={getStepTranslationBasedOnState(state)}/>
            case OrderStateType.RETURNED:
                return <ArrowUturnLeftIcon className={"w-6 h-6 text-gray-600"}
                                           title={getStepTranslationBasedOnState(state)}/>
            default:
                return <></>;
        }
    }

    return (
        <div className="mt-10" aria-hidden="true">
            <div className="bg-gray-200 rounded-full overflow-hidden">
                <div
                    className="h-2 bg-btn-main rounded-full"
                    style={{width: `calc((${step} * 2 + 1) / 8 * 100%)`}}
                />
            </div>
            <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                <div className="text-btn-main-hover">Vytvorená</div>
                <div className={step > 0 ? 'text-btn-main-hover' : 'text-center'}>
                    {step === 1 ? getStepTranslationBasedOnState(state) : "Spracováva sa"}
                </div>
                <div className={step > 1 ? 'text-btn-main-hover' : 'text-center'}>
                    {step === 2 ? getStepTranslationBasedOnState(state) : "Odoslaná"}
                </div>
                <div className={step > 2 ? 'text-btn-main-hover' : 'text-right'}>
                    {step === 3 ? getStepTranslationBasedOnState(state) : "Doručená"}
                </div>
            </div>
            <div className="grid sm:hidden grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                {getStepIconBasedOnState(OrderStateType.CREATED)}
                {step === 1 ? getStepIconBasedOnState(state) : <ArrowPathIcon className={"w-6 h-6 text-gray-700"}
                                                                              title={getStepTranslationBasedOnState(OrderStateType.CREATED)}/>}
                {step === 2 ? getStepIconBasedOnState(state) : <TruckIcon className={"w-6 h-6 text-gray-700"}
                                                                          title={getStepTranslationBasedOnState(OrderStateType.IN_TRANSFER)}/>}
                {step === 3 ? getStepIconBasedOnState(state) : <HomeIcon className={"w-6 h-6 text-gray-700"}
                                                                         title={getStepTranslationBasedOnState(OrderStateType.DELIVERED)}/>}
            </div>
        </div>
    );
}