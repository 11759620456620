import React, {FunctionComponent, useEffect, useState} from "react";
import {GQL_CHECK_CATEGORY_SLUG} from "../api/GraphQL";
import {useQuery} from "@apollo/client";
import {ProductSection} from "./ProductSection";
import {EshopSection} from "./EshopSection";
import {useLocation, useParams} from "react-router-dom";
import {DefaultPageLoading} from "../components/loading/DefaultPageLoading";
import {SectionPageLoader} from "../components/loading/SectionPageLoader";

interface Props {
    className?: string;
}

export const EshopProductSection: FunctionComponent<Props> = () => {

    const {id} = useParams();
    const {state} = useLocation();
    const {loading, error, data} = useQuery(GQL_CHECK_CATEGORY_SLUG, {variables: {slug: id}});
    const [category, setCategory] = useState('');

    useEffect(() => {
        if (loading || error) {
            return;
        }
        setCategory(data?.findSlug?.data);
    }, [data]);

    if (loading) {
        return state?.isFromLoadedPage ? <SectionPageLoader/> : <DefaultPageLoading/>;
    }

    if (category) {
        return <EshopSection category={category}/>
    }

    return <ProductSection/>
}
