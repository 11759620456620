import React, {Fragment, FunctionComponent} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {XMarkIcon} from "@heroicons/react/24/solid";
import tw from "twin.macro";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";


interface Props {
    navigation: any;
    children?: React.ReactNode
}

const HighlightedLink = tw.a`
  w-full
  flex
  items-center
  justify-center
  px-4
  py-2
  border
  border-transparent
  rounded-md
  shadow-sm
  text-base
  font-medium
  text-white
  bg-btn-main
  hover:bg-btn-main-hover
`

interface HighlightedProps {
    item: any;
}

const HighlightedLinkJSX: FunctionComponent<HighlightedProps> = ({item}) => {
    return (
        <HighlightedLink href={item.link}>
            {item.name}
        </HighlightedLink>
    )
}

const HighlightedLinkContainer = tw.div`
  py-6
  px-5
  space-y-6
`

const NavLink = tw(Link)`
  -m-3
  p-3
  flex
  items-center
  rounded-md
  hover:bg-gray-50
`;

export const NavBarMobile: FunctionComponent<Props> = ({navigation, children}) => {
    return (
        <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <Popover.Panel focus
                           className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
                {({close}) => (
                    <div
                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={navigation && navigation.logo.data.attributes.url}
                                        alt={navigation && navigation.logo.data.attributes.alternativeText}
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#bf8a31]">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-7 w-7 text-gray-700" aria-hidden="true"/>
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    {navigation && navigation.links.map((item: any) => (
                                        <NavLink
                                            onClick={() => close()}
                                            key={nanoid()}
                                            to={item.link}
                                        >
                                            <img src={item.icon.data.attributes.url}
                                                 alt={item.icon.data.attributes.alternativeText}
                                                 className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                 aria-hidden="true"/>
                                            <span
                                                className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                                        </NavLink>
                                    ))}

                                </nav>
                            </div>
                        </div>
                        <HighlightedLinkContainer>
                            <HighlightedLinkJSX item={navigation && navigation.highlightedLink}/>
                        </HighlightedLinkContainer>
                    </div>
                )}
            </Popover.Panel>
        </Transition>
    )
}
