import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import Eshop from "../components/eshop/Eshop";

interface Props {
    className?: string;
    category?: any;
}

export const EshopSection: FunctionComponent<Props> = ({category}) => {

    return (
        <NavFooterWrapperSection>
            <Eshop categoryFromRoute={category}/>
        </NavFooterWrapperSection>
    )
}
