import React, {FunctionComponent} from "react";
import {ORDER_ROUTE} from "../../../app";
import {Link} from "react-router-dom";
import {DATE_OPTIONS} from "../../../utils/DateTimeUtils";
import {calculateOrderPrices} from "../../../utils/OrderUtils";
import {roundNumber} from "../../../utils/EshopUtils";

interface Props {
    order: any;
}

export const OrderHistory: FunctionComponent<Props> = ({order}) => {

    const orderPrices = calculateOrderPrices(order);

    return (
        <div className={"p-6 bg-white rounded-lg border border-gray-200 shadow"}>
            <div className={"flex justify-between gap-2 sm:items-center"}>
                <div className={"flex gap-6 flex-col sm:flex-row"}>
                    <div className={"flex flex-col"}>
                        <p>Číslo objednávky</p>
                        <p className={"font-sans text-gray-700"}>{order.id}</p>
                    </div>
                    <div className={"flex flex-col"}>
                        <p>Dátum vytvorenia</p>
                        <p className={"font-sans text-gray-700"}>{new Date(order.createdAt).toLocaleDateString("sk-SK", DATE_OPTIONS)}</p>
                    </div>
                    <div className={"flex flex-col"}>
                        <p>Celková suma</p>
                        <p className={"font-sans font-bold"}>{roundNumber(orderPrices?.totalPrice)} {orderPrices?.currency}</p>
                    </div>
                </div>
                <div>
                    <Link to={"/" + ORDER_ROUTE + order.identifier}
                          className="flex rounded-md border border-transparent px-4 py-0.5 items-center text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover">
                        Detail
                    </Link>
                </div>
            </div>
        </div>
    )
}