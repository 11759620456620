import React, {FunctionComponent} from "react";
import tw from "twin.macro";

interface Props {
    className?: string;
    children?: React.ReactNode;
}

let Heading3H = tw.h3`
 text-lg
 font-semibold
 tracking-wide
 uppercase
 font-serif
 text-heading-three
`

export const H3: FunctionComponent<Props> = ({className, children}) => {
    return (
        <Heading3H className={className}>
            {children}
        </Heading3H>
    );
}
