import React, {FunctionComponent} from "react";

interface Props {
}

export const SectionSelectorLoader: FunctionComponent<Props> = () => {

    return (
        <div
            className="p-0 animate-pulse mx-8 border-0 no-underline w-[12rem] h-[8rem] md:w-[14rem] md:h-[9rem] lg:w-[16rem] lg:h-[9em] bg-gray-200 rounded-xl"/>
    );
}