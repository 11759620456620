import React, {FunctionComponent} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import {PageNotification} from "../notification/PageNotification";
import {LOGIN_ROUTE_TO} from "../../app";

interface Props {

}

export const EmailConfirmed: FunctionComponent<Props> = () => {

    const [searchParams] = useSearchParams();
    const confirmationCode = searchParams && searchParams.get("confirmation");

    if (!confirmationCode) {
        return <Navigate to={"/"} replace/>
    }

    return (
        <PageNotification title={"Účet bol úšpesne potvrdený"}
                          linkDescription={"Prihlásenie"}
                          link={LOGIN_ROUTE_TO}
                          description={"Účet bol úšpesne potvrdený. Pre aktiváciu veľkoobchodných cien prosím počkajte na prijatie vašej registrácie. O schválení budete informovaný prostredníctvom emailu."}/>
    )
}