import React, {FunctionComponent} from "react";
import {RadioGroup} from "@headlessui/react";
import {nanoid} from "nanoid";
import {Currency} from "../../../../types/Common";
import {VolumeOption} from "./VolumeOption";

interface VolumeBoxProps {
    variations: any;
    selectedVariation: any;
    setSelectedVariation: any;
    selectedColor: any;
    currency: Currency;
    wholesalePrice: any;
}

export const VolumeBox: FunctionComponent<VolumeBoxProps> = ({
                                                                 variations,
                                                                 selectedColor,
                                                                 selectedVariation,
                                                                 setSelectedVariation,
                                                                 currency,
                                                                 wholesalePrice
                                                             }) => {
    return (
        <div className="mt-6">
            <RadioGroup value={selectedVariation.id}
                        onChange={setSelectedVariation}>
                <RadioGroup.Label className="sr-only">Choose a
                    volume</RadioGroup.Label>
                <div
                    className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {variations[selectedColor].map((variation: any) => (
                        <VolumeOption
                            key={nanoid()}
                            selectedVariation={selectedVariation}
                            variation={variation} currency={currency}
                            wholesalePrice={wholesalePrice}/>
                    ))}
                </div>
            </RadioGroup>
        </div>
    )
}