import React, {FunctionComponent} from "react";
import tw from "twin.macro";
import {COMPANY_NAME_ALT} from "../../config";

interface Props {

}

export const HeadingError = tw.h1`
 text-base
 text-error-pages-text
 font-semibold
 tracking-wide
 uppercase
 font-serif
`

export const BackHomeLink = tw.a`
  mt-4
  inline-block
  flex
  flex-row
  justify-center
  items-center
  text-error-pages-text
  font-bold
`


export const PageNotFound: FunctionComponent<Props> = () => {
    return (
        <div className={"flex items-center justify-center w-full h-screen"}>
            <div className="container flex flex-col items-center mx-auto">
                <img className={"w-40 h-auto pb-12"} alt={COMPANY_NAME_ALT} src={"/logo.png"}/>
                <div className={"text-center"}>
                    <HeadingError>404 error</HeadingError>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Stránka nenájdená.</p>
                    <p className="mt-4 max-w-2xl text-md text-gray-500 lg:mx-auto">
                        Ľutujeme, nepodarilo sa nám nájsť stránku, ktorú hľadáte.
                    </p>
                    <BackHomeLink href={"/"}>Naspäť domov<span className={"ml-2 text-xl"}
                                                               aria-hidden="true"> &rarr;</span></BackHomeLink>
                </div>
            </div>
        </div>
    );
}