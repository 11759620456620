import React, {FunctionComponent, useEffect, useState} from 'react'
import {RadioGroup} from '@headlessui/react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {calculatePriceWithoutTax, getTotalPrice, isSameNumber, roundNumber} from "../../utils/EshopUtils";
import {nanoid} from "nanoid";
import {
    CHECKOUT_ADDRESS,
    CHECKOUT_CITY,
    CHECKOUT_CLEAR_STATE,
    CHECKOUT_COMPANY_DETAILS,
    CHECKOUT_COMPANY_DIC,
    CHECKOUT_COMPANY_IC_DPH,
    CHECKOUT_COMPANY_ICO,
    CHECKOUT_COMPANY_NAME,
    CHECKOUT_DELIVERY_COMPANY_NAME_NOTE,
    CHECKOUT_DELIVERY_METHOD,
    CHECKOUT_DELIVERY_NOTE,
    CHECKOUT_DELIVERY_PAYMENT,
    CHECKOUT_EMAIL,
    CHECKOUT_FIRST_NAME,
    CHECKOUT_LAST_NAME,
    CHECKOUT_NOTE,
    CHECKOUT_OTHER_ADDRESS,
    CHECKOUT_OTHER_CITY,
    CHECKOUT_OTHER_DELIVERY_ADDRESS,
    CHECKOUT_OTHER_EMAIL,
    CHECKOUT_OTHER_FIRST_NAME,
    CHECKOUT_OTHER_LAST_NAME,
    CHECKOUT_OTHER_STATE,
    CHECKOUT_OTHER_TELEPHONE,
    CHECKOUT_OTHER_ZIPCODE,
    CHECKOUT_PAYMENT_METHOD,
    CHECKOUT_STATE,
    CHECKOUT_TELEPHONE,
    CHECKOUT_ZIPCODE,
    VALIDATE_CHECKOUT
} from "../../redux/actions/action_types";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL, PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../../config";
import {CheckoutProduct} from "./product/CheckoutProduct";
import {InputType} from "../../types/input-type";
import {canSubmit} from "../../redux/reducers/checkout";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {GQL_DELIVERIES_PAYMENTS} from "../../api/GraphQL";
import {useQuery} from "@apollo/client";
import {getDefaultSupportedCountries} from "../../redux/reducers";
import {join} from "../../utils";
import {PageNotificationAlt} from "../notification/PageNotificationAlt";
import {authorizationBearer} from "../../utils/AuthorizationHeaderUtils";
import {ORDER_ROUTE} from "../../app";
import {calculateDiscount} from "../../utils/OrderUtils";
import {NotificationOnlyWholesaleUsers} from "../notification/NotificationOnlyWholesaleUsers";

export interface DispatchValues {
    dispatchMethod: any;
    typeName: any;
}

export interface ValueProps {
    value: string;
    error: string;
    type: InputType;
}

export interface ValueBooleanProps {
    value: boolean;
    error: string;
    type: InputType;
}

interface CheckoutInputFieldsProps {
    dispatch: DispatchValues;
    field: ValueProps;
    titleName?: string;
    required: boolean;
    type: string;
    autoComplete: string;
    placeHolder?: string;
    inputType?: string;
    disabled?: boolean;
}

interface CheckoutBooleanInputFieldsProps {
    dispatch: DispatchValues;
    field: ValueBooleanProps;
    titleName: string;
    type: string;
    autoComplete: string;
    placeHolder?: string;
    disabled?: boolean;
}

interface CheckoutSelectFieldsProps {
    dispatch: DispatchValues;
    field: ValueProps;
    titleName: string;
    required: boolean;
    type: string;
    options: string[];
    autoComplete: string;
    disabled?: boolean;
}

interface ErrorFieldProps {
    error: string;
    className?: string;
}

export const ErrorField: FunctionComponent<ErrorFieldProps> = ({error, className}) => {
    return (
        <p className={"ml-1 mt-3 text-red-600 text-sm" + (className ? (" " + className) : "")}>{error}</p>
    )
}

export const CheckoutInputField: FunctionComponent<CheckoutInputFieldsProps> = ({
                                                                                    dispatch,
                                                                                    titleName,
                                                                                    required,
                                                                                    type,
                                                                                    autoComplete,
                                                                                    placeHolder,
                                                                                    field,
                                                                                    inputType,
                                                                                    disabled
                                                                                }) => {
    return (
        <div>
            {titleName ?
                <label htmlFor={type}
                       className="flex flex-row text-sm font-normal text-gray-700">{titleName}
                    {!required ? <span
                        className={"align-end ml-2 text-[0.72rem] font-normal self-end text-gray-400"}>nepovinné</span> : null}
                </label> : null
            }
            <input
                onChange={(e) => {
                    dispatch.dispatchMethod({
                        type: dispatch.typeName,
                        value: e.target.value
                    })
                }
                }
                disabled={disabled == null ? false : disabled}
                value={field.value}
                placeholder={placeHolder}
                required={required}
                type={inputType ?? "text"}
                name={type}
                id={type}
                autoComplete={autoComplete}
                className={"mt-1 h-8 focus:ring-btn-main focus:border-btn-main block w-full shadow-sm sm:text-sm border-gray-300 border-[1.5px] rounded-md"}
            />
            {field.error ? <ErrorField error={field.error}/> : null}
        </div>
    )
}

export const CheckoutBooleanField: FunctionComponent<CheckoutBooleanInputFieldsProps> = ({
                                                                                             dispatch,
                                                                                             titleName,
                                                                                             type,
                                                                                             autoComplete,
                                                                                             placeHolder,
                                                                                             field,
                                                                                             disabled
                                                                                         }) => {

    return (
        <div className="cursor-pointer flex items-start" onClick={() => {
            dispatch.dispatchMethod({
                type: dispatch.typeName,
                value: !field.value
            })
        }}>
            <div className="flex items-center h-5">
                <input
                    disabled={disabled == null ? false : disabled}
                    readOnly={true}
                    checked={field.value}
                    id={type}
                    name={type}
                    type="checkbox"
                    autoComplete={autoComplete}
                    placeholder={placeHolder}
                    className="cursor-pointer accent-btn-main text-btn-main focus:ring-btn-main h-4 w-4 border-gray-300 rounded"
                />
            </div>
            <div className="cursor-pointer ml-3 text-sm">
                <label onClick={(e) => e.preventDefault()} htmlFor={type}
                       className="cursor-pointer font-normal text-gray-700">
                    {titleName}
                </label>
            </div>
        </div>
    )
}

export const CheckoutSelectField: FunctionComponent<CheckoutSelectFieldsProps> = ({
                                                                                      dispatch,
                                                                                      titleName,
                                                                                      required,
                                                                                      type,
                                                                                      autoComplete,
                                                                                      field,
                                                                                      options,
                                                                                      disabled
                                                                                  }) => {

    useEffect(() => {
        if (!field.value) {
            dispatch.dispatchMethod({
                type: dispatch.typeName,
                value: options ? options[0] : ''
            })
        }
    }, [])

    return (
        <div>
            <label htmlFor={type}
                   className="flex flex-row text-sm font-normal text-gray-700">{titleName}
                {!required ? <span
                    className={"align-end ml-2 text-[0.72rem] font-normal self-end text-gray-400"}>nepovinné</span> : null}
            </label>
            <select
                disabled={disabled == null ? false : disabled}
                onChange={(e) => {
                    dispatch.dispatchMethod({
                        type: dispatch.typeName,
                        value: e.target.value
                    })
                }
                }
                value={field.value}
                id={type}
                name={type}
                autoComplete={autoComplete}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-btn-main focus:border-btn-main sm:text-sm"
            >
                {options.map((option: any) =>
                    <option key={nanoid()}>{option}</option>
                )}
            </select>
            {field.error ? <ErrorField error={field.error}/> : null}
        </div>
    )
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const Checkout: FunctionComponent<any> = () => {
    const dispatch = useDispatch();

    const [checkout, products, cart, user, currency, wholesalePrice] = useSelector((state: any) =>
        [state.checkout, state.shop.products, state.shop.cart, state.user, state.shop.currency, state.shop.wholesalePrice], shallowEqual);

    const navigate = useNavigate();
    const [submittingOrder, setSubmittingOrder] = useState<boolean>(false);
    const [isServerError, setIsServerError] = useState<boolean>(false);

    const totalProductPriceWithTax: number = getTotalPrice(cart, products, wholesalePrice);
    const totalProductPriceWithoutTax: number = calculatePriceWithoutTax(totalProductPriceWithTax);

    const paymentPriceWithDiscounts = calculatePriceAfterDiscountsForDeliveryPayment(checkout.paymentMethod.value);
    const deliveryPriceWithDiscounts = calculatePriceAfterDiscountsForDeliveryPayment(checkout.deliveryMethod.value);

    const paymentPriceWithDiscountsWithoutTax = calculatePriceWithoutTax(paymentPriceWithDiscounts);
    const deliveryPriceWithDiscountsWithoutTax = calculatePriceWithoutTax(deliveryPriceWithDiscounts);

    const {loading, error, data} = useQuery(GQL_DELIVERIES_PAYMENTS);

    useEffect(() => {
        if (!loading && !error) {
            const delivery = data.deliveries.data[0];
            dispatch({
                type: CHECKOUT_DELIVERY_PAYMENT,
                delivery,
                payment: delivery.attributes.possiblePaymentMethods.data[0]
            });
        }
    }, [loading]);

    function calculatePriceAfterDiscountsForDeliveryPayment(itemToCalculate: any) {
        if (!itemToCalculate) {
            return 0;
        }

        const discounts = itemToCalculate?.attributes?.discounts?.data;
        const discountItem = discounts && discounts.length > 0 ? discounts[0].attributes : null;

        if (discountItem) {
            if (discountItem.applyFrom && discountItem.applyFrom <= totalProductPriceWithTax) {
                const discountedPrice = itemToCalculate.attributes.price - calculateDiscount(itemToCalculate.attributes.price, discountItem.percentage);
                return discountedPrice < 0 ? 0 : discountedPrice;
            }
        }
        return itemToCalculate.attributes.price;
    }

    function getApplyFromAndPercentageFromDiscount(itemToCalculate: any) {
        if (!itemToCalculate) {
            return null;
        }
        const discounts = itemToCalculate?.attributes?.discounts?.data;
        const discountItem = discounts && discounts.length > 0 ? discounts[0].attributes : null;
        if (discountItem) {
            return {applyFrom: discountItem.applyFrom, percentage: discountItem.percentage};
        }
    }

    if (PRICE_VISIBLE_FOR_WHOLESALE_ONLY && !wholesalePrice) {
        return <PageNotificationAlt title={"Pokladňa je dostupná len pre firemných zákazníkov"}
                                    description={<NotificationOnlyWholesaleUsers titleElement={null}/>}/>
    }

    if (!cart || cart.length < 1) {
        return <PageNotificationAlt title={"Košík je prázdny"}
                                    description={" Pre vytvorenie objednávky je nutné mať v košíku aspoň jeden produkt."}/>
    }

    return (
        <div>
            <div className="max-w-2xl mx-auto pt-16 pb-12 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Checkout</h2>
                <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                    <div>
                        <div>
                            <h2 className="text-lg font-medium text-gray-900">Osobné údaje</h2>

                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <CheckoutInputField field={checkout.firstName} autoComplete={"given-name"}
                                                    titleName={"Meno"} type={"first-name"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_FIRST_NAME
                                                    }}/>
                                <CheckoutInputField field={checkout.lastName} autoComplete={"last-name"}
                                                    titleName={"Priezvisko"} type={"first-name"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_LAST_NAME
                                                    }}/>
                                <CheckoutInputField field={checkout.email} autoComplete={"email"}
                                                    titleName={"Email"} type={"email"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_EMAIL
                                                    }}/>
                                <CheckoutInputField field={checkout.telephone} autoComplete={"telephone"}
                                                    titleName={"Telefón"} type={"telephone"}
                                                    placeHolder={"Číslo v tvare 0940620000"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_TELEPHONE
                                                    }}/>
                                <CheckoutBooleanField field={checkout.companyDetails}
                                                      autoComplete={"company-details"}
                                                      titleName={"Nakupujem na firmu, chcem faktúru"}
                                                      type={"company-details"}
                                                      dispatch={{
                                                          dispatchMethod: dispatch,
                                                          typeName: CHECKOUT_COMPANY_DETAILS
                                                      }}/>
                            </div>

                            {checkout.companyDetails.value ?
                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 mb-12">
                                    <CheckoutInputField field={checkout.companyName}
                                                        autoComplete={"company-name"}
                                                        titleName={"Firma"} type={"company-name"}
                                                        required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_COMPANY_NAME
                                                        }}/>
                                    <CheckoutInputField field={checkout.companyIco} autoComplete={"company-ico"}
                                                        titleName={"IČO"} type={"company-ico"} required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_COMPANY_ICO
                                                        }}/>
                                    <CheckoutInputField field={checkout.companyDic} autoComplete={"company-dic"}
                                                        titleName={"DIČ"} type={"company-dic"} required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_COMPANY_DIC
                                                        }}/>
                                    <CheckoutInputField field={checkout.companyIcDph}
                                                        autoComplete={"company-icDph"}
                                                        titleName={"IČ DPH"} type={"company-icDph"}
                                                        required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_COMPANY_IC_DPH
                                                        }}/>
                                </div> : null}
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <CheckoutInputField field={checkout.address} autoComplete={"address"}
                                                    titleName={"Adresa"} type={"address"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_ADDRESS
                                                    }}/>
                                <CheckoutInputField field={checkout.city} autoComplete={"city"}
                                                    titleName={"Mesto"} type={"city"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_CITY
                                                    }}/>
                                <CheckoutSelectField field={checkout.state} autoComplete={"state"}
                                                     titleName={"Krajina"} type={"state"} required={true}
                                                     options={getDefaultSupportedCountries()}
                                                     dispatch={{
                                                         dispatchMethod: dispatch,
                                                         typeName: CHECKOUT_STATE
                                                     }}/>
                                <CheckoutInputField field={checkout.zipcode} autoComplete={"zip-code"}
                                                    titleName={"PSČ"} type={"zip-code"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: CHECKOUT_ZIPCODE
                                                    }}/>
                                {!checkout.otherDeliveryAddress.value ?
                                    <>
                                        <CheckoutInputField field={checkout.companyNameNote}
                                                            autoComplete={"company-name-note"}
                                                            titleName={"Názov firmy pre doručenie"}
                                                            type={"company-name-note"}
                                                            required={false}
                                                            dispatch={{
                                                                dispatchMethod: dispatch,
                                                                typeName: CHECKOUT_DELIVERY_COMPANY_NAME_NOTE
                                                            }}/>
                                        <CheckoutInputField field={checkout.deliveryNote}
                                                            autoComplete={"delivery-note"}
                                                            titleName={"Informácia pre dopravcov"}
                                                            placeHolder={"Poschodie, recepcia a pod."}
                                                            type={"delivery-note"}
                                                            required={false}
                                                            dispatch={{
                                                                dispatchMethod: dispatch,
                                                                typeName: CHECKOUT_DELIVERY_NOTE
                                                            }}/>
                                    </> : null
                                }
                                <CheckoutBooleanField field={checkout.otherDeliveryAddress}
                                                      autoComplete={"other-delivery-address"}
                                                      titleName={"Poslať na inú adresu"}
                                                      type={"other-delivery-address"}
                                                      dispatch={{
                                                          dispatchMethod: dispatch,
                                                          typeName: CHECKOUT_OTHER_DELIVERY_ADDRESS
                                                      }}/>
                            </div>
                            {checkout.otherDeliveryAddress.value ?
                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                    <CheckoutInputField field={checkout.otherFirstName}
                                                        autoComplete={"given-name"}
                                                        titleName={"Meno"} type={"first-name"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_FIRST_NAME
                                                        }}/>
                                    <CheckoutInputField field={checkout.otherLastName}
                                                        autoComplete={"last-name"}
                                                        titleName={"Priezvisko"} type={"first-name"}
                                                        required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_LAST_NAME
                                                        }}/>
                                    <CheckoutInputField field={checkout.otherEmail} autoComplete={"email"}
                                                        titleName={"Email"} type={"email"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_EMAIL
                                                        }}/>
                                    <CheckoutInputField field={checkout.otherTelephone}
                                                        autoComplete={"telephone"}
                                                        titleName={"Telefón"} type={"telephone"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_TELEPHONE
                                                        }}/>
                                    <CheckoutInputField field={checkout.otherAddress} autoComplete={"address"}
                                                        titleName={"Adresa"} type={"address"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_ADDRESS
                                                        }}/>
                                    <CheckoutInputField field={checkout.otherCity} autoComplete={"city"}
                                                        titleName={"Mesto"} type={"city"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_CITY
                                                        }}/>
                                    <CheckoutSelectField field={checkout.otherState} autoComplete={"state"}
                                                         titleName={"Krajina"} type={"state"} required={true}
                                                         options={getDefaultSupportedCountries()}
                                                         dispatch={{
                                                             dispatchMethod: dispatch,
                                                             typeName: CHECKOUT_OTHER_STATE
                                                         }}/>
                                    <CheckoutInputField field={checkout.otherZipcode} autoComplete={"zip-code"}
                                                        titleName={"PSČ"} type={"zip-code"} required={true}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_OTHER_ZIPCODE
                                                        }}/>
                                    <CheckoutInputField field={checkout.companyNameNote}
                                                        autoComplete={"company-name-note"}
                                                        titleName={"Názov firmy pre doručenie"}
                                                        type={"company-name-note"}
                                                        required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_DELIVERY_COMPANY_NAME_NOTE
                                                        }}/>
                                    <CheckoutInputField field={checkout.deliveryNote}
                                                        autoComplete={"delivery-note"}
                                                        titleName={"Informácia pre dopravcov"}
                                                        placeHolder={"Poschodie, recepcia a pod."}
                                                        type={"delivery-note"}
                                                        required={false}
                                                        dispatch={{
                                                            dispatchMethod: dispatch,
                                                            typeName: CHECKOUT_DELIVERY_NOTE
                                                        }}/>
                                </div> : null}
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">

                            <RadioGroup value={checkout.deliveryMethod.value} onChange={(e) => {
                                dispatch({type: CHECKOUT_DELIVERY_METHOD, value: e});
                                if (!e.attributes.possiblePaymentMethods.data.find((paymentMethod: any) => e.id === paymentMethod.id)) {
                                    dispatch({
                                        type: CHECKOUT_PAYMENT_METHOD,
                                        value: e.attributes.possiblePaymentMethods.data[0]
                                    })
                                }
                            }
                            }>
                                <RadioGroup.Label className="text-lg font-medium text-gray-900">Spôsob
                                    doručenia</RadioGroup.Label>

                                {!loading && !error ?
                                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                        {data && data.deliveries.data.map((deliveryMethod: any) => (
                                            <RadioGroup.Option
                                                key={deliveryMethod.attributes.method}
                                                value={deliveryMethod}
                                                className={({checked, active}) =>
                                                    classNames(
                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                        active ? 'ring-2 ring-btn-main-hover' : '',
                                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                    )
                                                }
                                            >
                                                {({checked, active}) => {
                                                    const deliveryPriceWithDiscount = calculatePriceAfterDiscountsForDeliveryPayment(deliveryMethod);
                                                    const formattedDeliveryPriceWithDiscount = deliveryPriceWithDiscount !== 0 ? join([roundNumber(deliveryPriceWithDiscount), currency.name], ' ') : "Zadarmo";

                                                    const discountAttributes = getApplyFromAndPercentageFromDiscount(deliveryMethod);

                                                    return (
                                                        <>
                                                            <div className="flex-1 flex">
                                                                <div className="flex flex-col">
                                                                    <RadioGroup.Label as="span"
                                                                                      className="block text-sm font-medium text-gray-900">
                                                                        {deliveryMethod.attributes.name}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description
                                                                        as="span"
                                                                        className="mt-1 flex items-center text-sm text-gray-500"
                                                                    >
                                                                        {deliveryMethod.attributes.description}
                                                                    </RadioGroup.Description>
                                                                    <RadioGroup.Description as="span"
                                                                                            className="mt-6 text-sm font-medium text-gray-900">
                                                                        {formattedDeliveryPriceWithDiscount}
                                                                        {discountAttributes && discountAttributes.applyFrom && discountAttributes.percentage && deliveryPriceWithDiscount !== 0 ?
                                                                            <span key={nanoid()}
                                                                                  className={"ml-4 text-xs inline-flex font-medium items-center px-2.5 py-0.5 rounded break-words bg-nav-background"}>{discountAttributes.percentage !== 100 ? ("Zľava " + (discountAttributes.percentage) + "%") : "Zadarmo"} nad {roundNumber(discountAttributes.applyFrom)} {currency.name}</span> : null}
                                                                    </RadioGroup.Description>
                                                                </div>
                                                            </div>
                                                            {checked ?
                                                                <CheckCircleIcon
                                                                    className="w-6 h-6 text-btn-main"
                                                                    aria-hidden="true"/> : null}
                                                            <div
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-btn-main' : 'border-transparent',
                                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )
                                                }}
                                            </RadioGroup.Option>
                                        ))}
                                    </div> : <SpinnerLoader classname={"w-10 h-10 mx-auto"}/>}
                            </RadioGroup>

                        </div>

                        {/* Payment */}
                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h2 className="text-lg font-medium text-gray-900">Spôsob platby</h2>

                            {!loading && !error ?
                                <fieldset className="mt-4">
                                    <legend className="sr-only">Payment method</legend>
                                    <div className="space-y-4 flex flex-col">
                                        {checkout.deliveryMethod.value && checkout.deliveryMethod.value.attributes.possiblePaymentMethods.data.map((paymentMethod: any) => {
                                            const paymentMethodPrice = calculatePriceAfterDiscountsForDeliveryPayment(paymentMethod);
                                            const discountAttributes = getApplyFromAndPercentageFromDiscount(paymentMethod);
                                            return (
                                                <div key={nanoid()} className={"flex flex-col cursor-pointer"}
                                                     onClick={() => {
                                                         dispatch({
                                                             type: CHECKOUT_PAYMENT_METHOD,
                                                             value: paymentMethod
                                                         })
                                                     }}>
                                                    <div key={paymentMethod.attributes.method}
                                                         className="flex items-center">
                                                        <input
                                                            id={nanoid()}
                                                            name="payment-type"
                                                            type={"radio"}
                                                            readOnly={true}
                                                            checked={paymentMethod.id === checkout.paymentMethod.value.id}
                                                            className="cursor-pointer accent-btn-main focus:ring-btn-main text-btn-main h-4 w-4 border-gray-300"
                                                        />
                                                        <label onClick={(e) => e.preventDefault()}
                                                               htmlFor={"payment-type"}
                                                               className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
                                                            {paymentMethod.attributes.name}
                                                            {paymentMethodPrice != null && paymentMethodPrice !== 0 ?
                                                                <span
                                                                    className={"ml-2"}>+{roundNumber(paymentMethodPrice)} {currency.name}</span> : null}
                                                            {discountAttributes && discountAttributes.applyFrom && discountAttributes.percentage && paymentMethodPrice !== 0 ?
                                                                <span key={nanoid()}
                                                                      className={"ml-4 text-xs inline-flex font-medium items-center px-2.5 py-0.5 rounded break-words bg-nav-background"}>{discountAttributes.percentage !== 100 ? "Zľava" : "Zadarmo"} nad {roundNumber(discountAttributes.applyFrom)} {currency.name}</span> : null}
                                                        </label>
                                                    </div>
                                                    <p className={"ml-7 text-gray-400 text-sm"}>{paymentMethod.attributes.description}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </fieldset> : <SpinnerLoader classname={"w-10 h-10 mx-auto"}/>}
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <div className={"flex flex-row"}>
                                <h2 className="text-lg font-medium text-gray-900">Poznámka</h2>
                                <span
                                    className={"align-end ml-2 text-[0.72rem] font-normal self-center text-gray-400"}>nepovinné</span>
                            </div>

                            <div className="mt-4">
                                        <textarea
                                            id="note"
                                            name="note"
                                            rows={3}
                                            className="h-32 shadow-sm focus:ring-btn-main focus:border-btn-main mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                            placeholder="Sem napíšte vašu poznámku k objednávke"
                                            value={checkout.orderNote.value}
                                            onChange={(e) => dispatch({type: CHECKOUT_NOTE, value: e.target.value})}
                                        />
                            </div>
                        </div>
                    </div>


                    {/* Order summary */}
                    <div className="mt-10 lg:mt-0">
                        <h2 className="text-lg font-medium text-gray-900">Zhrnutie objednávky</h2>

                        <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                            <h3 className="sr-only">Predmety vo vašom košíku</h3>
                            {/* loading products */}
                            {!products || products.length < 1 || loading ?
                                <div className={"my-6"}>
                                    <SpinnerLoader classname={"w-12 h-12 mx-auto"}/>
                                </div>
                                : null
                            }
                            <ul className="divide-y divide-gray-200">
                                {products ?
                                    cart.map((cartItem: any) => {

                                            const productData = products.find((product: any) => isSameNumber(product.id, cartItem.productID));
                                            const product = productData && productData.attributes;
                                            const variation = productData && productData.attributes && product && product.variations.find((variation: any) => isSameNumber(variation.id, cartItem.variationID));

                                            if (!variation || !product) {
                                                return null;
                                            }
                                            return <CheckoutProduct currency={currency} key={nanoid()}
                                                                    product={product}
                                                                    variation={variation}
                                                                    cartItem={cartItem}
                                                                    wholesalePrice={wholesalePrice}/>
                                        }
                                    ) : null
                                }
                            </ul>
                            <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <dt className="text-sm">Celková cena produktov</dt>
                                    <dd className="text-sm font-medium text-gray-900">{roundNumber(totalProductPriceWithTax)} {currency.name}</dd>
                                </div>
                                <div className="flex items-center justify-between">
                                    <dt className="text-sm">Doprava {checkout.deliveryMethod?.value?.attributes?.name == null ? "" : (" - " + checkout.deliveryMethod.value.attributes.name)}</dt>
                                    <dd className="text-sm font-medium text-gray-900">{!checkout.deliveryMethod?.value?.attributes?.price ? join([roundNumber(0), currency.name], ' ') : (deliveryPriceWithDiscounts !== 0 ? join([roundNumber(deliveryPriceWithDiscounts), currency.name], ' ') : "Zadarmo")}</dd>
                                </div>
                                {checkout.paymentMethod.value?.attributes?.price != null && checkout.paymentMethod.value.attributes.price !== 0 ?
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">Platba {checkout.paymentMethod?.value?.attributes?.name == null ? "" : (" - " + checkout.paymentMethod.value.attributes.name)}</dt>
                                        <dd className="text-sm font-medium text-gray-900">{checkout.paymentMethod?.value?.attributes?.price == null ? join([roundNumber(0), currency.name], ' ') : (paymentPriceWithDiscounts !== 0 ? join([roundNumber(paymentPriceWithDiscounts), currency.name], ' ') : "Zadarmo")}</dd>
                                    </div> : null}

                                <div
                                    className="flex items-center justify-between border-t border-gray-200 pt-6">
                                    <dt className="text-sm">K úhrade bez DPH</dt>
                                    <dd className="text-sm font-medium text-gray-900">{roundNumber(totalProductPriceWithoutTax + paymentPriceWithDiscountsWithoutTax + deliveryPriceWithDiscountsWithoutTax)} {currency.name}</dd>
                                </div>
                                <div className="flex items-center justify-between">
                                    <dt className="text-sm">DPH</dt>
                                    <dd className="text-sm font-medium text-gray-900">{roundNumber(totalProductPriceWithTax - totalProductPriceWithoutTax + paymentPriceWithDiscounts - paymentPriceWithDiscountsWithoutTax + deliveryPriceWithDiscounts - deliveryPriceWithDiscountsWithoutTax)} {currency.name}</dd>
                                </div>
                                <div
                                    className="flex items-center justify-between border-t border-gray-200 pt-6">
                                    <dt className="text-base font-bold">K úhrade s DPH</dt>
                                    <dd className="text-base font-bold text-gray-900">{roundNumber(totalProductPriceWithTax + paymentPriceWithDiscounts + deliveryPriceWithDiscounts)} {currency.name}</dd>
                                </div>
                            </dl>

                            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();

                                        if (submittingOrder || !products || products.length < 1) {
                                            return;
                                        }
                                        dispatch({type: VALIDATE_CHECKOUT});

                                        if (canSubmit(checkout)) {
                                            setSubmittingOrder(true);
                                            let variations: any = []
                                            cart.forEach((product: any) => {
                                                variations.push(product.selectedVariation);
                                            });

                                            const billingAccountInfo = {
                                                firstName: checkout.firstName.value,
                                                lastName: checkout.lastName.value,
                                                address: checkout.address.value,
                                                city: checkout.city.value,
                                                zipcode: checkout.zipcode.value,
                                                telephone: checkout.telephone.value,
                                                email: checkout.email.value,
                                                state: checkout.state.value
                                            };

                                            let notes: any = {
                                                orderNote: checkout.orderNote.value,
                                                deliveryNote: checkout.deliveryNote.value,
                                                companyNameNote: checkout.companyNameNote.value
                                            };

                                            let companyInfo: any = null;

                                            if (checkout.companyDetails.value) {
                                                companyInfo = {
                                                    name: checkout.companyName.value,
                                                    ico: checkout.companyIco.value,
                                                    dic: checkout.companyDic.value,
                                                    icDph: checkout.companyIcDph.value
                                                };
                                            }

                                            let finalData: any = {
                                                billingAccountInfo,
                                                currency: currency.id,
                                                paymentMethod: checkout.paymentMethod.value.id,
                                                deliveryMethod: checkout.deliveryMethod.value.id,
                                                cart,
                                                companyInfo,
                                                notes
                                            };

                                            let deliveryAccountInfo: any = null;
                                            if (checkout.otherDeliveryAddress.value) {
                                                deliveryAccountInfo = {
                                                    firstName: checkout.otherFirstName.value,
                                                    lastName: checkout.otherLastName.value,
                                                    address: checkout.otherAddress.value,
                                                    city: checkout.otherCity.value,
                                                    zipcode: checkout.otherZipcode.value,
                                                    telephone: checkout.otherTelephone.value,
                                                    email: checkout.otherEmail.value,
                                                    state: checkout.otherState.value
                                                }
                                            }

                                            if (deliveryAccountInfo) {
                                                finalData = {
                                                    ...finalData,
                                                    deliveryAccountInfo,
                                                };
                                            }

                                            let config;

                                            if (user) {
                                                config = authorizationBearer(user.jwt);
                                            }
                                            axios.post(BACKEND_URL + "api/orders", {data: finalData}, config)
                                                .then((resp) => {
                                                    window.localStorage.removeItem('cart');
                                                    navigate("/" + ORDER_ROUTE + resp.data.identifier, {
                                                        state: {
                                                            order: resp.data.order,
                                                            isNewOrder: true,
                                                        },
                                                    });
                                                    dispatch({
                                                        type: CHECKOUT_CLEAR_STATE,
                                                        user
                                                    });
                                                }).catch((error) => {
                                                setIsServerError(true);
                                                console.log(error);
                                            }).finally(() => {
                                                setSubmittingOrder(false);
                                            })
                                        }
                                    }}
                                    type="submit"
                                    className={(isServerError || !products || products.length < 1 ? "bg-gray-500 hover:bg-gray-500 focus:ring-gray-400 " : "") + "flex flex-row justify-center mb-4 w-full bg-btn-main border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-btn-main"}
                                >
                                    Záväzne objednať {submittingOrder ?
                                    <SpinnerLoader classname={"ml-2 w-5 h-5"}/> : null}
                                </button>
                                <p className={"font-normal text-sm text-black"}>
                                    Kliknutím na tlačidlo "Závezne objednať" vyhlasujete, že ste sa oboznámili
                                    so <Link target="_blank" className={"underline font-bold"} to={"/sukromie"}>spracovaním
                                    osobných údajov</Link> aj s <Link target="_blank"
                                                                      className={"underline font-bold"}
                                                                      to={"/obchodnepodmienky"}>obchodnými
                                    podmienkami</Link> a chcete pokračovať na prehľad objednávky.
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {isServerError ?
                <div
                    className="items-center fixed left-1/2 top-[40%] translate-x-[-50%] z-50 flex flex-col max-w-md p-6 rounded-md shadow-lg bg-gray-200">
                    <h2 className="text-xl font-semibold leading-tight tracking-wide mb-4">Objednávka nebola
                        dokončená</h2>
                    <p className="flex-1">Objednávka nebola úspešne dokončená</p>
                    <p className="flex-1">Skúste to neskôr prosím.</p>

                    <Link
                        className={"mt-4 bg-btn-main border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-btn-main"}
                        to={"/"} onClick={() => {
                        setIsServerError(false);
                    }}>Ok</Link>

                </div> : null}
        </div>
    )
}

export default Checkout;
