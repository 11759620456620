import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {SHOP_ROUTE} from "../../../../app";
import {nanoid} from "nanoid";

interface NumberOfUnitsProps {
    productDetails: any;
}

export const ContainsProducts: FunctionComponent<NumberOfUnitsProps> = ({productDetails}) => {

    if (productDetails?.contains?.data?.length > 0) {
        return (
            <div className={"mt-4"}>
                <span className={"flex flex-row flex-wrap space-x-1"}>
                    <p>Obsahuje produkty:</p>
                    {productDetails.contains.data.map((product: any, index: number) =>
                        <>
                            {index !== 0 ? "," : null}
                            <Link key={nanoid()} className={"underline"}
                                  to={"/" + SHOP_ROUTE + product.attributes.slug}>
                                {product.attributes.name}
                            </Link>
                        </>
                    )}
                </span>
            </div>
        )
    }
    return null;
}