import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {OrderHistoryList} from "../components/eshop/order/OrderHistoryList";

interface Props {
    className?: string;
}

export const OrderHistorySection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <OrderHistoryList/>
        </NavFooterWrapperSection>
    );
}
