import React, {Component, FunctionComponent} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {DefaultPageLoading} from "../loading/DefaultPageLoading";

interface Props {
    component: Component;
}

export const PrivateRouteUserLogged: FunctionComponent<Props & any> = ({
                                                                           component,
                                                                           user,
                                                                           ...rest
                                                                       }) => {

    const location = useLocation();
    const previousLink = location?.state?.previousPath;

    if (!user.userDetailsLoaded) {
        return <DefaultPageLoading/>
    }
    return user.jwt ? <>{rest.children}</> : <Navigate {...rest} to={previousLink ?? "/"} replace/>

}