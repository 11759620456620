import React, {FunctionComponent} from "react";
import {H1} from "./H1";

interface Props {
    heading: string;
    subHeading?: string;
    description?: string;
}

export const SectionHeader: FunctionComponent<Props> = ({heading, subHeading, description}) => {
    return (
        <div className="text-center">
            <H1>{heading}</H1>
            {
                subHeading ?
                    <p className="mt-2 text-2xl sm:text-3xl leading-8 text-gray-800 font-extrabold font-sans">
                        {subHeading}
                    </p> : null
            }
            {
                description ?
                    <p className="mt-4 max-w-2xl text-xl text-gray-600 lg:mx-auto font-light">
                        {description}
                    </p> : null
            }
        </div>
    )
}
