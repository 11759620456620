import {
    CHECKOUT_ADDRESS,
    CHECKOUT_CITY,
    CHECKOUT_CLEAR_STATE,
    CHECKOUT_COMPANY_DETAILS,
    CHECKOUT_COMPANY_DIC,
    CHECKOUT_COMPANY_IC_DPH,
    CHECKOUT_COMPANY_ICO,
    CHECKOUT_COMPANY_NAME,
    CHECKOUT_DELIVERY_COMPANY_NAME_NOTE,
    CHECKOUT_DELIVERY_METHOD,
    CHECKOUT_DELIVERY_NOTE,
    CHECKOUT_DELIVERY_PAYMENT,
    CHECKOUT_EMAIL,
    CHECKOUT_FIRST_NAME,
    CHECKOUT_LAST_NAME,
    CHECKOUT_NOTE,
    CHECKOUT_OTHER_ADDRESS,
    CHECKOUT_OTHER_CITY,
    CHECKOUT_OTHER_DELIVERY_ADDRESS,
    CHECKOUT_OTHER_EMAIL,
    CHECKOUT_OTHER_FIRST_NAME,
    CHECKOUT_OTHER_LAST_NAME,
    CHECKOUT_OTHER_STATE,
    CHECKOUT_OTHER_TELEPHONE,
    CHECKOUT_OTHER_ZIPCODE,
    CHECKOUT_PAYMENT_METHOD,
    CHECKOUT_STATE,
    CHECKOUT_TELEPHONE,
    CHECKOUT_ZIPCODE,
    LOGOUT_USER,
    SET_USER,
    VALIDATE_CHECKOUT
} from "../actions/action_types";
import sanitizeHtml from 'sanitize-html';

import isEmail from 'validator/lib/isEmail';

import parsePhoneNumber from 'libphonenumber-js';
import _ from "lodash";
import {InputType} from "../../types/input-type";
import {getDefaultCheckoutStructure} from "./index";

export function adjustValue(obj: any, val: any) {
    return {...obj, value: val};
}

export function adjustValueIfNull(obj: any, val: any) {
    if (!obj || obj.value) {
        return obj;
    }
    return {...obj, value: val};
}

export function adjustValueToDefault(obj: any) {
    return {...obj, value: ''};
}

export function removeErrorValue(obj: any) {
    return {...obj, error: ''};
}

function adjustUserFieldState(state: any, userObject: any) {
    return {
        firstName: adjustValueIfNull(state.firstName, userObject.firstName.value),
        lastName: adjustValueIfNull(state.lastName, userObject.lastName.value),
        email: adjustValueIfNull(state.email, userObject.contactEmail.value),
        telephone: adjustValueIfNull(state.telephone, userObject.telephone.value),
        address: adjustValueIfNull(state.address, userObject.address.value),
        city: adjustValueIfNull(state.city, userObject.city.value),
        state: adjustValueIfNull(state.state, userObject.state.value),
        zipcode: adjustValueIfNull(state.zipcode, userObject.zipcode.value),
        companyName: adjustValueIfNull(state.companyName, userObject.companyName.value),
        companyIco: adjustValueIfNull(state.companyIco, userObject.companyIco.value),
        companyDic: adjustValueIfNull(state.companyDic, userObject.companyDic.value),
        companyIcDph: adjustValueIfNull(state.companyIcDph, userObject.companyIcDph.value),
    }
}

function adjustUserState(state: any, userObject: any) {
    return {
        firstName: adjustValueIfNull(state.firstName, userObject.userDetails.firstName),
        lastName: adjustValueIfNull(state.lastName, userObject.userDetails.lastName),
        email: adjustValueIfNull(state.email, userObject.userDetails.email),
        telephone: adjustValueIfNull(state.telephone, userObject.userDetails.telephone),
        address: adjustValueIfNull(state.address, userObject.userDetails.address),
        city: adjustValueIfNull(state.city, userObject.userDetails.city),
        state: adjustValueIfNull(state.state, userObject.userDetails.state),
        zipcode: adjustValueIfNull(state.zipcode, userObject.userDetails.zipcode),
        companyName: adjustValueIfNull(state.companyName, userObject?.companyDetails?.companyName),
        companyIco: adjustValueIfNull(state.companyIco, userObject?.companyDetails?.companyIco),
        companyDic: adjustValueIfNull(state.companyDic, userObject?.companyDetails?.companyDic),
        companyIcDph: adjustValueIfNull(state.companyIcDph, userObject?.companyDetails?.companyIcDph),
    }
}

export function checkoutReducer(state: any, action: any) {
    switch (action.type) {
        case SET_USER:
            if (!action.user) {
                return {...state}
            }
            return {
                ...state,
                ...adjustUserState(state, action.user)
            }
        case CHECKOUT_CLEAR_STATE:
            return {
                ...getDefaultCheckoutStructure(),
                ...adjustUserFieldState(getDefaultCheckoutStructure(), action.user)
            }
        case CHECKOUT_FIRST_NAME:
            return {...state, firstName: adjustValue(state.firstName, action.value)};
        case CHECKOUT_LAST_NAME:
            return {...state, lastName: adjustValue(state.lastName, action.value)};
        case CHECKOUT_EMAIL:
            return {...state, email: adjustValue(state.email, action.value)};
        case CHECKOUT_TELEPHONE:
            return {...state, telephone: adjustValue(state.telephone, action.value)};
        case CHECKOUT_ADDRESS:
            return {...state, address: adjustValue(state.address, action.value)};
        case CHECKOUT_CITY:
            return {...state, city: adjustValue(state.city, action.value)};
        case CHECKOUT_STATE:
            return {...state, state: adjustValue(state.state, action.value)};
        case CHECKOUT_ZIPCODE:
            return {...state, zipcode: adjustValue(state.zipcode, action.value)};
        case CHECKOUT_NOTE:
            return {...state, orderNote: adjustValue(state.orderNote, action.value)};
        case CHECKOUT_OTHER_DELIVERY_ADDRESS:
            if (action.value === false) {
                return {
                    ...state,
                    otherDeliveryAddress: adjustValue(state.otherDeliveryAddress, false),
                    otherFirstName: removeErrorValue(state.otherFirstName),
                    otherLastName: removeErrorValue(state.otherLastName),
                    otherEmail: removeErrorValue(state.otherEmail),
                    otherTelephone: removeErrorValue(state.otherTelephone),
                    otherAddress: removeErrorValue(state.otherAddress),
                    otherCity: removeErrorValue(state.otherCity),
                    otherZipcode: removeErrorValue(state.otherZipcode),
                    otherState: removeErrorValue(state.otherState),
                }
            }
            return {...state, otherDeliveryAddress: adjustValue(state.otherDeliveryAddress, true)};
        case CHECKOUT_COMPANY_DETAILS:
            return {...state, companyDetails: adjustValue(state.companyDetails, action.value)};
        case CHECKOUT_PAYMENT_METHOD:
            return {...state, paymentMethod: adjustValue(state.paymentMethod, action.value)};
        case CHECKOUT_DELIVERY_METHOD:
            return {...state, deliveryMethod: adjustValue(state.deliveryMethod, action.value)};
        case CHECKOUT_DELIVERY_PAYMENT:
            return {
                ...state,
                paymentMethod: adjustValue(state.paymentMethod, action.payment),
                deliveryMethod: adjustValue(state.deliveryMethod, action.delivery)
            }
        case CHECKOUT_OTHER_FIRST_NAME:
            return {...state, otherFirstName: adjustValue(state.otherFirstName, action.value)};
        case CHECKOUT_OTHER_LAST_NAME:
            return {...state, otherLastName: adjustValue(state.otherLastName, action.value)};
        case CHECKOUT_OTHER_EMAIL:
            return {...state, otherEmail: adjustValue(state.otherEmail, action.value)};
        case CHECKOUT_OTHER_TELEPHONE:
            return {...state, otherTelephone: adjustValue(state.otherTelephone, action.value)};
        case CHECKOUT_OTHER_ADDRESS:
            return {...state, otherAddress: adjustValue(state.otherAddress, action.value)};
        case CHECKOUT_OTHER_CITY:
            return {...state, otherCity: adjustValue(state.otherCity, action.value)};
        case CHECKOUT_OTHER_STATE:
            return {...state, otherState: adjustValue(state.otherState, action.value)};
        case CHECKOUT_OTHER_ZIPCODE:
            return {...state, otherZipcode: adjustValue(state.otherZipcode, action.value)};
        case CHECKOUT_COMPANY_NAME:
            return {...state, companyName: adjustValue(state.companyName, action.value)};
        case CHECKOUT_COMPANY_ICO:
            return {...state, companyIco: adjustValue(state.companyIco, action.value)};
        case CHECKOUT_COMPANY_DIC:
            return {...state, companyDic: adjustValue(state.companyDic, action.value)};
        case CHECKOUT_COMPANY_IC_DPH:
            return {...state, companyIcDph: adjustValue(state.companyIcDph, action.value)};
        case CHECKOUT_DELIVERY_COMPANY_NAME_NOTE:
            return {...state, companyNameNote: adjustValue(state.companyNameNote, action.value)};
        case CHECKOUT_DELIVERY_NOTE:
            return {...state, deliveryNote: adjustValue(state.deliveryNote, action.value)};
        case LOGOUT_USER:
            return {...state, ...getDefaultCheckoutStructure()}
        case VALIDATE_CHECKOUT:
            trimValues(state);

            let toCheck = ["firstName", "lastName", "address", "email", "telephone", "state", "zipcode", "city"];

            if (state.otherDeliveryAddress.value === true) {
                toCheck = toCheck.concat(["otherFirstName", "otherLastName", "otherAddress", "otherEmail", "otherTelephone", "otherState", "otherZipcode", "otherCity"]);
            }
            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}

export function trimValues(state: any) {
    Object.values(state).forEach((field: any) => {
        if (_.isObject()) {
            field.value = trimValue(field.value);
        }
    });
}

export function checkForErrors(state: any, toCheck: any) {
    Object.entries(state).forEach(([key, field]: any) => {
        if (toCheck.includes(key)) {
            field.value = sanitizeHtml(field.value);
            field.error = checkAndAddError(field);
        }
    });
}

export function clearState(field: any) {
    return {
        value: '',
        error: '',
        type: field.type
    }
}

export function checkAndAddError(field: any) {
    switch (field.type) {
        //TODO ADD NEW TYPES NAME, ADDRESS and do not disallow all for input type value
        case InputType.VALUE:
            return notEmptyOrError(field.value);
        case InputType.NAME:
            return notEmptyOrError(field.value) ?? (RESTRICTED_CHARS_NAME_ARRAY.some((v: string) => field.value.includes(v)) ? 'Použité neplatné znaky' : null);
        case InputType.ADDRESS:
            //TODO
            return null;
        case InputType.PASSWORD:
            return notEmptyOrErrorAndMinSize(field.value);
        case InputType.PASSWORD_LOGIN:
            return notEmptyOrError(field.value)
        case InputType.PHONE:
            return notInvalidTelephoneOrError(field.value);
        case InputType.EMAIL:
            return notInvalidEmailOrError(field.value);
        case InputType.ZIPCODE:
            return notInvalidZipCodeOrError(field.value);
        case InputType.BOOLEAN:
            return _.isUndefined(field.value) || _.isNull(field.value) ? 'Políčko musí byť začiarknuté.' : null;
        default:
            return '';
    }
}

export function canSubmit(state: any) {
    return !Object.values(state).some((val: any) => val.error && val.error.length > 0);
}

export function canSubmitAttributesCheck(attributes: any) {
    return !Object.values(attributes).some((val: any) => val.error && val.error.length > 0);
}

export function notInvalidZipCodeOrError(value: string) {
    return String(value)
        .toLowerCase()
        .match(/^(([0-9]{5})|([0-9]{3}[ ]{0,1}[0-9]{2}))$/) ? null : "Zadajte PSČ podľa vzoru 800 01, 80001";
}

export function formatSlovakTelephone(value: string) {
    return String(value)
        .toLowerCase()
        .match(/^(([0]{0,1})([1-9]{1})([0-9]{2})){1}([\ ]{0,1})((([0-9]{3})([\ ]{0,1})([0-9]{3}))|(([0-9]{2})([\ ]{0,1})([0-9]{2})([\ ]{0,1})([0-9]{2})))$/);
}

export function trimValue(value: string) {
    return value && _.isString(value) ? value.trim() : value;
}

export function notEmptyOrError(value: string) {
    return !value || value.length < 1 ? "Tento údaj je povinný" : null;
}

export function notEmptyOrErrorAndMinSize(value: string) {
    return !value || value.length < 1 ? "Tento údaj je povinný" : (value.length < 6 ? "Minimálny počet znakov je 6" : null);
}

export function notInvalidEmailOrError(value: string) {
    let emailEmpty = notEmptyOrError(value);
    return emailEmpty ?? (!isCorrectEmail(value) ? "Email nie je v správnom tvare" : null);
}

export function isCorrectEmail(value: string) {
    return isEmail(value, {blacklisted_chars: RESTRICTED_CHARS, host_blacklist: RESTRICTED_CHARS_ARRAY})
}

export const RESTRICTED_CHARS = "!#$%&'*+=?^`{|}~\"(),:;<>[\\]";

export const RESTRICTED_CHARS_NAME = "!#$%&'*+=?^`{|}~\"(),:;<>[\\]/_";

export const RESTRICTED_CHARS_ARRAY = Array.from(RESTRICTED_CHARS);

export const RESTRICTED_CHARS_NAME_ARRAY = Array.from(RESTRICTED_CHARS_NAME);

export function notInvalidTelephoneOrError(value: string) {
    return (!parsePhoneNumber(value)?.isPossible() && !formatSlovakTelephone(value) ? "Zadajte číslo podľa vzoru 0940 620 000, 0940620000, +421940650000" : null);
}
