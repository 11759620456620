import React, {FunctionComponent, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {DispatchValues, ErrorField, ValueProps} from "../Checkout";
import {roundNumber} from "../../../utils/EshopUtils";
import axios from "axios";
import {BACKEND_URL} from "../../../config";
import {ImageSkeletonStatic} from "../../skeleton/ImageSkeletonStatic";
import {OrderState} from "./OrderState";
import {nanoid} from "nanoid";
import {
    ORDER_IDENTIFIER,
    ORDER_IDENTIFIER_CHECK_ERROR,
    ORDER_IDENTIFIER_CLEAR,
    ORDER_IDENTIFIER_ERROR
} from "../../../redux/actions/action_types";
import {useDispatch, useSelector} from "react-redux";
import {canSubmit} from "../../../redux/reducers/checkout";
import {SectionPageLoader} from "../../loading/SectionPageLoader";
import {join} from "../../../utils";
import {ORDER_ROUTE} from "../../../app";
import {authorizationBearer} from "../../../utils/AuthorizationHeaderUtils";
import {DATE_FULL_OPTIONS, toLocaleTimeStringWithOption} from "../../../utils/DateTimeUtils";
import {calculateOrderPrices} from "../../../utils/OrderUtils";

interface Props {

}

interface AddressProps {
    address: any;
}

interface CheckoutInputFieldsProps {
    dispatch: DispatchValues;
    field: ValueProps;
    required: boolean;
    type: string;
    autoComplete: string;
    placeHolder?: string;
    inputType?: string;
    disabled?: boolean;
    buttonOnClick: any;
    name: any;
}

interface OrderHeaderWrapperProps {
    children?: React.ReactNode;
}

export const UserProfileWrapper: FunctionComponent<OrderHeaderWrapperProps> = ({children}) => {
    return (
        <div className="bg-white relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="max-w-3xl mx-auto px-4 py-12 sm:px-6 sm:py-16 lg:px-8">
                {children}
            </div>
        </div>
    )
}

const CheckoutInputOrderField: FunctionComponent<CheckoutInputFieldsProps> = ({
                                                                                  dispatch,
                                                                                  required,
                                                                                  type,
                                                                                  autoComplete,
                                                                                  placeHolder,
                                                                                  field,
                                                                                  inputType,
                                                                                  disabled,
                                                                                  buttonOnClick,
                                                                                  name,
                                                                              }) => {
    return (
        <div>
            <div className={"flex flex-col sm:flex-row"}>
                <label htmlFor="order" className="sr-only">
                    {placeHolder}
                </label>
                <input
                    onChange={(e) => {
                        dispatch.dispatchMethod({
                            type: dispatch.typeName,
                            value: e.target.value
                        })
                    }
                    }
                    disabled={disabled == null ? false : disabled}
                    value={field.value}
                    placeholder={placeHolder}
                    required={required}
                    type={inputType ?? "text"}
                    name={type}
                    id={type}
                    autoComplete={autoComplete}
                    className={"mt-1 h-12 w-full sm:w-80 focus:ring-btn-main focus:border-btn-main block shadow-sm sm:text-sm border-gray-300 border-[1.5px] rounded-md"}
                />
                <Button onClick={buttonOnClick} name={name}/>
            </div>
            {field.error ? <ErrorField error={field.error}/> : null}
        </div>
    )
}


export const Address: FunctionComponent<AddressProps> = ({address}) => {
    return (
        <dd className="mt-2 text-gray-700">
            <address className="not-italic">
           <span
               className="block">{address.firstName} {address.lastName}</span>
                <span className="block">{address.address}</span>
                <span
                    className="block">{address.city} {address.zipcode}</span>
                <span className="block">{address.state}</span>
                <span className="block">{address.telephone}</span>
            </address>
        </dd>
    )
}

interface ButtonProp {
    name: string;
    onClick: any;
}

const Button: FunctionComponent<ButtonProp> = ({name, onClick}) => {
    return (
        <button
            type="submit"
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-btn-main hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-btn-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onClick}
        >
            {name}
        </button>
    )
}

export function getOrderPromise(identifier: string) {
    return axios.get(BACKEND_URL + "api/order/findByIdentifier?populate=*", {
        params: {
            identifier
        }
    })
}

interface NoteProps {
    label: string;
    note: string;
}

const Note: FunctionComponent<NoteProps> = ({label, note}) => {
    return (
        note ?
            <div key={nanoid()} className="py-10 border-b border-gray-200 flex space-x-6">
                <div className="flex-auto flex flex-col">
                    <div>
                        <h4 className="font-medium text-gray-900">
                            {label}
                        </h4>
                        <p className="mt-2 text-sm text-gray-600 break-all">{note}</p>
                    </div>
                </div>
            </div> : null
    )
}

export function getUserOrders(user: any) {
    return axios.get(BACKEND_URL + "api/order/findByUser?populate=*", authorizationBearer(user.jwt));
}

export const Order: FunctionComponent<Props> = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);
    const {id} = useParams();

    const orderSelector = useSelector((state: any) => state.order);

    const [order, setOrder] = useState(location?.state?.order);
    const [loading, setLoading] = useState<boolean>(false);

    const isNewOrder: boolean = location?.state?.isNewOrder;

    useEffect(() => {
        if (id) {
            dispatch({type: ORDER_IDENTIFIER_CHECK_ERROR, value: id});
            return;
        } else {
            dispatch({type: ORDER_IDENTIFIER_CLEAR});
        }
    }, []);

    useEffect(() => {
        if (orderSelector.identifier.value && orderSelector.identifier.trigger && !orderSelector.identifier.error && !order && !loading) {
            getOrder();
        }
    }, [orderSelector.identifier]);

    function correctIdentifier(identifier: any) {
        return identifier && identifier.replaceAll("+", "%2B");
    }

    function getOrder() {
        if (loading || !canSubmit(orderSelector)) {
            return;
        }
        const correctedIdentifier = correctIdentifier(orderSelector.identifier.value);
        window.history.replaceState(null, "Objednávka", "/" + ORDER_ROUTE + correctedIdentifier);

        setLoading(true);
        getOrderPromise(correctedIdentifier).then((resp) => {
            setOrder(resp.data);
        }).catch(() => {
            dispatch({type: ORDER_IDENTIFIER_ERROR, value: "Zadaný kód neexistuje."});
        }).finally(() => {
            setLoading(false);
        });
    }

    if (!order) {
        return (
            <UserProfileWrapper>
                <h1 className="text-sm font-semibold uppercase tracking-wide text-heading-two">{!loading && user ? "Zoznam objednávok" : "Objednávka"}</h1>
                <p className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">{loading ? "Načitavam detaily objednávky" : (user ? "Vaše objednávky" : "Zobraziť súhrn objednávky")}</p>
                {loading ? <SectionPageLoader/> :
                    <form className="mt-5 sm:flex sm:items-center">
                        <div className="w-full sm:max-w-lg">
                            <CheckoutInputOrderField
                                field={orderSelector.identifier}
                                autoComplete={"type"}
                                type={"search"}
                                placeHolder={"Sledovacie číslo"}
                                required={true}
                                dispatch={{
                                    dispatchMethod: dispatch,
                                    typeName: ORDER_IDENTIFIER
                                }}
                                buttonOnClick={(e: any) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: ORDER_IDENTIFIER_CHECK_ERROR,
                                        value: orderSelector.identifier.value
                                    })
                                }}
                                name={"Vyhľadať"}/>
                        </div>
                    </form>}
            </UserProfileWrapper>
        )
    }

    const orderPrices: any = calculateOrderPrices(order);

    return (
        <UserProfileWrapper>
            <div>
                <div className={"flex flex-col-reverse sm:flex-row"}>
                    <div className={"flex-1"}>
                        <h1 className="text-sm font-semibold uppercase tracking-wide text-heading-two">{isNewOrder ? "Ďakujeme!" : "Objednávka"}</h1>
                        <p className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">{isNewOrder ? "Objednávka vytvorená" : "Prehľad objednávky"}</p>
                        <p className="mt-2 text-base text-gray-500">Vaša objednávka č. <span
                            className={"font-bold"}>{order.id}</span>{isNewOrder ? " bola vytvorená." : "."}
                        </p>
                    </div>
                    {
                        !isNewOrder ?
                            <div className={"mb-6 sm:mb-0"}>
                                <Button name={"Vyhľadať inú objednávku"} onClick={() => {
                                    window.history.replaceState(null, "Objednávky", "/" + ORDER_ROUTE);
                                    dispatch({type: ORDER_IDENTIFIER_CLEAR});
                                    setOrder(null);
                                }}/>
                            </div> : null
                    }
                </div>
                <div className={"flex flex-row mt-12"}>
                    <dl className="text-sm font-medium w-1/2">
                        <dt className="text-gray-900">Sledovacie číslo</dt>
                        <dd className="text-btn-main-hover mt-2 break-words">{order.identifier}</dd>
                    </dl>
                    <dl className="text-sm font-medium w-1/2">
                        <dt className="text-gray-900">Dátum vytvorenia</dt>
                        <dd className="text-btn-main-hover mt-2 break-words">{toLocaleTimeStringWithOption(order.createdAt, DATE_FULL_OPTIONS)}</dd>
                    </dl>
                </div>
                <div className="max-w-xl">
                    <OrderState state={order.state}/>
                </div>
            </div>

            <div className="mt-10 border-t border-gray-200">
                <h2 className="sr-only">Vaša objednávka</h2>

                <h3 className="sr-only">Predmety</h3>
                {order.products.map((product: any) => (
                    <div key={nanoid()} className="py-10 border-b border-gray-200 flex space-x-6">
                        {product.imageObject || product.image ? <img
                            src={product.image?.formats?.thumbnail?.url != null ? product.image?.formats?.thumbnail?.url : product.image.url}
                            alt={product.image.alternativeText}
                            className="flex-none w-20 h-20 sm:w-22 sm:h-22 lg:w-24 lg-h-24 object-center object-contain rounded-lg bg-white"
                        /> : <ImageSkeletonStatic/>}
                        <div className="flex-auto flex flex-col">
                            <div>
                                <h4 className="font-medium text-gray-900">
                                    <a href={product.href}>{product.name}</a>
                                </h4>
                                <p className="mt-2 text-sm text-gray-600">{product.shortDescription}</p>
                            </div>
                            {
                                product.volume || product.color ?
                                    <div className="mt-6 flex flex-col sm:flex-row flex-wrap">
                                        <dl className="flex flex-col sm:flex-row text-sm sm:divide-x divide-gray-200 space-x-0 sm:space-x-6">
                                            {
                                                product.volume ?
                                                    <div className="flex">
                                                        <dt className="font-medium text-gray-900">Objem</dt>
                                                        <dd className="ml-2 text-gray-700">{product.volume.amount} {product.volume.unit.code}</dd>
                                                    </div> : null
                                            }
                                            {product.color ?
                                                <div className="sm:pl-4 flex">
                                                    <dt className="font-medium text-gray-900">Farba</dt>
                                                    <dd className="ml-2 text-gray-700">{product.color.name}</dd>
                                                </div> : null
                                            }
                                        </dl>
                                    </div> : null
                            }
                            <div className="mt-6 flex flex-col sm:flex-row flex-wrap">
                                <dl className="flex flex-col sm:flex-row text-sm sm:divide-x divide-gray-200 space-x-0 sm:space-x-6">
                                    <div className="flex">
                                        <dt className="font-medium text-gray-900">Identifikátor</dt>
                                        <dd className="ml-2 text-gray-700">{product.identifier}</dd>
                                    </div>
                                    <div className="sm:pl-4 flex">
                                        <dt className="font-medium text-gray-900">Množstvo</dt>
                                        <dd className="ml-2 text-gray-700">{product.quantity}ks</dd>
                                    </div>
                                    <div className="sm:pl-4 flex">
                                        <dt className="font-medium text-gray-900">Cena za kus</dt>
                                        <dd className="ml-2 text-gray-700">{roundNumber(product.price.amount)} {orderPrices.currency}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                ))}

                <Note note={order.notes.orderNote} label={"Poznámka"}/>
                <Note note={order.notes.deliveryNote} label={"Informácia pre dopravcov"}/>
                <Note note={order.notes.companyNameNote} label={"Názov firmy pre doručenie"}/>

                <div className="sm:ml-40 sm:pl-6">
                    <h3 className="sr-only">Vaše informácie</h3>

                    <h4 className="sr-only">Adresa</h4>
                    <dl className="grid grid-cols-2 gap-x-6 text-sm py-10">
                        <div>
                            <dt className="font-medium text-gray-900">Dodacie údaje</dt>
                            <Address address={order.deliveryAccountInfo ?? order.billingAccountInfo}/>
                        </div>
                        <div>
                            <dt className="font-medium text-gray-900">Fakturačné údaje</dt>
                            <Address address={order.billingAccountInfo}/>
                        </div>
                    </dl>

                    <h4 className="sr-only">Platba</h4>
                    <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 text-sm py-10">
                        <div>
                            <dt className="font-medium text-gray-900">Spôsob platby</dt>
                            <dd className="mt-2 text-gray-700">
                                <p>{order?.paymentMethod?.name}</p>
                            </dd>
                        </div>
                        <div>
                            <dt className="font-medium text-gray-900">Spôsob dopravy</dt>
                            <dd className="mt-2 text-gray-700">
                                <p>{order?.deliveryMethod?.name}</p>
                            </dd>
                        </div>
                    </dl>

                    <h3 className="sr-only">Zhrnutie</h3>

                    <dl className="space-y-6 border-t border-gray-200 text-sm pt-10">
                        <div className="flex justify-between">
                            <dt className="font-medium text-gray-900">Celková cena produktov</dt>
                            <dd className="text-gray-700">{roundNumber(orderPrices.priceOfProducts)} {orderPrices.currency}</dd>
                        </div>
                        {/*<div className="flex justify-between">
                                    <dt className="flex font-medium text-gray-900">
                                        Discount
                                        <span
                                            className="rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 ml-2">STUDENT50</span>
                                    </dt>
                                    <dd className="text-gray-700">-$18.00 (50%)</dd>
                                </div>*/}

                        {
                            order?.deliveryMethod ?
                                <div className="flex justify-between">
                                    <dt className="font-medium text-gray-900">{"Doprava - " + order.deliveryMethod.name}</dt>
                                    <dd className="text-gray-700">{orderPrices.deliveryPrice == 0 ? "Zadarmo" : join([roundNumber(orderPrices.deliveryPrice), orderPrices.currency], ' ')}</dd>
                                </div> : null
                        }
                        {
                            order?.paymentMethod ?
                                <div className="flex justify-between">
                                    <dt className="font-medium text-gray-900">{"Platba - " + order.paymentMethod.name}</dt>
                                    <dd className="text-gray-700">{orderPrices.paymentPrice == 0 ? "Zadarmo" : join([roundNumber(orderPrices.paymentPrice), orderPrices.currency], ' ')}</dd>
                                </div> : null
                        }

                        <div className="flex justify-between border-t pt-4 border-gray-200">
                            <dt className="font-medium text-gray-900">Celková cena bez DPH</dt>
                            <dd className="text-gray-700">{roundNumber(orderPrices.totalPrice - orderPrices.totalTax)} {orderPrices.currency}</dd>
                        </div>
                        <div className="flex justify-between">
                            <dt className="font-medium text-gray-900">DPH</dt>
                            <dd className="text-gray-700">{roundNumber(orderPrices.totalTax)} {orderPrices.currency}</dd>
                        </div>
                        <div className="flex justify-between border-t pt-6 border-gray-200">
                            <dt className="text-lg font-bold text-gray-900">Celková cena vrátane DPH</dt>
                            <dd className="text-lg font-bold text-gray-900">{roundNumber(orderPrices.totalPrice)} {orderPrices.currency}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </UserProfileWrapper>
    )
}
