import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {Order} from "../components/eshop/order/Order";

interface Props {
    className?: string;
}

export const OrderSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Order/>
        </NavFooterWrapperSection>
    );
}
