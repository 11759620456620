import React, {FunctionComponent, useState} from "react";
import {CheckoutInputField, ErrorField} from "../eshop/Checkout";
import {LOGIN_EMAIL, LOGIN_PASSWORD, VALIDATE_LOGIN} from "../../redux/actions/action_types";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {canSubmit} from "../../redux/reducers/checkout";
import {COMPANY_NAME_ALT, BACKEND_URL} from "../../config";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {fetchUser, REGISTER_ROUTE_TO} from "../../app";

interface Props {

}

export const UserLogin: FunctionComponent<Props> = () => {

    const dispatch = useDispatch();

    const login = useSelector((state: any) => state.login);
    const [submittingLogin, setSubmittingLogin] = useState<boolean>(false);
    const [badRequest, setBadRequest] = useState<any>({status: '', name: ''});

    return (
        <div className={"min-h-full bg-gray-50"}>
            <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <a href={"/"}>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={"/logo.png"}
                            alt={COMPANY_NAME_ALT}
                        />
                    </a>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Prihlasenie</h2>
                    <p className="mt-2 text-center text-sm text-gray-600"></p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                                <CheckoutInputField field={login.email} autoComplete={"email"}
                                                    titleName={"Email"} type={"email"} required={true}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: LOGIN_EMAIL
                                                    }}/>
                                <CheckoutInputField field={login.password} autoComplete={"password"}
                                                    titleName={"Heslo"} type={"password"} required={true}
                                                    inputType={"password"}
                                                    dispatch={{
                                                        dispatchMethod: dispatch,
                                                        typeName: LOGIN_PASSWORD
                                                    }}/>
                            </div>

                            <div>
                                <button onClick={(e) => {
                                    e.preventDefault();

                                    if (submittingLogin) {
                                        return;
                                    }

                                    dispatch({type: VALIDATE_LOGIN});
                                    if (canSubmit(login)) {
                                        setSubmittingLogin(true);

                                        axios.post(BACKEND_URL + `api/auth/local`, {
                                            identifier: login.email.value,
                                            password: login.password.value
                                        }).then((resp) => {
                                                const {jwt} = resp.data;
                                                window.localStorage.setItem('jwt', jwt);
                                                fetchUser(jwt, dispatch);
                                            }
                                        ).catch((e) => {
                                            const error = e?.response?.data?.error;
                                            if (error) {
                                                setBadRequest({status: error.status, name: error.name});
                                            } else {
                                                console.log(e);
                                            }
                                            setSubmittingLogin(false);
                                        });
                                    }
                                }}
                                        className={"mx-auto flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                    Prihlásiť sa
                                    {submittingLogin ? <SpinnerLoader classname={"ml-2 w-5 h-5"}/> : null}
                                </button>
                                {
                                    badRequest?.status ?
                                        badRequest.status === 400 ?
                                            <ErrorField className={"text-center"}
                                                        error={badRequest.name === "ApplicationError" ? "Váš účet nie je potvrdený" : "Zadané údaje sa nezhodujú"}/> : null : null
                                }
                            </div>

                            <div className="flex flex-col items-center justify-center">
                                <div className="text-sm">
                                    <Link
                                        to={"/obnovitheslo"}
                                        type="button"
                                        className="font-medium text-btn-main hover:text-btn-main-hover">
                                        Zabudli ste heslo?<span aria-hidden="true"> &rarr;</span>
                                    </Link>
                                </div>

                                <div
                                    className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                    <p>
                                        nemáte ešte účet?{' '}
                                        <Link
                                            to={REGISTER_ROUTE_TO}
                                            type="button"
                                            className="font-medium text-btn-main hover:text-btn-main-hover">
                                            Zaregistrujte sa<span aria-hidden="true"> &rarr;</span>
                                        </Link>
                                    </p>
                                </div>
                                <div
                                    className="mt-4 flex justify-center text-center text-sm text-gray-500">
                                    <p>
                                        naspäť na{' '}
                                        <Link
                                            to={"/"}
                                            type="button"
                                            className="font-medium text-btn-main hover:text-btn-main-hover">
                                            Úvodnú stránku<span aria-hidden="true"> &rarr;</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
