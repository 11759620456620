export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_ITEMS_CART = 'SET_ITEMS_CART';
export const SET_CART_ITEM_STATE = 'SET_CART_ITEM_STATE';
export const CLEAR_CART_ITEM_STATE = 'CLEAR_CART_ITEM_STATE';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const UPDATE_ITEM_FROM_CART = 'UPDATE_ITEM_FROM_CART';
export const CHANGE_QUANTITY_OF_ITEM_IN_CART = 'CHANGE_QUANTITY_OF_ITEM_IN_CART';
export const CART_OPEN = "CART_OPEN";

export const CHECKOUT_FIRST_NAME = "CHECKOUT_FIRST_NAME";
export const CHECKOUT_LAST_NAME = "CHECKOUT_LAST_NAME";
export const CHECKOUT_EMAIL = 'CHECKOUT_EMAIL';
export const CHECKOUT_TELEPHONE = 'CHECKOUT_TELEPHONE';
export const CHECKOUT_ADDRESS = 'CHECKOUT_ADDRESS';
export const CHECKOUT_CITY = 'CHECKOUT_CITY';
export const CHECKOUT_STATE = 'CHECKOUT_STATE';
export const CHECKOUT_ZIPCODE = 'CHECKOUT_ZIPCODE';
export const CHECKOUT_NOTE = 'CHECKOUT_NOTE';
export const CHECKOUT_OTHER_FIRST_NAME = "CHECKOUT_OTHER_FIRST_NAME";
export const CHECKOUT_OTHER_LAST_NAME = "CHECKOUT_OTHER_LAST_NAME";
export const CHECKOUT_OTHER_EMAIL = 'CHECKOUT_OTHER_EMAIL';
export const CHECKOUT_OTHER_TELEPHONE = 'CHECKOUT_OTHER_TELEPHONE';
export const CHECKOUT_OTHER_ADDRESS = 'CHECKOUT_OTHER_ADDRESS';
export const CHECKOUT_OTHER_CITY = 'CHECKOUT_OTHER_CITY';
export const CHECKOUT_OTHER_STATE = 'CHECKOUT_OTHER_STATE';
export const CHECKOUT_OTHER_ZIPCODE = 'CHECKOUT_OTHER_ZIPCODE';
export const CHECKOUT_OTHER_DELIVERY_ADDRESS = 'CHECKOUT_OTHER_DELIVERY_ADDRESS';
export const CHECKOUT_COMPANY_NAME = 'CHECKOUT_COMPANY_NAME';
export const CHECKOUT_COMPANY_ICO = 'CHECKOUT_COMPANY_ICO';
export const CHECKOUT_COMPANY_DIC = 'CHECKOUT_COMPANY_DIC';
export const CHECKOUT_COMPANY_IC_DPH = 'CHECKOUT_COMPANY_IC_DPH';
export const CHECKOUT_DELIVERY_COMPANY_NAME_NOTE = 'CHECKOUT_DELIVERY_COMPANY_NAME_NOTE';
export const CHECKOUT_DELIVERY_NOTE = 'CHECKOUT_DELIVERY_NOTE';
export const CHECKOUT_COMPANY_DETAILS = 'CHECKOUT_COMPANY_DETAILS';
export const CHECKOUT_DELIVERY_METHOD = 'CHECKOUT_DELIVERY_METHOD';
export const CHECKOUT_PAYMENT_METHOD = 'CHECKOUT_PAYMENT_METHOD';
export const CHECKOUT_DELIVERY_PAYMENT = 'CHECKOUT_DELIVERY_PAYMENT';
export const CHECKOUT_CLEAR_STATE = 'CHECKOUT_CLEAR_STATE';

export const REGISTRATION_FIRST_NAME = "REGISTRATION_FIRST_NAME";
export const REGISTRATION_LAST_NAME = "REGISTRATION_LAST_NAME";
export const REGISTRATION_EMAIL = 'REGISTRATION_EMAIL';
export const REGISTRATION_PASSWORD = 'REGISTRATION_PASSWORD';
export const REGISTRATION_TELEPHONE = 'REGISTRATION_TELEPHONE';
export const REGISTRATION_MEMBERSHIP = 'REGISTRATION_MEMBERSHIP';
export const REGISTRATION_ADDRESS = 'REGISTRATION_ADDRESS';
export const REGISTRATION_CITY = 'REGISTRATION_CITY';
export const REGISTRATION_STATE = 'REGISTRATION_STATE';
export const REGISTRATION_ZIPCODE = 'REGISTRATION_ZIPCODE';
export const REGISTRATION_COMPANY_DETAILS = 'REGISTRATION_COMPANY_DETAILS';
export const REGISTRATION_COMPANY_NAME = 'REGISTRATION_COMPANY_NAME';
export const REGISTRATION_COMPANY_ICO = 'REGISTRATION_COMPANY_ICO';
export const REGISTRATION_COMPANY_DIC = 'REGISTRATION_COMPANY_DIC';
export const REGISTRATION_COMPANY_IC_DPH = 'REGISTRATION_COMPANY_IC_DPH';
export const REGISTRATION_CLEAR_STATE = 'REGISTRATION_CLEAR_STATE';

export const USER_FIRST_NAME = "USER_FIRST_NAME";
export const USER_LAST_NAME = "USER_LAST_NAME";
export const USER_EMAIL = 'USER_EMAIL';
export const USER_CONTACT_EMAIL = 'USER_CONTACT_EMAIL';
export const USER_REMOVE_PASSWORD_STATES = 'USER_REMOVE_PASSWORD_STATES'
export const USER_CURRENT_PASSWORD = 'USER_CURRENT_PASSWORD';
export const USER_NEW_PASSWORD = 'USER_NEW_PASSWORD';
export const USER_NEW_PASSWORD_CONFIRMATION = 'USER_NEW_PASSWORD_CONFIRMATION';
export const VALIDATE_CHANGE_PASSWORD = 'VALIDATE_CHANGE_PASSWORD';
export const USER_TELEPHONE = 'USER_TELEPHONE';
export const USER_ADDRESS = 'USER_ADDRESS';
export const USER_CITY = 'USER_CITY';
export const USER_STATE = 'USER_STATE';
export const USER_ZIPCODE = 'USER_ZIPCODE';
export const USER_COMPANY_NAME = 'USER_COMPANY_NAME';
export const USER_COMPANY_ICO = 'USER_COMPANY_ICO';
export const USER_COMPANY_DIC = 'USER_COMPANY_DIC';
export const USER_COMPANY_IC_DPH = 'USER_COMPANY_IC_DPH';
export const USER_MEMBERSHIP = 'USER_MEMBERSHIP';
export const USER_JWT = 'USER_JWT';

export const ORDER_IDENTIFIER = 'ORDER_IDENTIFIER';
export const ORDER_IDENTIFIER_ERROR = 'ORDER_IDENTIFIER_ERROR';
export const ORDER_IDENTIFIER_CLEAR = 'ORDER_IDENTIFIER_CLEAR';
export const ORDER_IDENTIFIER_CHECK_ERROR = 'ORDER_IDENTIFIER_CHECK_ERROR';
export const VALIDATE_ORDER = 'VALIDATE_ORDER';

export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const RESET_EMAIL = 'RESET_EMAIL';
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_CONFIRMATION = "CHANGE_PASSWORD_CONFIRMATION";
export const VALIDATE_CHANGE = "VALIDATE_CHANGE";
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD';
export const LOGIN_REMEMBER = 'LOGIN_REMEMBER';
export const VALIDATE_CHECKOUT = "VALIDATE_CHECKOUT";
export const VALIDATE_REGISTRATION = "VALIDATE_REGISTRATION";
export const VALIDATE_LOGIN = "VALIDATE_LOGIN";
export const VALIDATE_RESET = "VALIDATE_RESET";
export const VALIDATE_USER_CHANGE = "VALIDATE_USER_CHANGE";
