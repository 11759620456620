import React, {FunctionComponent} from "react";

interface NumberOfUnitsProps {
    selectedVariation: any;
}

export const NumberOfUnits: FunctionComponent<NumberOfUnitsProps> = ({selectedVariation}) => {
    return (
        <div className={"mt-6 mb-2 flex flex-col"}>
            {selectedVariation.stockQuantity > 0 ?
                selectedVariation.stockQuantity > 5 ?
                    <p className={"text-success-product"}>
                        Viac než 5 ks na sklade
                    </p> :
                    <p className={"text-warning-product"}>{selectedVariation.stockQuantity} ks
                        na
                        sklade</p>
                : <p className={"text-sold-product"}>Vypredané</p>
            }
            <p className={"text-base text-gray-700"}>Kód: {selectedVariation.variationIdentifier}</p>
        </div>
    )
}