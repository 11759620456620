import React, {FunctionComponent} from "react";
import {ContainerLoading} from "../common/Container";
import {SpinnerLoader} from "./SpinnerLoader";

interface Props {
    className?: string;
}

export const SectionPageLoader: FunctionComponent<Props> = () => {
    return (
        <ContainerLoading>
            <SpinnerLoader classname={"w-12 h-12"}/>
        </ContainerLoading>
    )
}