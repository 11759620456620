import React, {FunctionComponent, ReactNode} from "react";
import {scrollToId} from "../../hooks/ScrollUtils";
import {ChevronRightIcon} from "@heroicons/react/20/solid";

interface Props {
    image: any;
    header: any;
    className?: string;
    children?: ReactNode;
}

export const Main: FunctionComponent<Props> = ({
                                                   image,
                                                   header
                                               }) => {

    return (
        <div className="lg:px-20 md:px-6 px-4 md:py-12 py-8 max-w-7xl mx-auto">
            <div className="md:flex items-center justify-between">
                <div className="md:w-1/3">
                    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-12 mb-4 text-heading-two font-serif">{header.heading}</h1>
                    <h2 className="text-xl font-semibold leading-9 text-gray-600">{header.subHeading}</h2>
                    <p className="text-base leading-6 mt-4 text-gray-600">{header.description}</p>
                    <button onClick={() => {
                        scrollToId("#tim");
                    }} aria-label="view team"
                            className="block md:hidden focus:ring-2 focus:ring-offset-2 focus:ring-heading-one focus:outline-none mt-6 md:mt-8 text-base font-semibold leading-none text-heading-one flex items-center hover:underline">
                        Dozvedieť sa viac
                        <ChevronRightIcon className={"w-5 h-5"}/>
                    </button>
                </div>
                <div className="md:w-8/12 lg:mt-0 mt-8 md:ml-6 h-44 sm:h-60 md:h-60 lg:h-64">
                    <img src={image.data.attributes.url} alt={image.data.attributes.alternativeText}
                         className="h-full w-full block object-fill sm:object-fill rounded-lg shadow-lg"/>
                </div>
            </div>
        </div>
    );
}