import {
    REGISTRATION_ADDRESS,
    REGISTRATION_CITY,
    REGISTRATION_CLEAR_STATE,
    REGISTRATION_COMPANY_DETAILS,
    REGISTRATION_COMPANY_DIC,
    REGISTRATION_COMPANY_IC_DPH,
    REGISTRATION_COMPANY_ICO,
    REGISTRATION_COMPANY_NAME,
    REGISTRATION_EMAIL,
    REGISTRATION_FIRST_NAME,
    REGISTRATION_LAST_NAME,
    REGISTRATION_MEMBERSHIP,
    REGISTRATION_PASSWORD,
    REGISTRATION_STATE,
    REGISTRATION_TELEPHONE,
    REGISTRATION_ZIPCODE,
    VALIDATE_REGISTRATION
} from "../actions/action_types";
import {adjustValue, checkForErrors, trimValues} from "./checkout";
import {getDefaultUserRegistrationStructure} from "./index";

export function registrationReducer(state: any, action: any) {
    switch (action.type) {
        case REGISTRATION_FIRST_NAME:
            return {...state, firstName: adjustValue(state.firstName, action.value)};
        case REGISTRATION_LAST_NAME:
            return {...state, lastName: adjustValue(state.lastName, action.value)};
        case REGISTRATION_EMAIL:
            return {...state, email: adjustValue(state.email, action.value)};
        case REGISTRATION_PASSWORD:
            return {...state, password: adjustValue(state.password, action.value)};
        case REGISTRATION_TELEPHONE:
            return {...state, telephone: adjustValue(state.telephone, action.value)};
        case REGISTRATION_MEMBERSHIP:
            return {...state, membershipIdentifier: adjustValue(state.membershipIdentifier, action.value)};
        case REGISTRATION_ADDRESS:
            return {...state, address: adjustValue(state.address, action.value)};
        case REGISTRATION_CITY:
            return {...state, city: adjustValue(state.city, action.value)};
        case REGISTRATION_STATE:
            return {...state, state: adjustValue(state.state, action.value)};
        case REGISTRATION_ZIPCODE:
            return {...state, zipcode: adjustValue(state.zipcode, action.value)};
        case REGISTRATION_COMPANY_DETAILS:
            return {...state, companyDetails: adjustValue(state.companyDetails, action.value)};
        case REGISTRATION_COMPANY_NAME:
            return {...state, companyName: adjustValue(state.companyName, action.value)};
        case REGISTRATION_COMPANY_ICO:
            return {...state, companyIco: adjustValue(state.companyIco, action.value)};
        case REGISTRATION_COMPANY_DIC:
            return {...state, companyDic: adjustValue(state.companyDic, action.value)};
        case REGISTRATION_COMPANY_IC_DPH:
            return {...state, companyIcDph: adjustValue(state.companyIcDph, action.value)};
        case REGISTRATION_CLEAR_STATE:
            return {...getDefaultUserRegistrationStructure()};
        case VALIDATE_REGISTRATION:
            trimValues(state);
            let toCheck = ["firstName", "lastName", "email", "password", "address", "telephone", "state", "zipcode", "city"];

            if (state.membershipIdentifier.value) {
                toCheck.push("membershipIdentifier");
            }

            if (state.companyDetails.value) {
                toCheck.concat(["companyName", "companyIco", "companyDic", "companyIcDph"]);
            }
            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}