import {adjustValue, checkForErrors, trimValues} from "./checkout";
import {RESET_EMAIL, VALIDATE_RESET} from "../actions/action_types";

export function resetReducer(state: any, action: any) {
    switch (action.type) {
        case RESET_EMAIL:
            return {...state, email: adjustValue(state.email, action.value)};
        case VALIDATE_RESET:
            trimValues(state);
            let toCheck = ["email"];
            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}