export const DATE_FULL_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric"
};

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

export const DATE_OPTIONS_TIME: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
};

export const toLocaleTimeStringWithOption = (date: string, dateOption: Intl.DateTimeFormatOptions): string => {
    const dateObject = new Date(date);
    return dateObject ? dateObject.toLocaleTimeString("sk-SK", dateOption) : "";
}

export const toLocaleDateStringWithOption = (date: string, dateOption: Intl.DateTimeFormatOptions): string => {
    const dateObject = new Date(date);
    return dateObject ? dateObject.toLocaleDateString("sk-SK", dateOption) : "";
}

export const toLocaleDateString = (date: string): string => {
    const dateObject = new Date(date);
    return dateObject ? dateObject.toLocaleDateString() : "";
}