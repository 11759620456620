const TOP_OFFSET = 65;

export const scrollTop = (): void => {
    window.scrollTo({top: 0, behavior: 'smooth'});
};

export const scrollToId = (id: string): void => {
    let element = document.getElementById(id);
    if (element) {
        window.scrollTo({left: 0, top: element.offsetTop - TOP_OFFSET, behavior: "smooth"});
    }
};

export const scrollToIdRaw = (id: string): void => {
    let element = document.getElementById(id);
    if (element) {
        window.scrollTo({left: 0, top: element.offsetTop - TOP_OFFSET});
    }
};