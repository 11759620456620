import React, {FunctionComponent} from "react";
import {scrollTop} from "../../hooks/ScrollUtils";
import styled from "@emotion/styled";
import useWindowSizeScrollResize from "../../hooks/useWindowsSizeScrollResize";
import {ChevronUpIcon} from "@heroicons/react/24/solid";

interface ScrollTopElementProps {
    className?: string;
}

let S = styled.div<{ breakPoint: boolean }>`
  bottom: 7%;
  right: 3%;
  opacity: ${p => p.breakPoint ? 1 : 0};
  transition: all 0.5s linear;
  border-radius: 9999px;
  visibility: ${p => p.breakPoint ? "visible" : "hidden"};
`

export const ScrollTop: FunctionComponent<ScrollTopElementProps> = ({className}) => {
    const [breakPoint] = useWindowSizeScrollResize();
    return (
        <S className={"bg-scroll-top align-middle fixed cursor-pointer flex z-40"} onClick={scrollTop} breakPoint={breakPoint > 600}>
            <ChevronUpIcon className="m-2 h-6 w-6 text-black" aria-hidden="true"/>
        </S>
    )
}