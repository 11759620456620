import React, {FunctionComponent} from "react";
import {Members} from "../components/team/Members";
import NavFooterWrapperSection from "./NavFooterWrapperSection";

interface Props {
    className?: string;
}

export const MemberSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Members/>
        </NavFooterWrapperSection>
    );
}
