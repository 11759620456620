import React, {FunctionComponent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CHECKOUT_CLEAR_STATE,
    USER_ADDRESS,
    USER_CITY,
    USER_COMPANY_DIC,
    USER_COMPANY_IC_DPH,
    USER_COMPANY_ICO,
    USER_COMPANY_NAME,
    USER_CONTACT_EMAIL,
    USER_CURRENT_PASSWORD,
    USER_EMAIL,
    USER_FIRST_NAME,
    USER_LAST_NAME,
    USER_MEMBERSHIP,
    USER_NEW_PASSWORD,
    USER_NEW_PASSWORD_CONFIRMATION,
    USER_REMOVE_PASSWORD_STATES,
    USER_STATE,
    USER_TELEPHONE,
    USER_ZIPCODE,
    VALIDATE_CHANGE_PASSWORD,
    VALIDATE_USER_CHANGE
} from "../../redux/actions/action_types";
import {CheckoutSelectField, DispatchValues, ErrorField, ValueProps} from "../eshop/Checkout";
import {BACKEND_URL} from "../../config";
import axios from "axios";
import {canSubmitAttributesCheck} from "../../redux/reducers/checkout";
import {AlertSuccess} from "../notification/AlertSuccess";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {getDefaultSupportedCountries} from "../../redux/reducers";
import NavFooterWrapperSection from "../../sections/NavFooterWrapperSection";
import {ArrowRightOnRectangleIcon} from "@heroicons/react/24/solid";
import {UserProfileWrapper} from "../eshop/order/Order";
import {authorizationBearer} from "../../utils/AuthorizationHeaderUtils";

interface Props {
}

interface CheckoutInputFieldsProps {
    dispatch: DispatchValues;
    field: ValueProps;
    titleName?: string;
    required: boolean;
    type: string;
    autoComplete: string;
    placeHolder?: string;
    inputType?: string;
    disabled?: boolean;
}

const ProfileInputField: FunctionComponent<CheckoutInputFieldsProps> = ({
                                                                            dispatch,
                                                                            titleName,
                                                                            required,
                                                                            type,
                                                                            autoComplete,
                                                                            placeHolder,
                                                                            field,
                                                                            inputType,
                                                                            disabled
                                                                        }) => {

    return (
        <div>
            {titleName ?
                <label htmlFor={type}
                       className="flex flex-row block text-sm font-normal text-gray-700">{titleName}
                    {!required ? <span
                        className={"align-end ml-2 text-[0.72rem] font-normal self-end text-gray-400"}>nepovinné</span> : null}
                </label> : null
            }
            <input
                onChange={(e) => {
                    dispatch.dispatchMethod({
                        type: dispatch.typeName,
                        value: e.target.value
                    })
                }
                }
                disabled={disabled}
                value={field.value}
                placeholder={placeHolder}
                required={required}
                type={inputType ?? "text"}
                name={type}
                id={type}
                autoComplete={autoComplete}
                className={"mt-1 h-8 focus:ring-btn-main focus:border-btn-main block w-full sm:text-sm border-[1.5px] rounded-md border-gray-300 shadow-sm " + (disabled ? "bg-gray-200" : "")}
            />
            {field.error ? <ErrorField error={field.error}/> : null}
        </div>
    )
}


export const UserProfile: FunctionComponent<Props> = () => {

    const user = useSelector((state: any) => state.user);

    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [submittingChange, setSubmittingChange] = useState(false);
    const [showStatusMessage, setShowStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();

    if (!user.jwt) {
        return <></>
    }

    return (
        <NavFooterWrapperSection>
            <UserProfileWrapper>
                <div className="sm:mx-auto sm:w-full sm:max-w-3xl">

                    <h1 className="text-sm font-semibold uppercase tracking-wide text-heading-two">{"Informácie o vašom účte"}</h1>
                    <p className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">{"Profil"}</p>

                    {
                        showStatusMessage ?
                            <div className={"mt-4"}>
                                <AlertSuccess text={showStatusMessage}
                                              setIsOpen={() => setShowStatusMessage('')}/>
                            </div> : null

                    }
                    <div className="mt-8 px-4 sm:rounded-lg sm:px-10">
                        <form className="lg:grid lg:grid-cols-1 lg:gap-x-12 xl:gap-x-16">
                            <div>
                                <div>
                                    <h2 className="text-lg font-medium text-gray-900">{openChangePassword ? "Zmena hesla" : "Prihlasovacie údaje"}</h2>

                                    {
                                        !openChangePassword ?
                                            <>
                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    <ProfileInputField field={user.email} autoComplete={"email"}
                                                                       titleName={"Prihlasovací email"}
                                                                       type={"email"}
                                                                       required={true}
                                                                       disabled={true}
                                                                       inputType={"email"}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_EMAIL
                                                                       }}/>

                                                    <button onClick={(e) => {
                                                        e.preventDefault();

                                                        if (submittingChange) {
                                                            return;
                                                        }
                                                        setErrorMessage('');
                                                        dispatch({type: USER_REMOVE_PASSWORD_STATES});
                                                        setOpenChangePassword(true);
                                                        setShowStatusMessage('');
                                                    }}
                                                            className={"mx-auto mt-6 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white text-sm shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                                        Zmeniť heslo
                                                    </button>
                                                </div>

                                                <h2 className="mt-4 text-lg font-medium text-gray-900">Osobné
                                                    údaje</h2>
                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    <ProfileInputField field={user.firstName}
                                                                       autoComplete={"given-name"}
                                                                       titleName={"Meno"} type={"first-name"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_FIRST_NAME
                                                                       }}/>
                                                    <ProfileInputField field={user.lastName}
                                                                       autoComplete={"last-name"}
                                                                       titleName={"Priezvisko"} type={"first-name"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_LAST_NAME
                                                                       }}/>
                                                    <ProfileInputField field={user.telephone}
                                                                       autoComplete={"telephone"}
                                                                       titleName={"Telefón"} type={"telephone"}
                                                                       placeHolder={"Číslo v tvare 0940620000"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_TELEPHONE
                                                                       }}/>
                                                    <ProfileInputField field={user.contactEmail}
                                                                       autoComplete={"email"}
                                                                       titleName={"Kontaktný email"} type={"email"}
                                                                       required={true}
                                                                       inputType={"email"}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_CONTACT_EMAIL
                                                                       }}/>
                                                </div>

                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    <ProfileInputField field={user.address}
                                                                       autoComplete={"address"}
                                                                       titleName={"Adresa"} type={"address"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_ADDRESS
                                                                       }}/>
                                                    <ProfileInputField field={user.city} autoComplete={"city"}
                                                                       titleName={"Mesto"} type={"city"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_CITY
                                                                       }}/>
                                                    <CheckoutSelectField field={user.state} autoComplete={"state"}
                                                                         titleName={"Krajina"} type={"state"}
                                                                         required={true}
                                                                         options={getDefaultSupportedCountries()}
                                                                         dispatch={{
                                                                             dispatchMethod: dispatch,
                                                                             typeName: USER_STATE
                                                                         }}/>
                                                    <ProfileInputField field={user.zipcode}
                                                                       autoComplete={"zip-code"}
                                                                       titleName={"PSČ"} type={"zip-code"}
                                                                       required={true}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_ZIPCODE
                                                                       }}/>
                                                    <ProfileInputField field={user.membershipIdentifier}
                                                                       autoComplete={"membershipcard"}
                                                                       titleName={"Karta ENERGY"}
                                                                       type={"membershipcard"}
                                                                       required={false}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_MEMBERSHIP
                                                                       }}/>
                                                </div>

                                                <h2 className="mt-4 text-lg font-medium text-gray-900">Firemné
                                                    údaje</h2>
                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    <ProfileInputField field={user.companyName}
                                                                       autoComplete={"company-name"}
                                                                       titleName={"Firma"} type={"company-name"}
                                                                       required={false}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_COMPANY_NAME
                                                                       }}/>
                                                    <ProfileInputField field={user.companyIco}
                                                                       autoComplete={"company-ico"}
                                                                       titleName={"IČO"} type={"company-ico"}
                                                                       required={false}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_COMPANY_ICO
                                                                       }}/>
                                                    <ProfileInputField field={user.companyDic}
                                                                       autoComplete={"company-dic"}
                                                                       titleName={"DIČ"} type={"company-dic"}
                                                                       required={false}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_COMPANY_DIC
                                                                       }}/>
                                                    <ProfileInputField field={user.companyIcDph}
                                                                       autoComplete={"company-dic"}
                                                                       titleName={"IČ DPH"} type={"company-dic"}
                                                                       required={false}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_COMPANY_IC_DPH
                                                                       }}/>
                                                </div>

                                            </> :

                                            <>
                                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                                                    <ProfileInputField field={user.password} autoComplete={"email"}
                                                                       titleName={"Momentálne heslo"}
                                                                       type={"password"}
                                                                       required={true}
                                                                       inputType={"password"}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_CURRENT_PASSWORD
                                                                       }}/>

                                                    <ProfileInputField field={user.newPassword}
                                                                       autoComplete={"password"}
                                                                       titleName={"Nové heslo"} type={"password"}
                                                                       required={true}
                                                                       inputType={"password"}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_NEW_PASSWORD
                                                                       }}/>

                                                    <ProfileInputField field={user.newPasswordConfirmation}
                                                                       autoComplete={"password"}
                                                                       titleName={"Zopakujte nové heslo"}
                                                                       type={"password"}
                                                                       required={true}
                                                                       inputType={"password"}
                                                                       dispatch={{
                                                                           dispatchMethod: dispatch,
                                                                           typeName: USER_NEW_PASSWORD_CONFIRMATION
                                                                       }}/>

                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        setErrorMessage('');

                                                        if (submittingChange) {
                                                            return;
                                                        }
                                                        dispatch({type: VALIDATE_CHANGE_PASSWORD});

                                                        if (canSubmitAttributesCheck({
                                                            password: user.password,
                                                            newPassword: user.newPassword,
                                                            newPasswordConfirmation: user.newPasswordConfirmation
                                                        })) {
                                                            setSubmittingChange(true);
                                                            axios.post(BACKEND_URL + 'api/auth/change-password', {
                                                                currentPassword: user.password.value,
                                                                password: user.newPassword.value,
                                                                passwordConfirmation: user.newPasswordConfirmation.value
                                                            }, {
                                                                ...authorizationBearer(user.jwt)
                                                            }).then((res) => {
                                                                setErrorMessage('');
                                                                dispatch({type: USER_REMOVE_PASSWORD_STATES});
                                                                setShowStatusMessage("Heslo bolo úspešne zmenené.");
                                                                setOpenChangePassword(false);
                                                            }).catch((e) => {
                                                                setErrorMessage('Pri zmene hesla nastala chyba. Skúste to znova neskôr prosím.');
                                                            }).finally(() => {
                                                                setSubmittingChange(false);
                                                            });
                                                        }
                                                    }}
                                                            className={"mx-auto mt-6 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white text-sm shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                                        Zmeniť heslo
                                                        {submittingChange ?
                                                            <SpinnerLoader classname={"ml-2 w-5 h-5"}/> : null}
                                                    </button>
                                                </div>
                                            </>
                                    }
                                    <div
                                        className="mt-4 flex justify-center text-center text-sm text-gray-500 flex-col">

                                        {!openChangePassword ?
                                            <button onClick={(e) => {
                                                e.preventDefault();

                                                if (submittingChange) {
                                                    return;
                                                }

                                                setShowStatusMessage('');
                                                setErrorMessage('');
                                                dispatch({type: VALIDATE_USER_CHANGE});

                                                if (!canSubmitAttributesCheck(user)) {
                                                    return;
                                                }
                                                setSubmittingChange(true);
                                                const config = {
                                                    headers: {
                                                        'Authorization': `Bearer ${user.jwt}`
                                                    }
                                                }

                                                axios.put(BACKEND_URL + "api/users-permissions/users/me",
                                                    {
                                                        userDetails: {
                                                            firstName: user.firstName.value,
                                                            lastName: user.lastName.value,
                                                            telephone: user.telephone.value,
                                                            address: user.address.value,
                                                            city: user.city.value,
                                                            email: user.contactEmail.value,
                                                            zipcode: user.zipcode.value,
                                                            state: user.state.value
                                                        },
                                                        companyDetails: {
                                                            name: user.companyName.value,
                                                            ico: user.companyIco.value,
                                                            icDph: user.companyIcDph.value,
                                                            dic: user.companyDic.value
                                                        },
                                                        membershipIdentifier: user.membershipIdentifier.value
                                                    },
                                                    config
                                                ).then(() => {
                                                    setShowStatusMessage('Údaje boli úspešne aktualizované.');
                                                    dispatch({
                                                        type: CHECKOUT_CLEAR_STATE,
                                                        user
                                                    });
                                                }).catch((e) => {
                                                    console.log(e);
                                                    setErrorMessage('Pri aktualizovaní údajov nastala chyba. Skusto to znovu neskôr prosím.')
                                                }).finally(() => setSubmittingChange(false))
                                            }}
                                                    className={"mx-auto mt-6 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                                Aktualizovať údaje
                                                {submittingChange ?
                                                    <SpinnerLoader classname={"ml-2 w-5 h-5"}/> : null}
                                            </button> : null}

                                        {errorMessage ? <ErrorField className={"text-center"}
                                                                    error={errorMessage}/> : null}

                                        {openChangePassword ?
                                            <p className={"mt-6"}>
                                                naspäť na{' '}
                                                <button
                                                    onClick={() => {
                                                        setOpenChangePassword(false);
                                                        setErrorMessage('');
                                                    }}
                                                    type="button"
                                                    className="font-medium text-btn-main hover:text-btn-main-hover">
                                                    Profil<span aria-hidden="true"> &rarr;</span>
                                                </button>
                                            </p> : null
                                        }

                                        {!openChangePassword ?
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.localStorage.removeItem('jwt');
                                                    //dispatch({type: LOGOUT_USER});
                                                    window.location.reload();
                                                }}
                                                className={'shadow p-4 rounded-lg mt-6 font-medium text-btn-main hover:text-btn-main-hover flex flex-row gap-1 mx-auto items-center'}
                                            >
                                                <ArrowRightOnRectangleIcon className={"h-6 w-6"}/>
                                                Odhlásiť sa
                                            </button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </UserProfileWrapper>
        </NavFooterWrapperSection>
    )
}