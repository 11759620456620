import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {scrollTop} from "./scrollToUtils";

export default function ScrollToTopRouter() {
    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname && (pathname.match(/\//g) || []).length > 1 && pathname.lastIndexOf("\\") < (pathname.length - 1)) {
            return;
        }
        scrollTop()
    }, [pathname]);

    return null;
}
