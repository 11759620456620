import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {Container} from "../components/common/Container";
import {FAQItem} from "../components/faq/FAQItem";

interface Props {
    className?: string;
}

export const FAQSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Container>
                <section className="bg-white dark:bg-gray-900">
                    <div className="container max-w-4xl px-6 py-10 mx-auto">
                        <h1 className="text-4xl font-semibold text-center text-gray-800 dark:text-white">Často kladené
                            otázky</h1>
                        <div className="mt-12 space-y-8">
                            <FAQItem/>
                        </div>
                    </div>
                </section>
            </Container>
        </NavFooterWrapperSection>
    );
}
