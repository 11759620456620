import {
    ADD_ITEM_TO_CART,
    CART_OPEN,
    CHANGE_QUANTITY_OF_ITEM_IN_CART, CLEAR_CART_ITEM_STATE,
    REMOVE_ITEM_FROM_CART, SET_CART_ITEM_STATE,
    SET_ITEMS_CART,
    SET_PRODUCTS,
    SET_USER,
    UPDATE_ITEM_FROM_CART
} from "../actions/action_types";
import {ItemProps} from "../../components/eshop/Cart";
import {isSameNumber} from "../../utils/EshopUtils";

function isSameItem(cart: any, element: ItemProps, productID: number, variationID: number) {
    return isSameNumber(element.productID, productID) && isSameNumber(element.variationID, variationID);
}

export function cartReducer(state: any, action: any) {
    switch (action.type) {
        case SET_USER:
            if (!action.user) {
                return {...state, wholesalePrice: false};
            }
            return {...state, wholesalePrice: !!action?.user?.wholesalePrice}

        case SET_ITEMS_CART:
            return {...state, cart: action.cart};

        case SET_CART_ITEM_STATE:
            return {...state, cartItemState: action.cartItemState};

        case CLEAR_CART_ITEM_STATE:
            return {...state, cartItemState: ''};

        case ADD_ITEM_TO_CART:
        case CHANGE_QUANTITY_OF_ITEM_IN_CART:
            let item: ItemProps = state.cart.find((element: ItemProps) => isSameItem(state.cart, element, action.productID, action.variationID));

            if (item) {
                return {
                    ...state,
                    cart: state.cart.map((element: ItemProps) => isSameItem(state.cart, element, action.productID, action.variationID) ?
                        {...item, quantity: action.quantity} :
                        element
                    )
                };
            }
            return {
                ...state,
                cart: [...state.cart, {
                    productID: action.productID,
                    variationID: action.variationID,
                    quantity: action.quantity
                }]
            };

        case REMOVE_ITEM_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter((element: ItemProps) => !isSameItem(state.cart, element, action.productID, action.variationID))
            };

        case UPDATE_ITEM_FROM_CART:
            const index = state.cart.findIndex((prod: ItemProps) => prod.productID === action.productID);

            if (index !== 1) {
                const newCart = [];
                for (let prod of state.cart) {
                    if (prod.productID === action.productID) {
                        //TODO add check when variation doest not exist
                        prod = {
                            stockQuantity: action.variation.stockQuantity,
                            volume: action.variation.volume,
                            price: action.variation.price,
                            color: action.variation.color,
                            productID: prod.productID,
                            variationID: action.variation.id,
                            quantity: prod.quantity,
                            images: prod.images,
                            identifier: action.variation.variationIdentifier,
                            shortDescription: prod.shortDescription,
                            name: prod.name,
                        }
                    }
                    newCart.push(prod);
                }
                return {...state, cart: newCart};
            }
            return {...state};

        case CART_OPEN:
            return {...state, cartIsOpen: action.cartIsOpenStateTo};

        case SET_PRODUCTS:
            return {...state, products: action.products}

        default:
            return state;
    }
}