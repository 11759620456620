import {SectionHeader} from "../common/SectionHeader";
import {Container} from "../common/Container";
import React, {FunctionComponent, useEffect} from "react";
import Article from "./Article";
import {nanoid} from "nanoid";
import {useParams} from "react-router-dom";
import {GQL_ARTICLE_SLUG} from "../../api/GraphQL";
import {useQuery} from "@apollo/client";
import {PageNotFound} from "../error-pages/PageNotFound";
import {SectionPageLoader} from "../loading/SectionPageLoader";
import {useSelector} from "react-redux";
import {scrollToIdRaw} from "../../hooks/ScrollUtils";
import ArticleFull from "./ArticleFull";

interface Props {
    magazine: any;
    articles: any;
}

const Magazine: FunctionComponent<Props> = ({magazine, articles}) => {

    const {id} = useParams();
    const currency = useSelector((state: any) => state.shop.currency);

    const {loading, error, data} = useQuery(GQL_ARTICLE_SLUG, {
        variables: {slug: id},
        skip: id == null
    });

    useEffect(() => {
        if (data) {
            scrollToIdRaw('article');
        }
    }, [data]);

    if (loading) {
        return (
            <SectionPageLoader/>
        )
    }

    if (id && !data?.findSlug?.data) {
        return <PageNotFound/>
    }

    if (!error && data?.findSlug?.data) {
        return <ArticleFull currency={currency} data={data}/>
    }

    return (
        <Container>
            <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 mb-6 lg:mb-10">
                <div className="space-y-12">
                    <SectionHeader heading={magazine.header.heading}
                                   subHeading={magazine.header.subHeading}
                                   description={magazine.header.description}/>
                </div>
            </div>
            <div className="px-4 mx-auto">
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {articles && articles.map((article: any) =>
                        <Article key={nanoid()} article={article.attributes}/>
                    )}
                </div>
            </div>
        </Container>
    )
}

export default Magazine;
