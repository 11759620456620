import React, {FunctionComponent} from "react";

interface Props {

}


export const ContactLoader: FunctionComponent<Props> = () => {

    return (
        <div className="w-full mx-auto flex md:flex-nowrap flex-wrap md:mb-8 mt-12">
            <div
                className="md:w-1/3 flex flex-col md:ml-auto w-full mb-16 md:mb-8 animate-pulse rounded-2xl">
                <div className={"space-y-4"}>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-1/2 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-1/2 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div className={"space-y-4 mt-12"}>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-3/4 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-3/4 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-3/4 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div className={"space-y-4 mt-12"}>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="w-10/12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>

            </div>
            <div
                className="min-h-[28rem] max-h-[40rem] w-full md:w-2/3 bg-gray-300 rounded-lg overflow-hidden flex items-end justify-start relative animate-pulse"></div>
        </div>
    );
}
