import React, {FunctionComponent, useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useQuery} from "@apollo/client";
import {GQL_PRODUCT_BY_SLUG} from "../../../api/GraphQL";
import {NO_COLOR, prepareVariations} from "../../../utils/EshopUtils";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {ADD_ITEM_TO_CART} from "../../../redux/actions/action_types";
import {AddedItemState} from "./parts/AddedItemState";
import {VolumeBox} from "./parts/VolumeBox";
import {SelectedVariationStatus} from "./parts/SelectedVariationStatus";
import {ColorBox} from "./parts/ColorBox";
import {NumberOfUnits} from "./parts/NumberOfUnits";
import {SelectNumberOfProducts} from "./parts/SelectNumberOfProducts";
import {Container} from "../../common/Container";
import {nanoid} from "nanoid";
import {SectionPageLoader} from "../../loading/SectionPageLoader";
import {ContainsProducts} from "./parts/ContainsProducts";
import {CkEditorDiv} from '../../../utils';
import {PageNotificationAlt} from "../../notification/PageNotificationAlt";
import {PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../../../config";
import {NotificationOnlyWholesaleUsers} from "../../notification/NotificationOnlyWholesaleUsers";
import {getSpecificImageType} from "../../../utils/ImageUtils";
import {ImageType} from "../../../types/image-type";

interface Props {

}

export const ProductDetailsFullPage: FunctionComponent<Props> = () => {

    const {id} = useParams();
    const {state} = useLocation();
    const dispatch = useDispatch();

    const [user, wholesalePrice, currency] = useSelector((state: any) => [state.user, state.shop.wholesalePrice, state.shop.currency], shallowEqual);

    const [selectedVariation, setSelectedVariation] = useState<any>();
    const [selectedColor, setSelectedColor] = useState<any>();
    const [variations, setVariations] = useState<any>([]);
    const [variationsOrder, setVariationsOrder] = useState<any>([]);
    const [quantity, setQuantity] = useState<number>();
    const [product, setProduct] = useState<any>();
    const [notExists, setNotExist] = useState<boolean>(false);
    const [isAddedToCart, setIsAddedToCart] = useState<any>(false);

    const {loading, error, data} = useQuery(GQL_PRODUCT_BY_SLUG, {variables: {slug: id}});

    useEffect(() => {
        if (loading || error) {
            return;
        }

        if (!data?.findSlug?.data?.attributes?.variations) {
            setNotExist(true);
            return;
        }

        const {variations, variationsOrder} = prepareVariations(data.findSlug.data.attributes.variations);
        const color: any = state?.selectedColor ?? variations ? (Object.keys(variations).length === 1 && variations.hasOwnProperty(NO_COLOR)) ? NO_COLOR : Object.keys(variations)[0] : null;

        setQuantity(state?.quantity ?? 1);
        setVariations(variations);
        setVariationsOrder(variationsOrder);
        setSelectedColor(color);
        setSelectedVariation(state?.selectedVariation ?? variations[color][0]);
        setProduct({...data.findSlug.data.attributes, id: data.findSlug.data.id});
    }, [data]);

    if (loading || (!product && !notExists)) {
        return <SectionPageLoader className={"w-12 h-12"}/>
    }

    if (notExists) {
        return (
            <PageNotificationAlt title={"Produkt alebo kategória neexistuje"}
                                 description={"Zadaný produkt alebo kategória neexistuje. Názov bol zmenený alebo už nie je viacej dostupný."}/>
        )
    }

    return (
        <Container>
            <div className="pt-6">
                {/*<nav aria-label="Breadcrumb">
                    <ol role="list"
                        className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                       product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z"/>
                                    </svg>
                                </div>
                            </li>
                        ))
                        {<li className="text-sm">
                            <a href={product.href} aria-current="page"
                               className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>}
                    </ol>
                </nav>*/}
                <div className={"mt-6 grid grid-cols-1 md:grid-cols-2"}>
                    {/* Image gallery */}
                    <div className="flex flex-col overflow-hidden rounded-lg mb-4 md:mb-0">
                        <img
                            src={getSpecificImageType(product.images?.data[0], ImageType.MEDIUM)}
                            alt={product.images?.data[0]?.attributes?.alternativeText}
                            className="object-contain object-center w-full sm:w-72 md:w-80 lg:w-96 h-auto mx-auto max-h-96"
                        />
                        {
                            product.images?.data?.length > 1 ?
                                <div className={"mt-4 flex flex-row flex-wrap"}>
                                    {
                                        product.images.data.map((img: any, index: number) => {
                                                if (index !== 0) {
                                                    return (
                                                        <img key={nanoid()}
                                                             src={getSpecificImageType(img, ImageType.THUMBNAIL)}
                                                             alt={img.attributes?.alternativeText}
                                                             className="object-contain object-center w-20 h-20 hover:border rounded cursor-pointer"
                                                        />
                                                    )
                                                }
                                                return null;
                                            }
                                        )
                                    }
                                </div>
                                : null
                        }
                    </div>
                    {/* Product info */}
                    <div className="mx-auto">
                        <div>
                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">{product.name}</h1>
                            <p className={"mb-4"}>{product.shortDescription}</p>
                        </div>

                        <ContainsProducts productDetails={product}/>

                        {/* Options */}
                        {
                            !PRICE_VISIBLE_FOR_WHOLESALE_ONLY || wholesalePrice ?
                                <div className="mt-4 lg:row-span-3 lg:mt-0">
                                    <h2 className="sr-only">Product information</h2>
                                    <SelectedVariationStatus selectedVariation={selectedVariation}
                                                             wholesalePrice={wholesalePrice}
                                                             currency={currency}/>

                                    <form className="mt-6"
                                          onSubmit={(e) => {
                                              e.preventDefault();
                                              dispatch({
                                                  type: ADD_ITEM_TO_CART,
                                                  productID: product.id,
                                                  variationID: selectedVariation.id,
                                                  quantity
                                              });
                                              let event: any = new Event('storage');
                                              window.dispatchEvent(event);
                                              setIsAddedToCart(true);
                                          }}>
                                        {/* Colors */}
                                        <ColorBox variations={variations} variationsOrder={variationsOrder}
                                                  setSelectedVariation={setSelectedVariation}
                                                  setSelectedColor={setSelectedColor} selectedColor={selectedColor}/>

                                        {/* Volumes */}
                                        <VolumeBox selectedVariation={selectedVariation}
                                                   wholesalePrice={wholesalePrice} currency={currency}
                                                   selectedColor={selectedColor}
                                                   setSelectedVariation={setSelectedVariation}
                                                   variations={variations}/>

                                        <NumberOfUnits selectedVariation={selectedVariation}/>

                                        <div className={"flex flex-row mt-6"}>
                                            <SelectNumberOfProducts
                                                selectedVariation={selectedVariation}
                                                quantity={quantity} setQuantity={setQuantity}/>
                                            <button
                                                disabled={selectedVariation?.stockQuantity < 1}
                                                type="submit"
                                                className="w-full bg-btn-main border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-btn-main disabled:bg-gray-500 disabled:cursor-not-allowed"
                                            >
                                                Pridať do košíka
                                            </button>
                                        </div>
                                    </form>
                                </div> : <NotificationOnlyWholesaleUsers
                                    titleElement={<p>Produkt je dostupný len pre firemných zákazníkov.</p>}/>
                        }
                    </div>
                </div>

                <div
                    className="py-10 lg:col-span-2 lg:col-start-1 lg:pt-6 lg:pb-16 lg:pr-8">
                    <div className="mt-2">
                        <h2 className="text-lg font-medium text-gray-900 border-b pb-2">Informácie o produkte</h2>
                        <CkEditorDiv className="mt-4 space-y-6">
                                <span
                                    className="text-gray-700">{parse(DOMPurify.sanitize(user.jwt && product?.fullDescription ? product?.fullDescription : product?.description))}</span>
                        </CkEditorDiv>
                    </div>
                </div>
            </div>
            {
                isAddedToCart ?
                    <AddedItemState productDetails={product} selectedVariation={selectedVariation}
                                    wholesalePrice={wholesalePrice}
                                    currency={currency} quantity={quantity} dispatch={dispatch}
                                    setIsOpen={setIsAddedToCart}
                                    isOpen={isAddedToCart}/>
                    : null
            }
        </Container>
    )
}
