import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import Checkout from "../components/eshop/Checkout";

interface Props {
    className?: string;
}

export const CheckoutSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Checkout/>
        </NavFooterWrapperSection>
    );
}
