export enum InputType {
    PHONE = "PHONE",
    ZIPCODE = "ZIPCODE",
    VALUE = "VALUE",
    EMAIL = "EMAIL",
    BOOLEAN = "BOOLEAN",
    OBJECT = "OBJECT",
    PASSWORD = "PASSWORD",
    PASSWORD_LOGIN = "PASSWORD_LOGIN",
    NAME = "NAME",
    ADDRESS = "ADDRESS",
}
