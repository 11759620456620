import {calculatePriceWithoutTax, priceUnit} from "./EshopUtils";

export function applyDiscounts(itemToCalculate: any): number {
    if (!itemToCalculate) {
        return 0;
    }
    const discounts = itemToCalculate?.appliedDiscounts;
    let price = itemToCalculate.price;
    if (discounts) {
        for (const discount of discounts) {
            price = price - calculateDiscount(price, discount.percentage);
        }
    }
    return !price || price < 0 ? 0 : price;
}

export function calculateDiscount(price: number, percentage: number) {
    return price * percentage / 100;
}

export function calculatePriceOfProducts(order: any) {
    return order.products.reduce((sum: any, otherProduct: any) => {
        return sum + otherProduct.price.amount * otherProduct.quantity;
    }, 0);
}

export function calculateOrderPrices(order: any) {

    if (!order) {
        return;
    }

    const paymentMethod = order.paymentMethod;
    const deliveryMethod = order.deliveryMethod;

    const priceOfProducts: any = calculatePriceOfProducts(order);

    const currency = priceUnit(order.currency);
    const deliveryPrice = deliveryMethod?.price == null ? 0 : applyDiscounts(deliveryMethod);
    const paymentPrice = paymentMethod?.price == null ? 0 : applyDiscounts(paymentMethod);

    const deliveryPriceWithoutTax = calculatePriceWithoutTax(deliveryPrice);
    const paymentPriceWithoutTax = calculatePriceWithoutTax(paymentPrice);

    const priceOfProductsWithoutTax = calculatePriceWithoutTax(priceOfProducts);
    const totalPrice = priceOfProducts + deliveryPrice + paymentPrice;

    const totalTax = priceOfProducts - priceOfProductsWithoutTax + deliveryPrice - deliveryPriceWithoutTax + paymentPrice - paymentPriceWithoutTax;

    return {
        totalPrice,
        totalTax,
        priceOfProductsWithoutTax,
        paymentPriceWithoutTax,
        deliveryPriceWithoutTax,
        deliveryPrice,
        paymentPrice,
        priceOfProducts,
        currency
    }
}