import React, {Fragment, FunctionComponent, useState} from "react";
import {Combobox, Transition} from "@headlessui/react";
import {classNames} from "../../utils";
import {useQuery} from "@apollo/client";
import {GQL_FIND_PRODUCT_BY_NAME} from "../../api/GraphQL";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";
import {SHOP_ROUTE} from "../../app";
import {useDebounce} from 'use-debounce';
import sanitizeHtml from "sanitize-html";
import _ from "lodash";

interface Props {
    className?: string;
}

export const SearchProduct: FunctionComponent<Props> = () => {

    const [searchText, setSearchText] = useState('');
    const [textForQuery] = useDebounce(searchText, 250);

    const textForQueryEmpty = _.isNull(textForQuery) || _.isUndefined(textForQuery) || _.isEmpty(textForQuery)

    const {loading, error, data} = useQuery(GQL_FIND_PRODUCT_BY_NAME, {
        variables: {text: textForQuery},
        skip: textForQueryEmpty
    });

    return (
        <div className="w-96 justify-end">
            <Combobox>
                <div className="relative mt-1">
                    <div
                        className="text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-btn-main focus:border-btn-main flex items-center pl-3 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none"
                             stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                        <Combobox.Input
                            onBlur={(e: any) => {
                                e.preventDefault()
                            }}
                            placeholder={"Vyhľadajte produkt"}
                            className={classNames("h-10 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0", (!!searchText ? "text-gray-900" : "text-gray-500"))}
                            displayValue={() => searchText}
                            onChange={(event) => {
                                setSearchText(sanitizeHtml(event.target.value));
                            }}
                        />
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Combobox.Options hidden={textForQueryEmpty}
                                          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {data?.products?.data?.length === 0 ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Žiaden produkt nenájdený
                                </div>
                            ) : (
                                data?.products?.data?.map((item: any) => (
                                    <Combobox.Option
                                        key={nanoid()}
                                        className={({active}) =>
                                            `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                                                active ? 'bg-gray-200' : 'text-gray-900'
                                            }`
                                        }
                                        value={item}
                                    >
                                        <Link className={"flex flex-row items-center"}
                                              to={"/" + SHOP_ROUTE + item?.attributes?.slug}>
                                            <img className={"w-7 h-7 mr-4"}
                                                 alt={item?.attributes?.images?.data[0]?.attributes?.alternativeText}
                                                 src={item?.attributes?.images?.data[0]?.attributes?.formats?.thumbnail?.url ?? item?.attributes?.images?.data[0]?.attributes?.url}/>

                                            {item?.attributes?.name}
                                        </Link>
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}
