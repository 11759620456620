import React, {FunctionComponent, ReactNode} from "react";
import tw from 'twin.macro';

interface Props {
    className?: string;
    children?: ReactNode;
}

let C = tw.div`
 flex
 justify-center
`;

let WTW = tw.div`
 flex
 flex-col
 max-w-7xl
 mx-auto
 px-4
 sm:px-6
 w-full
 max-w-[1920px]
`

export const Container: FunctionComponent<Props> = ({className, children}) => {
    return (
        <C className={"max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12" + (className ? (" " + className) : "")}>
            <WTW className={"items-center justify-center"}>
                {children}
            </WTW>
        </C>
    );
}

export const ContainerLoading: FunctionComponent<Props> = ({className, children}) => {
    return (
        <C className={"max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 h-screen" + (className ? (" " + className) : "")}>
            <WTW className={"items-center"}>
                {children}
            </WTW>
        </C>
    );
}
