import React, {FunctionComponent} from "react";
import tw from "twin.macro";

interface Props {
    className?: string;
    children?: React.ReactNode;
}

let Heading = tw.h1`
 text-xl
 uppercase
 font-serif
 font-semibold
 tracking-wide
 text-heading-two
`

export const H1: FunctionComponent<Props> = ({className, children}) => {
    return (
        <Heading className={className}>
            {children}
        </Heading>
    );
}
