import React, {FunctionComponent, useState} from "react";
import ProductDetailsModal from "./ProductDetailsModal";
import {nanoid} from "nanoid";
import {Skeleton} from "../../skeleton/Skeleton";
import {roundNumber} from "../../../utils/EshopUtils";
import {useSelector} from "react-redux";
import {Currency} from "../../../types/Common";
import {Link} from "react-router-dom";
import _ from "lodash";
import {SHOP_ROUTE} from "../../../app";
import {join} from "../../../utils";
import styled from "@emotion/styled";
import {PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../../../config";
import {getSpecificImageType} from "../../../utils/ImageUtils";
import {ImageType} from "../../../types/image-type";

interface ProductProps {
    product: ProductDatabaseProps;
    currency: Currency;
    productId: any;
    getProductsForGivenCategory: any;
    children?: any;
    config?: any;
}

export interface ProductDatabaseProps {
    slug: string;
    description: string;
    name: string;
    shortDescription: string;
    fullDescription: string;
    variations: any;
    images: any;
    partOf: any;
    contains: any;
    documents: any;
}

interface DiscountProps {
    discount: any
}

export const FadeoutProduct = styled.div`
  bottom: 0;
  height: 2rem;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%,
  rgba(255, 255, 255, 1) 100%);
  position: absolute;
  width: 100%;
`

const DiscountModal: FunctionComponent<DiscountProps> = ({discount}) => {
    return (
        <span key={nanoid()}
              className={"absolute top-5 left-0 text-xs font-medium items-center p-2 rounded-full break-words bg-nav-background"}>-{discount}%</span>
    )
}

export function findHighestDiscount(product: any) {
    const allDiscounts: any = [];
    product && product.variations.map((variation: any) => {
        variation?.price?.discounts?.data?.length > 0 && variation.price.discounts.data.map((discount: any) => {
            allDiscounts.push(discount);
        })
    });
    if (allDiscounts.length > 0) {
        const percentages = allDiscounts.map((discount: any) => discount?.attributes?.percentage > 0 ? discount.attributes.percentage : 0);
        return percentages?.length > 0 ? Math.max(...percentages) : null;
    }
    return null;
}

export const MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW = 5;

export const Product: FunctionComponent<ProductProps> = ({
                                                             currency,
                                                             product,
                                                             productId,
                                                             config,
                                                             getProductsForGivenCategory
                                                         }) => {

    const [isOpen, setIsOpen] = useState(false);
    const wholesalePrice = useSelector((state: any) => state.shop.wholesalePrice);

    if (!product || !product.variations) {
        return null;
    }

    const firstVariation = product.variations[0];

    const colors = product.variations.map((variation: any) => variation.color?.data).filter((color: any, index: number, self: any) => !!color && self.indexOf(color) === index);

    function getLowestVariationPrice() {
        let prices = product.variations.map(function (prod: any) {
            return wholesalePrice ? prod.price.amountWholesale : prod.price.amount;
        });
        return Math.min(...prices);
    }

    const discount = findHighestDiscount(product);

    return (
        <div className={"flex flex-col"}>
            <div onClick={() => setIsOpen(true)}
                 key={nanoid()}
                 className="bg-white group relative cursor-pointer group">
                <div
                    className="flex flex-col overflow-hidden group-hover:opacity-75 w-32 sm:w-44 h-40 sm:h-48 mx-auto justify-center items-center">
                    {
                        product.images?.data?.length > 0 ?
                            <img
                                className="object-contain mx-auto rounded-2xl"
                                src={getSpecificImageType(product.images.data[0], ImageType.THUMBNAIL)}
                                alt={product.images.data[0].alternativeText}
                            />
                            :
                            <Skeleton/>
                    }
                    {discount ? <DiscountModal discount={discount}/> : null}
                </div>
                <div className="flex flex-col justify-between items-center">
                    <h3 className={"text-sm text-center sm:text-base text-gray-800 font-bold group-hover:underline mb-1"}>
                        {product?.name}
                    </h3>
                    <div className={"relative"}>
                        <p className={"text-sm break-words font-normal text-gray-900 h-20 overflow-clip"}>{product?.shortDescription}</p>
                        <FadeoutProduct/>
                    </div>
                    {
                        colors?.length > 0 ?
                            <div className={"mt-2 flex flex-col"}>
                                <div className={"flex flex-row justify-between"}>
                                    {colors.slice(0, MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW).map((color: any) =>
                                        color.attributes.img?.data ?
                                            <img key={nanoid()}
                                                 className={"w-5 h-5"}
                                                 src={color.attributes.img.data.attributes.url}
                                                 alt={color.attributes.img.data.attributes.alternativeText}/>
                                            :
                                            <span key={nanoid()}
                                                  aria-hidden="true"
                                                  style={{background: color.attributes.hexCode}}
                                                  className={'h-5 w-5 mx-1 border border-black border-opacity-10 rounded-full'}
                                            />
                                    )}
                                </div>
                                {colors.length > MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW ?
                                    <p className={"mt-1 text-sm text-center"}>+{colors.length - MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW} {colors.length - MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW === 1 ? "farba" : (colors.length - MAX_INDEX_FOR_COLORS_IN_ESHOP_VIEW < 5 ? "farby" : "farieb")}</p> : null}
                            </div> : null
                    }
                    {
                        PRICE_VISIBLE_FOR_WHOLESALE_ONLY && !wholesalePrice ? null :

                            product.variations.length === 1 ?
                                <>
                                    {firstVariation?.volume ?
                                        <p className={"mt-2 text-sm font-normal text-gray-900"}>{join([firstVariation?.volume?.amount, firstVariation?.volume?.unit?.data?.attributes?.code, firstVariation?.volume?.additionalParameter], ' ')}</p> : null
                                    }
                                    <p className={"mt-2 text-sm font-bold text-gray-900"}>{roundNumber(wholesalePrice ? firstVariation?.price?.amountWholesale : firstVariation?.price?.amount)} {currency.name}</p>
                                </>
                                :
                                <p className={"mt-2 text-sm font-bold text-gray-900"}>od {roundNumber(getLowestVariationPrice())} {currency.name}</p>
                    }
                </div>
                {
                    isOpen ?
                        <ProductDetailsModal currency={currency} productId={productId}
                                             productDetails={product}
                                             isOpen={isOpen}
                                             setIsOpen={setIsOpen}/> :
                        null
                }
            </div>
            {
                config?.data?.attributes?.linkToWhenPartOf?.data?.id && product?.partOf?.data?.length > 0 ?
                    <div className={"mx-auto mt-4"}>
                        <Link className={"bg-btn-main text-white px-4 py-1 rounded text-sm"} key={nanoid()}
                              to={"/" + SHOP_ROUTE + config.data.attributes.linkToWhenPartOf?.data?.attributes?.slug}
                              onClick={(e) => {
                                  getProductsForGivenCategory(e, config.data.attributes.linkToWhenPartOf.data);
                                  window.history.replaceState("", "", ("/" + SHOP_ROUTE + config.data.attributes.linkToWhenPartOf?.data?.attributes?.slug));
                              }}>{_.startCase(config.data.attributes.linkToWhenPartOf?.data?.attributes?.name?.toLowerCase())}</Link>
                    </div> : null

            }
        </div>
    )
}