import React, {FunctionComponent, useEffect, useState} from "react";
import {SectionPageLoader} from "../../loading/SectionPageLoader";
import {OrderHistory} from "./OrderHistory";
import {nanoid} from "nanoid";
import {getUserOrders, UserProfileWrapper} from "./Order";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {ORDER_ROUTE} from "../../../app";
import {PageNotificationAlt} from "../../notification/PageNotificationAlt";

interface Props {

}

export const OrderHistoryList: FunctionComponent<Props> = () => {

    const user = useSelector((state: any) => state.user);
    const [userOrders, setUserOrders] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user?.jwt) {
            setLoading(true);
            getUserOrders(user).then((e) => {
                setUserOrders(e?.data);
                setLoading(false);
            })
        }
    }, []);


    return (
        <UserProfileWrapper>
            <h1 className="text-sm font-semibold uppercase tracking-wide text-heading-two">{!loading && user ? "Zoznam objednávok" : "Objednávka"}</h1>
            <p className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">{loading ? "Načitavam detaily objednávky" : (user ? "Vaše objednávky" : "Zobraziť súhrn objednávky")}</p>
            {
                user?.jwt ?
                    loading ? <SectionPageLoader/> :
                        <div className={"flex flex-col gap-8 mt-6"}>
                            {userOrders?.length > 0 ?
                                userOrders?.map((order: any) =>
                                    <OrderHistory key={nanoid()} order={order}/>
                                ) :
                                <PageNotificationAlt title={"Zoznam objednávok je prázdny"}
                                                     description={"Momentálne neevidujeme žiadne objednávky priradené k vášmu účtu."}/>
                            }
                        </div>
                    : <Navigate to={"/" + ORDER_ROUTE}/>
            }
        </UserProfileWrapper>
    )
}