import {ImageType} from "../types/image-type";

export function getBaseImage(imageObject: any) {
    return imageObject?.attributes?.url;
}

export function getSpecificImageType(imageObject: any, type: ImageType) {
    if (!imageObject?.attributes) {
        return null;
    }
    const imageAttributes = imageObject.attributes;

    if (!(imageAttributes?.formats?.[type])) {
        return imageAttributes.url;
    }
    return imageAttributes.formats[type].url;
}
