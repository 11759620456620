import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {SHOP_ROUTE} from "../../../app";
import {nanoid} from "nanoid";
import {classNames} from "../../../utils";

interface Props {
    allCategoryStructures: any;
    currentlyIteratedCategoryStructure: any;
    currentlySelectedCategory: any;
    getProductsForGivenCategory: any;
    level: number;
}

interface SideBarImgProps {
    imageObject: any;
}

const EshopSideBarImg: FunctionComponent<SideBarImgProps> = ({imageObject}) => {
    return (
        <img className={"ml-2 mr-2 w-5 h-5 rounded"}
             src={imageObject.url}
             alt={imageObject.alternativeText}/>
    )
}

export const EshopSideBarButton: FunctionComponent<Props> = ({
                                                                 allCategoryStructures,
                                                                 currentlyIteratedCategoryStructure,
                                                                 level,
                                                                 getProductsForGivenCategory,
                                                                 currentlySelectedCategory,
                                                             }) => {

    const currentlyIteratedCategoryObject = allCategoryStructures.find((currentCategoryObj: any) => currentlyIteratedCategoryStructure.attributes.category.data.id === currentCategoryObj.attributes.category.data.id);
    const childCategoryStructures = allCategoryStructures.filter((iteratedCategoryStructure: any) =>
        iteratedCategoryStructure.attributes?.parentCategory?.data?.id === currentlyIteratedCategoryStructure.attributes?.category?.data?.id)


    const currentCategory = currentlyIteratedCategoryObject?.attributes?.category?.data;

    if (!currentCategory) {
        return null;
    }

    const hasChildCategoryStructure = childCategoryStructures.length > 0;
    const hasProducts = currentCategory.attributes?.products?.data?.length > 0;

    if (!hasProducts && !hasChildCategoryStructure) {
        return null;
    }
    return (
        <li>
            <div style={{paddingLeft: `${level === 0 ? 0.5 : level}rem`}}
                 className={classNames("flex items-center justify-between w-full py-2 pr-2 text-base rounded-lg text-gray-900 transition duration-75 group hover:bg-gray-100", currentlySelectedCategory === currentlyIteratedCategoryObject ? "font-bold" : null)}>
                <Link className={"w-full"}
                      to={"/" + SHOP_ROUTE + currentCategory?.attributes?.slug}
                      onClick={(e) => {
                          getProductsForGivenCategory(e, currentlyIteratedCategoryObject);
                      }}>
                    {currentCategory?.attributes?.img?.data?.attributes ?
                        <EshopSideBarImg key={nanoid()}
                                         imageObject={currentCategory.attributes.img.data.attributes}/> : null}
                    {currentCategory?.attributes?.name}
                </Link>
            </div>
            {
                hasChildCategoryStructure ?
                    <ul className={"list-none"}>
                        {childCategoryStructures?.map((linkCategory: any) =>
                            <EshopSideBarButton
                                allCategoryStructures={allCategoryStructures}
                                currentlyIteratedCategoryStructure={linkCategory}
                                currentlySelectedCategory={currentlySelectedCategory}
                                key={nanoid()}
                                level={level + 1}
                                getProductsForGivenCategory={getProductsForGivenCategory}
                            />)
                        }
                    </ul>
                    : null
            }
        </li>
    )
}