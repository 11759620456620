import {LOGIN_EMAIL, LOGIN_PASSWORD, LOGIN_REMEMBER, VALIDATE_LOGIN} from "../actions/action_types";
import {adjustValue, checkForErrors, trimValues} from "./checkout";

export function loginReducer(state: any, action: any) {
    switch (action.type) {
        case LOGIN_EMAIL:
            return {...state, email: adjustValue(state.email, action.value)};
        case LOGIN_PASSWORD:
            return {...state, password: adjustValue(state.password, action.value)};
        case LOGIN_REMEMBER:
            return {...state, remember: adjustValue(state.remember, action.value)};
        case VALIDATE_LOGIN:
            trimValues(state);
            let toCheck = ["email", "password"];
            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}