import styled from "@emotion/styled";
import React, {FunctionComponent, ReactNode, useEffect, useState} from "react";
import tw from "twin.macro";
import {Container} from "../common/Container";
import {nanoid} from "nanoid";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {SectionHeader} from "../common/SectionHeader";
import {H2} from "../common/H2";
import {Link, useParams} from "react-router-dom";
import {scrollToId} from "../../hooks/ScrollUtils";
import {PageNotFound} from "../error-pages/PageNotFound";
import {CkEditorDiv, join} from "../../utils";
import {useQuery} from "@apollo/client";
import {GQL_MEMBERS} from "../../api/GraphQL";
import {HeaderLoader} from "../loading/HeaderLoader";
import {getSectionItem} from "../../utils/SectionUtils";
import {SelectorLoader} from "../loading/SelectorLoader";

interface Props {
    className?: string;
    children?: ReactNode;
}

interface MemberProps {
    member: any;
    className?: string;
    children?: ReactNode;
}

interface SelectorProps {
    item: any;
    setItem: any;
    prefix: string;
    isSelected: boolean;
}

let WrapperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1rem;

  :not(:last-of-type) {
    margin-bottom: 3rem;
  }

  ul {
    list-style: initial;
  }

  h3 {
    font-size: 2rem;
  }
`

let ProductSquareParagraphs = tw(WrapperSection)`
  mb-16
  p-8
  bg-background-main
  rounded-xl
  shadow-md
`

const Selector: FunctionComponent<SelectorProps> = ({item, setItem, prefix, isSelected}) => {

    return (
        <Link state={{preventScroll: true}} to={prefix + item.identifier}
              className={join(["cursor-pointer hover:scale-110 duration-200", isSelected ? "scale-110" : null], ' ')}
              onClick={() => {
                  setItem(item);
                  scrollToId(MEMBERS_ID);
              }}>
            <div className="space-y-4">
                <img
                    className="h-28 w-28 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 mx-auto rounded-full object-cover object-center"
                    src={item.selector.img.data.attributes.url}
                    alt={item.selector.img.data.attributes.alternativeText}/>
                <div className="space-y-2">
                    <div className="text-xs font-medium lg:text-sm text-center">
                        <h3 className={"font-bold text-[1.08rem] mb-1"}>{item.heading}</h3>
                        <p className="text-[0.95rem] text-heading-one font-serif font-bold">{item.role}</p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const Member: FunctionComponent<MemberProps> = ({member}) => {

    if (!member) {
        return <></>
    }

    const sanitizedMainTextHtml = DOMPurify.sanitize(member.text);
    const personalText = DOMPurify.sanitize(member.personalText)

    return (
        <>
            <H2 className={"mb-12 text-center"}>{member.heading}</H2>
            <div
                className={"grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8 items-center justify-items-center mx-2 sm:mx-4 md:mx-6 lg:mx-8 mb-12"}>
                <WrapperSection>
                    <CkEditorDiv>
                        {parse(sanitizedMainTextHtml)}
                    </CkEditorDiv>
                </WrapperSection>
                <img className={"h-auto w-full max-w-[450px] rounded-md shadow-md"} src={member.img.data.attributes.url}
                     alt={member.img.data.attributes.alternativeText}/>
            </div>
            {personalText ?
                <ProductSquareParagraphs>
                    <CkEditorDiv>
                        {parse(personalText)}
                    </CkEditorDiv>
                </ProductSquareParagraphs>
                : null
            }
        </>
    )
}

const MEMBERS_ID = "members";

export const Members: FunctionComponent<Props> = () => {

    const {data, loading, error} = useQuery(GQL_MEMBERS);

    const [currentItem, setCurrentItem]: any = useState();
    const {id} = useParams(); //this is link identifier, not id from database

    useEffect(() => {
        const memberSection = data?.team?.data?.attributes?.members;
        getSectionItem(memberSection, id, currentItem, setCurrentItem, MEMBERS_ID);
    }, [id, data]);

    if (!loading && !currentItem) {
        return <PageNotFound/>
    }

    return (
        <Container>
            {
                loading ?
                    <HeaderLoader addDescription={false}/> :
                    <SectionHeader heading={data.team.data.attributes.pageHeader?.heading}
                                   subHeading={data?.team.data.attributes.pageHeader?.subHeading}
                                   description={data?.team.data.attributes.pageHeader?.description}/>
            }
            <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8">
                {
                    loading ?
                        <>
                            {Array(5).fill(0).map(() => (
                                <SelectorLoader key={nanoid()}/>
                            ))}
                        </> :
                        data?.team?.data?.attributes?.members?.map((member: any) =>
                            <Selector key={nanoid()} item={member} setItem={setCurrentItem}
                                      prefix={"/nasiterapeuti/"} isSelected={member === currentItem}/>
                        )
                }
            </div>
            <div id={MEMBERS_ID} className={"mt-8"}>
                <Member key={nanoid()} member={currentItem}/>
            </div>
        </Container>

    )
}
