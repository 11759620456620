import React, {FunctionComponent} from 'react'
import {Popover} from '@headlessui/react'
import {Bars3Icon} from "@heroicons/react/24/solid";
import {NavBarMobile} from "./NavBarMobile";
import {nanoid} from "nanoid";
import Cart from "../eshop/Cart";
import {Link} from "react-router-dom";
import {Profile} from "./Profile";
import {COMPANY_NAME} from "../../config";

interface Props {
    navigation: any;
}

interface HighlightedProps {
    item: any;
}

const HighlightedLinkJSX: FunctionComponent<HighlightedProps> = ({item}) => {
    return (
        <a className={"whitespace-nowrap inline-flex items-center justify-center px-4 py-2 mr-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-btn-main hover:bg-btn-main-hover"}
           href={item.link}>
            {item.name}
        </a>
    )
}

export const NavBar: FunctionComponent<Props> = ({navigation}) => {
    return (
        <Popover className={"relative md:mx-6"}>
            <NavBarMobile navigation={navigation}/>
            <div className={"max-w-7xl mx-auto px-2 bg-nav-background md:rounded-xl shadow"}>
                <div className={"flex space-x-10 justify-center lg:justify-between items-center py-6 "}>
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/">
                            <span className="sr-only">{COMPANY_NAME}</span>
                            <img
                                className="w-24 sm:w-28 md:w-32 h-auto"
                                src={navigation && navigation.logo.data.attributes.url}
                                alt={navigation && navigation.logo.data.attributes.alternativeText}
                            />
                        </a>
                    </div>
                    <div className={"md:hidden flex flex-row"}>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button
                                className="bg-nav-background rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-gray-600 focus:outline-none">
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon className="h-8 w-8 text-gray-600 bg-nav-background" aria-hidden="true"/>
                            </Popover.Button>
                        </div>
                    </div>
                    <Popover.Group as="nav" className="hidden md:flex space-x-6 lg:space-x-8 flex-wrap">
                        {navigation && navigation.links.map((item: any) => (
                            <Link className={"text-base font-medium text-gray-600 hover:text-btn-main"} key={nanoid()}
                                  to={item.link}>
                                {item.name}
                            </Link>
                        ))}
                    </Popover.Group>
                    <Profile/>
                    <Cart/>
                    <div className="hidden md:flex items-center justify-between lg:flex-1 lg:w-0">
                        {
                            navigation?.openingHours ?
                                <p className={"max-w-[8rem] break-words whitespace-normal text-base font-medium text-gray-500 hover:text-gray-900 mx-4"}>
                                    {navigation && navigation.openingHours}
                                </p>: null
                        }
                        <HighlightedLinkJSX item={navigation && navigation.highlightedLink}/>
                    </div>
                </div>
            </div>
        </Popover>
    )
}
