import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {useQuery} from "@apollo/client";
import {GQL_GENERAL_BUSINESS_RULES} from "../api/GraphQL";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {Container} from "../components/common/Container";
import {SectionHeader} from "../components/common/SectionHeader";
import {SectionPageLoader} from "../components/loading/SectionPageLoader";
import {CkEditorDiv} from "../utils";

interface Props {
    className?: string;
}

export const GeneralBusinessRules: FunctionComponent<Props> = () => {

    const {loading, error, data} = useQuery(GQL_GENERAL_BUSINESS_RULES);

    return (
        <NavFooterWrapperSection>
            <Container>
                {
                    loading ? <SectionPageLoader/> :
                        <div className={"w-full"}>
                            <div className={"mb-12 text-center sm:mx-12 md:mx-20 lg:mx-32"}>
                                <SectionHeader heading={"Všeobecné obchodné podmienky"}/>
                            </div>
                            <CkEditorDiv className={"break-words text-left sm:mx-12 md:mx-20 lg:mx-32"}>
                                {parse(DOMPurify.sanitize(data.config.data.attributes.generalBusinessRules))}
                            </CkEditorDiv>
                        </div>
                }
            </Container>
        </NavFooterWrapperSection>
    );
}
