import React, {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import {MAX_NUMBER_OF_ITEMS_PER_VARIATION} from "../../Cart";

interface SelectNumberOfProductsProps {
    selectedVariation: any;
    quantity: any;
    setQuantity: any;
}

export const SelectNumberOfProducts: FunctionComponent<SelectNumberOfProductsProps> = ({
                                                                                           selectedVariation,
                                                                                           quantity,
                                                                                           setQuantity
                                                                                       }) => {

    if (selectedVariation?.stockQuantity > 0) {
        return (
            <select
                value={quantity}
                onChange={(e) => {
                    setQuantity(parseInt(e.target.value));
                }}
                key={nanoid()}
                id="quantity"
                name="quantity"
                className="cursor-pointer h-full focus:ring-btn-main focus:border-btn-main py-3 mr-6 border-transparent bg-gray-200 text-gray-600 sm:text-sm rounded-md">
                {[...Array(selectedVariation.stockQuantity > 9 ? MAX_NUMBER_OF_ITEMS_PER_VARIATION : selectedVariation.stockQuantity)].map((val: any, index: number) =>
                    <option key={nanoid()}
                            value={index + 1}>{index + 1} ks</option>
                )}
            </select>
        )
    }
    return <></>
}