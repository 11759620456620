import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import ContactPage from "../components/contact/ContactPage";

interface Props {
    className?: string;
}

export const ContactSection: FunctionComponent<Props> = () => {

    return (
        <NavFooterWrapperSection>
            <ContactPage/>
        </NavFooterWrapperSection>
    );
}
