import React, {FunctionComponent} from "react";

interface Props {
    classname?: string;
}

export const TextLoader: FunctionComponent<Props> = ({classname}) => {

    return (
        <div role="status" className="animate-pulse">
            <div className="flex items-center justify-start mt-6">
                <div className="ml-2 lg:ml-0 w-full h-3 bg-gray-200 rounded-full dark:bg-gray-700 mr-3"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    );
}