import {scrollToId} from "../hooks/ScrollUtils";

export function getFirstItem(array: any) {
    return array && array.length > 0 ? array[0] : null;
}

export function getFirstObjectFromObject(data: any): any {

    if (!data) {
        return undefined;
    }

    const objectArray = Object.values(data);

    if (objectArray && objectArray.length > 0) {
        return objectArray[0];
    }
    return undefined;
}

export function getSectionItem(sectionItem: any, id: any, currentItem: any, setCurrentItem: any, scrollElementId: any) {

    if (!sectionItem) {
        return;
    }

    if (!id) {
        return setCurrentItem(getFirstItem(sectionItem));
    }

    if (currentItem?.identifier === id) {
        scrollToId(scrollElementId);
        return;
    }

    if (id) {
        let foundId = sectionItem?.find((item: any) => {
            if (item.identifier === id) {
                setCurrentItem(item);
                scrollToId(scrollElementId);
                return item.identifier;
            }
            return null;
        });
        if (!foundId) {
            setCurrentItem(null);
        }
    }
    return;

}