import React, {FunctionComponent, useState} from "react";
import {CheckoutInputField} from "../eshop/Checkout";
import {CHANGE_PASSWORD, CHANGE_PASSWORD_CONFIRMATION, VALIDATE_CHANGE} from "../../redux/actions/action_types";
import {useDispatch, useSelector} from "react-redux";
import {canSubmit} from "../../redux/reducers/checkout";
import axios from "axios";
import {COMPANY_NAME_ALT, BACKEND_URL} from "../../config";
import {PageNotification} from "../notification/PageNotification";
import {Navigate, useSearchParams} from "react-router-dom";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {LOGIN_ROUTE_TO} from "../../app";

interface Props {

}

export const PasswordChange: FunctionComponent<Props> = () => {

    const change = useSelector((state: any) => state.change);
    const dispatch = useDispatch();

    const [submittingChange, setSubmittingChange] = useState<boolean>(false);
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [searchParams] = useSearchParams();
    const resetCode = searchParams && searchParams.get("code");

    if (!resetCode) {
        return <Navigate to={"/"} replace/>
    }

    if (wasSubmitted) {
        return <PageNotification title={"Heslo bolo zmenené."} linkDescription={"Prihlásanie"}
                                 link={LOGIN_ROUTE_TO}
                                 description={"Heslo bolo úspešne zmenené. Môžete sa prihlásiť s novým heslom."}/>
    }

    return (
        <div className={"min-h-full bg-gray-50"}>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <a href={"/"}>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={"/logo.png"}
                            alt={COMPANY_NAME_ALT}
                        />
                    </a>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Zmena hesla</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                                {<CheckoutInputField field={change.password} autoComplete={"password"}
                                                     titleName={"Heslo"} type={"password"} required={true}
                                                     inputType={"password"}
                                                     dispatch={{
                                                         dispatchMethod: dispatch,
                                                         typeName: CHANGE_PASSWORD
                                                     }}/>}
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                                {<CheckoutInputField field={change.passwordConfirmation} autoComplete={"password"}
                                                     inputType={"password"}
                                                     titleName={"Potvrdenie hesla"} type={"password"} required={true}
                                                     dispatch={{
                                                         dispatchMethod: dispatch,
                                                         typeName: CHANGE_PASSWORD_CONFIRMATION
                                                     }}/>}
                            </div>

                            <div>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    if (submittingChange) {
                                        return;
                                    }
                                    dispatch({type: VALIDATE_CHANGE});

                                    if (canSubmit(change)) {
                                        setSubmittingChange(true);
                                        axios.post(BACKEND_URL + 'api/auth/reset-password', {
                                            password: change.password.value,
                                            passwordConfirmation: change.passwordConfirmation.value,
                                            code: resetCode
                                        })
                                            .then(() => setWasSubmitted(true)).catch(() => setIsError(true)).finally(() => setSubmittingChange(false))
                                    }
                                }}
                                        className={"mx-auto mt-12 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                    Zmeniť heslo
                                    {submittingChange ?
                                        <div className={"ml-2 w-7 h-7"}><SpinnerLoader
                                            classname={"w-6 h-6"}/>
                                        </div> : null}
                                </button>
                                {isError ?
                                    <p className="mt-2 text-center text-sm text-gray-600">Pri zmene hesla nastala
                                        chyba.</p> : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}