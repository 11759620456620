import React, {FunctionComponent} from "react";
import {NO_COLOR} from "../../../../utils/EshopUtils";
import {RadioGroup} from "@headlessui/react";
import {nanoid} from "nanoid";
import {ColorOption} from "../ProductDetailsModal";

interface ColorBoxProps {
    variations: any;
    variationsOrder: any;
    setSelectedColor: any;
    selectedColor: any;
    setSelectedVariation: any;
}

export const ColorBox: FunctionComponent<ColorBoxProps> = ({
                                                               variations,
                                                               variationsOrder,
                                                               selectedColor,
                                                               setSelectedColor,
                                                               setSelectedVariation
                                                           }) => {

    const colorsIdsArray: any = Object.keys(variations);
    if (colorsIdsArray?.length > 0 && (colorsIdsArray.length !== 1 || colorsIdsArray[0] !== NO_COLOR)) {
        return (
            <div>
                <h3 className="sr-only">Farba</h3>
                <RadioGroup value={selectedColor}
                            onChange={(e) => {
                                setSelectedColor(e);
                                setSelectedVariation(variations[e][0]);
                            }}
                            className="mt-4">
                    <RadioGroup.Label
                        className="sr-only"> Choose a
                        color </RadioGroup.Label>
                    <div
                        className="flex flex-wrap">
                        {variationsOrder.map((colorId: any) => (
                            variations[colorId] ?
                                <ColorOption key={nanoid()}
                                             color={variations[colorId]?.length > 0 ? variations[colorId][0]?.color?.data : null}/> : null
                        ))}
                    </div>
                </RadioGroup>
            </div>
        )
    }
    return <></>
}