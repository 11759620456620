import React, {FunctionComponent} from "react";
import {join} from "../../utils";

interface Props {
    addDescription: boolean;
}


export const HeaderLoader: FunctionComponent<Props> = ({addDescription}) => {

    return (
        <div
            className="flex flex-col select-none">
            <div className="w-40 h-6 mb-4 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse"></div>
            <div
                className={join(["w-64 h-8 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse", addDescription ? "mb-8" : null], ' ')}></div>
            {
                addDescription ?
                    <>
                        <div
                            className="w-96 h-3 mb-4 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse"></div>
                        <div
                            className="w-96 h-3 mb-4 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse"></div>
                        <div
                            className="w-96 h-3 mb-4 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse"></div>
                    </> : null
            }
        </div>
    );
}
