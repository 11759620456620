import React, {FunctionComponent, ReactNode} from "react";
import {ScrollTop} from "../components/scroll/ScrollTop";
import tw from "twin.macro";
import {GQL_HEADER_FOOTER} from "../api/GraphQL";
import {useQuery} from "@apollo/client";
import {Maintenance} from "../components/error-pages/Maintenance";
import {CookiesConsent} from "../components/cookies/CookiesConsent";
import {Footer} from "../components/footer/Footer";
import {NavBar} from "../components/navbar/NavBar";
import {DefaultPageLoading} from "../components/loading/DefaultPageLoading";
import {useSelector} from "react-redux";

interface Props {
    children?: ReactNode;
}

let MainWrapper = tw.div`
  mx-auto
  relative
`


const NavFooterWrapperSection: FunctionComponent<Props> = ({children}) => {

    const userDetailsLoaded = useSelector((state: any) => state.user.userDetailsLoaded);
    const {data, loading, error} = useQuery(GQL_HEADER_FOOTER);

    if (error) {
        return <Maintenance/>
    }

    if (loading || !userDetailsLoaded) {
        return <DefaultPageLoading/>;
    }

    return (
        <MainWrapper>
            <NavBar navigation={data.navigation.data.attributes}/>
            {children}
            <ScrollTop/>
            <CookiesConsent/>
            <Footer footer={data.footer.data.attributes}/>
        </MainWrapper>
    );
}

export default NavFooterWrapperSection;