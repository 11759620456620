import React, {FunctionComponent} from "react";
import tw from "twin.macro";
import {SpinnerLoader} from "./SpinnerLoader";
import {COMPANY_NAME_ALT} from "../../config";

interface Props {
}

let Loading = tw.div`
  w-full
  h-screen
  flex
  flex-col
  justify-center
  items-center
  bg-page-background
  text-black
`

export const ImgDefaultPages = tw.img`
  w-40
  h-auto
`

export const DefaultPageLoading: FunctionComponent<Props> = () => {

    return (
        <Loading>
            <ImgDefaultPages
                src={"/logo.png"}
                alt={COMPANY_NAME_ALT}/>
            <SpinnerLoader classname={"mt-4 w-10 h-10"}/>
        </Loading>
    );
}