import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {GQL_SERVICES} from "../api/GraphQL";
import {Section} from "../components/section/Section";

interface Props {
    className?: string;
}

export const ServicesSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Section url={GQL_SERVICES} elementId={"services"} prefixLink={"/sluzby/"}/>
        </NavFooterWrapperSection>
    );
}
