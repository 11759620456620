import React, {FunctionComponent} from "react";

interface Props {
}

export const ProductLoader: FunctionComponent<Props> = () => {

    return (
        <div
            className="bg-white p-2 sm:p-4 flex flex-col select-none ">
            <div
                className="w-28 h-32 mb-4 sm:w-40 sm:h-44 object-contain p-2 mx-auto rounded-2xl bg-gray-200 animate-pulse"></div>
            <div className="flex flex-col flex-1 gap-5 sm:p-2">
                <div className="flex flex-1 flex-col gap-3">
                    <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                </div>
                <div className="mt-auto flex flex-col gap-3 items-center mb-2">
                    <div className="bg-gray-200 w-3/4 animate-pulse h-3 rounded-2xl"></div>
                    <div className="bg-gray-200 w-3/4 animate-pulse h-3 rounded-2xl"></div>
                </div>
            </div>
        </div>
    );
}