import React, {FunctionComponent} from "react";

interface Props {
    citation: any;
    className?: string;
}

export const Testimonial: FunctionComponent<Props> = ({citation}) => {

    return (
        <div className="pt-16 lg:py-24">
            <div className="pb-16 bg-testimonial-bg lg:pb-0 lg:z-10 lg:relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                    <div className="relative lg:-my-8">
                        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"/>
                        <div
                            className="mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl px-4 sm:px-6 lg:p-0 lg:h-full">
                            <div
                                className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                                <img
                                    className="object-cover lg:h-full lg:w-full"
                                    src={citation && citation.img.data.attributes.url}
                                    alt={citation && citation.img.data.attributes.alternativeText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-12 lg:max-w-none">
                            <blockquote>
                                <div>
                                    <svg
                                        className="h-8 md:h-10 w-8 text-white opacity-40"
                                        fill="currentColor"
                                        viewBox="0 0 32 32"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                                    </svg>
                                    <p className="mt-6 text-lg md:text-xl font-normal text-white">
                                        {citation && citation.text}
                                    </p>
                                </div>
                                <footer>
                                    <p className="text-lg font-serif font-bold text-white drop-shadow-sm mt-4 mb-1.5">{citation && citation.author}</p>
                                    <p className="text-sm font-serif font-bold text-white drop-shadow-sm">Zakladateľka
                                        Centra Gara</p>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
