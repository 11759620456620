import React, {FunctionComponent} from "react";


interface Props {
    className?: string;
}

export const Skeleton: FunctionComponent<Props> = () => {
    return (
        <div className="w-full bg-white drop-shadow-md rounded-lg">
            <div className="w-full h-48 bg-slate-200"></div>
            <div className="p-3 space-y-4">
                <div className="w-2/3 h-6 bg-slate-200"></div>
                <div className="flex space-x-4">
                    <div className="w-1/3 h-3 bg-yellow-500"></div>
                    <div className="w-1/3 h-3 bg-yellow-500"></div>
                    <div className="w-1/3 h-3 bg-yellow-500"></div>
                </div>
                <div className="space-y-2">
                    <div className="w-3/4 h-3 bg-slate-200"></div>
                    <div className="w-full h-3 bg-slate-200"></div>
                    <div className="w-2/3 h-3 bg-slate-200"></div>
                </div>
            </div>
        </div>
    );
}
