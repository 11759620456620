import {Component, FunctionComponent} from "react";
import {Navigate, useLocation} from "react-router-dom";

interface Props {
    component: Component;
    user: any;
}

export const PrivateRouteLogged: FunctionComponent<Props & any> = ({component, user, ...rest}) => {

    const location = useLocation();
    const previousLink = location?.state?.previousPath;

    return !user.jwt ? <>{rest.children}</> : <Navigate {...rest} to={previousLink ?? "/"} replace/>

}