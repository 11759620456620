import React, {FunctionComponent} from "react";

interface Props {
}

export const SelectorLoader: FunctionComponent<Props> = () => {

    return (

        <li className="space-y-4 animate-pulse list-none mx-8">
            <div
                className="h-28 w-28 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 mx-auto rounded-full object-cover object-center animate-pulse bg-gray-200"/>
            <div className="space-y-2 bg-gray-200 h-8">
                <div className="text-xs font-medium lg:text-sm text-center animate-pulse bg-gray-200">
                    <h3 className={"font-bold text-[1.08rem] mb-1 animate-pulse bg-gray-200"}/>
                    <p className="text-[0.95rem] text-heading-one font-serif font-bold animate-pulse bg-gray-200"/>
                </div>
            </div>
        </li>
    );
}