import {useDispatch, useSelector} from 'react-redux';
import React, {FunctionComponent, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {MainSection} from "./sections/MainSection";
import {SET_USER} from "./redux/actions/action_types";
import axios from "axios";
import {BACKEND_URL} from "./config";
import ScrollToTopRouter from './hooks/useScrollTopRouter';
import {PrivateRouteLogged} from './components/authentication/PrivateRouteLogged';
import {PasswordReset} from "./components/admin/PasswordReset";
import {PasswordChange} from "./components/admin/PasswordChange";
import {EmailConfirmed} from "./components/admin/EmailConfirmed";
import {PageNotFound} from "./components/error-pages/PageNotFound";
import {PrivacySection} from './sections/PrivacySection';
import {EshopSection} from './sections/EshopSection';
import {CheckoutSection} from './sections/CheckoutSection';
import {OrderSection} from './sections/OrderSection';
import {GeneralBusinessRules} from './sections/GeneralBusinessRules';
import {MemberSection} from './sections/MemberSection';
import {ContactSection} from './sections/ContactSection';
import {EducationSection} from './sections/EducationSection';
import {ServicesSection} from './sections/ServicesSection';
import {AdviceSection} from './sections/AdviceSection';
import {MagazineSection} from "./sections/MagazineSection";
import {UserProfile} from "./components/user/UserProfile";
import {PrivateRouteUserLogged} from "./components/authentication/PrivateRoute";
import {UserLogin} from "./components/admin/UserLogin";
import {UserRegistration} from "./components/admin/UserRegistration";
import {EshopProductSection} from "./sections/EshopProductSection";
import {FAQSection} from "./sections/FAQSection";
import {authorizationBearer} from "./utils/AuthorizationHeaderUtils";
import {OrderHistorySection} from "./sections/OrderHistorySection";

interface Props {

}

/* General routes */
export const ID_ROUTE = ":id/";

export const ORDER_LIST_ROUTE = "objednavky/";
export const ORDER_ROUTE = "objednavka/";
export const SHOP_ROUTE = "obchod/";

export const LOGIN_ROUTE_TO = "/prihlasit";
export const REGISTER_ROUTE_TO = "/registrovat"


/* Specific routes */
export const EDUCATION_ROUTE = "vzdelavanie/";
export const SERVICE_ROUTE = "sluzby/";
export const MAGAZINE_ROUTE = "magazin/";
export const ADVICE_ROUTE = "poradna/";
export const TEAM_ROUTE = "nasiterapeuti/";



interface FetchUserObjectProps {
    setIsFetchedUser?: any;
}

export const fetchUser = (jwt: any, dispatch: any, options?: FetchUserObjectProps) => {
    if (jwt) {
        axios.get(BACKEND_URL + `api/users/me?populate=*`, {
            ...authorizationBearer(jwt)
        }).then((res) => {
            dispatch({type: SET_USER, jwt, user: res.data});
            if (options?.setIsFetchedUser) {
                options.setIsFetchedUser(true);
            }
        }).catch((e) => {
            if (e.response && e.response.status === 401) {
                window.localStorage.removeItem('jwt');
                window.location.reload();
            }
            console.log(e);
        });
    } else {
        dispatch({type: SET_USER, jwt: null, user: null});
    }
}

export const App: FunctionComponent<Props> = () => {
    const {pathname, hash, key, state} = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user);

    const [isFetchedUser, setIsFetchedUser] = useState<boolean>(false);

    useEffect(() => {
        // if not a hash link, scroll to top

        if (state?.preventScroll) {
            return;
        }

        if (hash === '') {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({behavior: "smooth"});
                }
            }, 10);
        }
    }, [pathname, hash, key]); // do this on route change

    useEffect(() => {
        if (isFetchedUser) {
            return;
        }
        const jwt = window.localStorage.getItem('jwt');
        fetchUser(jwt, dispatch, {setIsFetchedUser});
    }, [isFetchedUser]);

    return (
        <>
            <ScrollToTopRouter/>
            <Routes>
                <Route path={"prihlasit"}
                       element={<PrivateRouteLogged user={user}><UserLogin/></PrivateRouteLogged>}/>
                <Route path={"registrovat"}
                       element={<PrivateRouteLogged user={user}><UserRegistration/></PrivateRouteLogged>}/>
                <Route path={"obnovitheslo"}
                       element={<PrivateRouteLogged user={user}><PasswordReset/></PrivateRouteLogged>}/>
                <Route path={"zmenitheslo"}
                       element={<PrivateRouteLogged user={user}><PasswordChange/></PrivateRouteLogged>}/>
                <Route path={"profil/"}
                       element={<PrivateRouteUserLogged user={user}><UserProfile/></PrivateRouteUserLogged>}/>
                <Route path={SHOP_ROUTE} element={<EshopSection/>}/>
                <Route path={SHOP_ROUTE + ID_ROUTE} element={<EshopProductSection/>}/>
                <Route path={"pokladna/"} element={<CheckoutSection/>}/>
                <Route path={ORDER_ROUTE} element={<OrderSection/>}/>
                <Route path={ORDER_ROUTE + ID_ROUTE} element={<OrderSection/>}/>
                <Route path={ORDER_LIST_ROUTE} element={<OrderHistorySection/>}/>
                <Route path={"obchodnepodmienky/"} element={<GeneralBusinessRules/>}/>
                <Route path={"emailpotvrdeny"} element={<EmailConfirmed/>}/>
                <Route path={"sukromie/"} element={<PrivacySection/>}/>
                <Route path={"kontakt/"} element={<ContactSection/>}/>
                <Route path={"faq/"} element={<FAQSection/>}/>
                <Route path={TEAM_ROUTE}
                       element={<MemberSection/>}/>
                <Route path={TEAM_ROUTE + ID_ROUTE}
                       element={<MemberSection/>}/>
                <Route path={EDUCATION_ROUTE + ID_ROUTE} element={<EducationSection/>}/>
                <Route path={EDUCATION_ROUTE} element={<EducationSection/>}/>
                <Route path={SERVICE_ROUTE + ID_ROUTE} element={<ServicesSection/>}/>
                <Route path={SERVICE_ROUTE} element={<ServicesSection/>}/>
                <Route path={MAGAZINE_ROUTE} element={<MagazineSection/>}/>
                <Route path={MAGAZINE_ROUTE + ID_ROUTE} element={<MagazineSection/>}/>
                <Route path={ADVICE_ROUTE} element={<AdviceSection/>}/>
                <Route path={ADVICE_ROUTE + ID_ROUTE}
                       element={<AdviceSection/>}/>
                <Route path={"/"} element={<MainSection/>}/>
                <Route path={"*"} element={<PageNotFound/>}/>
            </Routes>
        </>
    );
}
