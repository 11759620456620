import {Link} from "react-router-dom";
import {SectionHeader} from "../common/SectionHeader";
import {FunctionComponent} from "react";
import {nanoid} from "nanoid";

interface Props {
    contact: any;
}

export const OPENING_HOURS = [
    "Pondelok : 10:00 - 17:00",
    "Utorok : 10:00 - 17:00",
    "Streda : 10:00 - 17:00",
    "Štvrtok : 13:00 - 17:00",
    "Piatok : 10:00 - 17:00",
    "Obedná prestávka : 12:30 - 13:30"
];


const Contact: FunctionComponent<Props> = ({contact}) => {

    return (
        <section id={"kontakt"}
                 className="pt-10 px-4 text-gray-600 body-font relative max-w-7xl mx-auto">
            <SectionHeader heading={contact.header.heading} subHeading={contact.header.subHeading}
                           description={contact.header.description}/>
            <div className="mt-12 px-5 mx-auto flex sm:flex-nowrap flex-wrap">
                <div
                    className="min-h-[36rem] max-h-[50rem] w-full md:w-2/3 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                    <iframe className="absolute inset-0"
                            title="map"
                            src={contact.location.map}
                            width="100%" height="100%"></iframe>
                </div>
                <div className="w-full md:w-1/3 flex flex-col md:ml-auto w-full md:mb-8 mt-8 sm:mt-0">
                    <div className="pb-16 px-4 sm:pl-6 lg:col-span-2 lg:pl-8 lg:mb-8">
                        <div className="mx-auto">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Kontaktné
                                informácie</h2>

                            <p className="mt-3 text-base leading-6 text-gray-500">V prípade akýchkoľvek otázok nás
                                neváhajte kontaktovať. Sme pripravení pomôcť každému, kto sa stratil vo svojom živote.
                                Každému, kto sa hľadá a chýba mu ten správny nadhľad. Každému kto má odvahu a rozhodol
                                sa vziať svoj život do vlastných rúk.</p>

                            <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Kontaktné
                                údaje</h3>
                            <dl className="mt-2 text-base text-gray-500">
                                <div>
                                    <dt className="sr-only">Postal address</dt>
                                    <dd>
                                        <p>Topoľová 812</p>
                                        <p>018 51 Nová Dubnica</p>
                                    </dd>
                                </div>
                                <div className="mt-6">
                                    <dt className="sr-only">Telefónne číslo</dt>
                                    <dd className="flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                        </svg>
                                        <a href="tel:+421903535523" className="no-underline"><span className="ml-3">+421 903 535 523</span></a>
                                    </dd>
                                </div>
                                <div className="mt-3">
                                    <dt className="sr-only">Telefónne číslo</dt>
                                    <dd className="flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                        </svg>
                                        <a href="tel:+421908748304" className="no-underline"><span className="ml-3">+421 908 748 304</span></a>
                                    </dd>
                                </div>
                                <div className="mt-3">
                                    <dt className="sr-only">Email</dt>
                                    <dd className="flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                        </svg>
                                        <a href="mailto:zuzanamikulova179@gmail.com" className="no-underline"><span
                                            className="ml-3">zuzanamikulova179@gmail.com</span></a>
                                    </dd>
                                </div>

                                <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Otváracie
                                    hodiny</h3>

                                {OPENING_HOURS.map((oh) => (
                                    <p className={"mt-0.5 text-[1.07rem] text-gray-500"} key={nanoid()}>{oh}</p>
                                ))}

                                <p className="mt-6 text-base text-gray-500">
                                    Viacej informácií nájdete v <Link to={"/kontakt"}
                                                                      className="font-medium text-gray-700 underline">kontaktnej
                                    sekcií</Link>.
                                </p>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;
