import React, {FunctionComponent} from "react";
import {useCookies} from 'react-cookie';
import tw from "twin.macro";
import {Link} from "react-router-dom";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

interface Props {

}

const Button = tw.button`
  px-6
  py-2
  rounded-md
  shadow-sm
  text-gray-100
  bg-btn-main
  hover:bg-btn-main-hover
`

export const CookiesConsent: FunctionComponent<Props> = () => {

    const [cookies, setCookie] = useCookies(['cookies_consent']);

    return (
        <>
            {!cookies.cookies_consent ?
                <>
                    <div className="fixed bottom-0 right-0 sm:right-8 sm:bottom-8 z-50 flex flex-col max-w-md gap-2 p-6 rounded-md shadow-xl bg-white border-[1.5px] border-gray-400">
                        <div className={"flex flex-row gap-2 items-center"}>
                            <ExclamationCircleIcon className={"w-8 h-8"}/>
                            <h2 className="text-xl font-semibold leading-tight tracking-wide">Oznámenie o používaní
                                cookies</h2>
                        </div>
                        <p className="max-w-[350px] flex-1 dark:text-gray-400">Pokračovaním v prehliadaní stránky súhlasíte s používaním cookies.
                            <Link to={"/sukromie"} rel="noopener noreferrer" className="ml-2 font-semibold">Dozvedieť sa viac<span aria-hidden="true"> &rarr;</span></Link>
                        </p>
                        <div className="flex flex-col justify-center gap-3 mt-6 sm:flex-row">
                            <Button onClick={() => {
                                let expires = new Date();
                                expires.setHours(expires.getHours() + 720);
                                setCookie('cookies_consent', true, {path: '/', expires});
                            }}>Súhlasím</Button>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}