import {checkoutReducer} from "./checkout";
import {InputType} from "../../types/input-type";
import {registrationReducer} from "./registration";
import {loginReducer} from "./login";
import {resetReducer} from "./reset";
import {changeReducer} from "./change";
import {userReducer} from "./user";
import {priceUnit} from "../../utils/EshopUtils";
import {orderReducer} from "./order";
import {cartReducer} from "./shop";

export const getDefaultSupportedCountries = () => {
    return ["Slovensko", "Česko"]
};

export const getDefaultCheckoutStructure = () => {
    return {
        firstName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        lastName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        email: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        telephone: {
            value: '',
            error: '',
            type: InputType.PHONE
        },
        address: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        city: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        state: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        zipcode: {
            value: '',
            error: '',
            type: InputType.ZIPCODE
        },
        orderNote: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        otherDeliveryAddress: {
            value: false,
            error: '',
            type: InputType.BOOLEAN
        },
        companyDetails: {
            value: false,
            error: '',
            type: InputType.BOOLEAN
        },
        paymentMethod: {
            value: '',
            error: '',
            type: InputType.OBJECT
        },
        deliveryMethod: {
            value: '',
            error: '',
            type: InputType.OBJECT
        },
        otherFirstName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        otherLastName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        otherEmail: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        otherTelephone: {
            value: '',
            error: '',
            type: InputType.PHONE
        },
        otherAddress: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        otherCity: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        otherState: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        otherZipcode: {
            value: '',
            error: '',
            type: InputType.ZIPCODE
        },
        companyName: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIco: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyDic: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIcDph: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyNameNote: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        deliveryNote: {
            value: '',
            error: '',
            type: InputType.VALUE
        }
    }
};

export const getDefaultUserProfileStructure = () => {
    return {
        firstName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        lastName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        email: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        contactEmail: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        password: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
        newPassword: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
        newPasswordConfirmation: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
        telephone: {
            value: '',
            error: '',
            type: InputType.PHONE
        },
        address: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        city: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        state: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        zipcode: {
            value: '',
            error: '',
            type: InputType.ZIPCODE
        },
        companyName: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIco: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyDic: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIcDph: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        membershipIdentifier: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        jwt: '',
        userDetailsLoaded: false
    }
};

export const getDefaultUserRegistrationStructure = () => {
    return {
        firstName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        lastName: {
            value: '',
            error: '',
            type: InputType.NAME
        },
        email: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        password: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
        telephone: {
            value: '',
            error: '',
            type: InputType.PHONE
        },
        membershipIdentifier: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        address: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        city: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        state: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        zipcode: {
            value: '',
            error: '',
            type: InputType.ZIPCODE
        },
        companyDetails: {
            value: false,
            error: '',
            type: InputType.BOOLEAN
        },
        companyName: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIco: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyDic: {
            value: '',
            error: '',
            type: InputType.VALUE
        },
        companyIcDph: {
            value: '',
            error: '',
            type: InputType.VALUE
        }
    }
}

export const getDefaultShopStructure = () => {
    return {
        cart: [],
        products: [],
        wholesalePrice: false,
        cartItemState: '',
        currency: {
            id: "EUR",
            name: priceUnit("EUR")
        }
    }
}

export const getDefaultResetPasswordStructure = () => {
    return {
        email: {
            value: '',
            error: '',
            type: InputType.EMAIL
        }
    }
}

export const getDefaultChangePasswordStructure = () => {
    return {
        password: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
        passwordConfirmation: {
            value: '',
            error: '',
            type: InputType.PASSWORD
        },
    }
}

export const getDefaultUserLoginStructure = () => {
    return {
        email: {
            value: '',
            error: '',
            type: InputType.EMAIL
        },
        password: {
            value: '',
            error: '',
            type: InputType.PASSWORD_LOGIN
        },
        remember: {
            value: true,
            error: '',
            type: InputType.BOOLEAN
        }
    }
}

export const getDefaultOrderInputStructure = () => {
    return {
        identifier: {
            value: '',
            error: '',
            type: InputType.VALUE
        }
    }
}

const defaultState = {
    shop: getDefaultShopStructure(),
    reset: getDefaultResetPasswordStructure(),
    change: getDefaultChangePasswordStructure(),
    login: getDefaultUserLoginStructure(),
    registration: getDefaultUserRegistrationStructure(),
    checkout: getDefaultCheckoutStructure(),
    user: getDefaultUserProfileStructure(),
    order: getDefaultOrderInputStructure()
};

function root(state = defaultState, action: any) {
    const shop = cartReducer(state.shop, action);
    const checkout = checkoutReducer(state.checkout, action);
    const registration = registrationReducer(state.registration, action);
    const login = loginReducer(state.login, action);
    const reset = resetReducer(state.reset, action);
    const change = changeReducer(state.change, action);
    const user = userReducer(state.user, action);
    const order = orderReducer(state.order, action);

    return {
        shop,
        checkout,
        registration,
        login,
        reset,
        change,
        user,
        order
    }
}

export default root;
