import React, {FunctionComponent, ReactNode, useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import {scrollToId} from "../../hooks/ScrollUtils";
import styled from "@emotion/styled";
import {getFirstObjectFromObject, getSectionItem} from "../../utils/SectionUtils";
import {Container} from "../common/Container";
import {Link, useParams} from "react-router-dom";
import {nanoid} from "nanoid";
import tw from "twin.macro";
import {H2} from "../common/H2";
import {H3} from "../common/H3";
import {SectionHeader} from "../common/SectionHeader";
import {CkEditorDiv} from "../../utils";
import {hasVariationWithStockQuantity} from "../eshop/Eshop";
import {Product} from "../eshop/product/Product";
import {useSelector} from "react-redux";
import {HeaderLoader} from "../loading/HeaderLoader";
import {useQuery} from "@apollo/client";
import {SectionSelectorLoader} from "../loading/SectionSelectorLoader";
import {PageNotFound} from "../error-pages/PageNotFound";

interface Props {
    url?: any;
    elementId?: string;
    prefixLink?: string;
    children?: ReactNode;
}

interface ServiceProps {
    sectionContent: any;
    className?: string;
    children?: ReactNode;
}

const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  :not(:last-of-type) {
    margin-bottom: 3rem;
  }

  ul {
    list-style: initial;
  }
`

const AbstractOptionBackground = tw.div`
  shadow-md
  cursor-pointer
  w-full
  h-full
  rounded-md
`

const OptionBackground = styled(AbstractOptionBackground)<{ bgLink: string; isSelected: boolean; }>`
  background-image: url('${p => p.bgLink}');
  background-size: cover;

  transition: transform 200ms;
  transform: scale(${p => p.isSelected ? 1.10 : 1});

  :hover {
    transform: scale(1.10);
  }
`

interface SelectorProps {
    item: any;
    setItem: any;
    prefix: any;
    sectionElementId: any;
    isSelected: boolean;
}

export const Selector: FunctionComponent<SelectorProps> = ({item, sectionElementId, prefix, setItem, isSelected}) => {

    return (
        <Link state={{preventScroll: true}} to={prefix + item.identifier}
              className={"p-0 border-0 no-underline w-[12rem] h-[8rem] md:w-[14rem] md:h-[9rem] lg:w-[16rem] lg:h-[9em]"}
              onClick={() => {
                  setItem(item);
                  scrollToId(sectionElementId);
              }
              }>
            <OptionBackground bgLink={item && item.selector.img.data.attributes.url} isSelected={isSelected}>
                <div className={"w-full h-full flex items-center justify-center bg-stone-100/10"}>
                    <span
                        className={" align-middle text-center bg-heading-one/80 font-serif font-bold text-white text-base md:text-xl flex items-center justify-center rounded-xl py-3 px-2"}>
                        {item && item.selector.heading}
                    </span>
                </div>
            </OptionBackground>
        </Link>
    )
}

interface IntroductionProps {
    introduction: any;
}

const Introduction: FunctionComponent<IntroductionProps> = ({introduction}) => {
    const sanitizedDescriptionTextHtml = DOMPurify.sanitize(introduction.text);
    return (
        <div
            className={"grid gap-6 grid-cols-1 justify-items-center md:grid-cols-2 pb-8 sm:pb-10 md:pb-12 border-b-4 border-btn-main border-dashed"}>
            <CkEditorDiv>
                <H3 className={"my-4"}>{introduction.heading}</H3>
                {parse(sanitizedDescriptionTextHtml)}
            </CkEditorDiv>
            <img
                className={"object-cover w-[19rem] sm:w-[22rem] md:w-[24rem] lg:w-[26rem] h-[12rem] sm:h-[13rem] md:h-[14rem] lg:h-[16rem] shadow-md rounded-lg"}
                src={introduction.img.data.attributes.url}
                alt={introduction.img.data.attributes.alternativeText}/>
        </div>
    )
}

const SectionContent: FunctionComponent<ServiceProps> = ({sectionContent}) => {

    const currency = useSelector((state: any) => state.shop.currency);

    if (!sectionContent) {
        return <></>
    }

    const sanitizedMainTextHtml = DOMPurify.sanitize(sectionContent.text);

    return (
        <>
            <H2 className={"my-8"}>{sectionContent.heading}</H2>
            {sectionContent.introduction && <Introduction introduction={sectionContent.introduction}/>}
            <WrapperSection className={"my-6"}>
                <CkEditorDiv>
                    {parse(sanitizedMainTextHtml)}
                </CkEditorDiv>
                {
                    sectionContent.products?.data?.length > 0 ?
                        <>
                            <H3 className={"mt-4 mb-2 text-center"}>Produkty na zakúpenie</H3>
                            <div
                                className={"grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8"}>
                                {sectionContent.products.data.map((product: any) => (
                                    hasVariationWithStockQuantity(product) ?
                                        <Product currency={currency} key={nanoid()}
                                                 getProductsForGivenCategory={null}
                                                 product={product.attributes}
                                                 productId={product.id}/> : null
                                ))}
                            </div>
                        </> : null
                }
            </WrapperSection>
        </>
    )
}


export const Section: FunctionComponent<Props> = ({url, elementId, prefixLink}) => {

    const {data, loading, error} = useQuery(url);

    const [currentItem, setCurrentItem]: any = useState();
    const {id} = useParams();

    useEffect(() => {
        const sectionData = getFirstObjectFromObject(data)?.data?.attributes?.items;
        getSectionItem(sectionData, id, currentItem, setCurrentItem, elementId);
    }, [id, data]);

    if (!loading && !currentItem) {
        return <PageNotFound/>
    }

    const firstObjectFromObject = getFirstObjectFromObject(data);

    return (
        <Container>
            {loading ? <HeaderLoader addDescription={false}/> :
                <SectionHeader heading={firstObjectFromObject?.data?.attributes?.header.heading}
                               subHeading={firstObjectFromObject?.data?.attributes?.header.subHeading}
                               description={firstObjectFromObject?.data?.attributes?.header.description}/>
            }
            <div
                className={"mt-8 grid justify-items-center grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8 md:grid-cols-4 md:gap-8 p-0"}>
                {loading ?
                    <>
                        {Array(5).fill(0).map(() => (
                            <SectionSelectorLoader key={nanoid()}/>
                        ))}
                    </> :
                    firstObjectFromObject?.data?.attributes?.items?.map((item: any) =>
                        <Selector key={nanoid()} sectionElementId={elementId} prefix={prefixLink} item={item}
                                  setItem={setCurrentItem} isSelected={currentItem?.id === item.id}/>
                    )}
            </div>
            {!loading ?
                <div id={elementId} className={"mt-12 flex flex-col flex-nowrap"}>
                    <div className={"flex flex-col items-center justify-center mx-0 sm:mx-12 md:mx-20 lg:mx-32"}>
                        <SectionContent key={nanoid()} sectionContent={currentItem}/>
                    </div>
                </div> : null}
        </Container>
    )
}
