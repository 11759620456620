import _ from "lodash";
import {PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../config";

export function roundNumber(nmb: number) {
    return Number(nmb).toFixed(2);
}

export function priceUnit(unit: any) {
    if (!unit) {
        return "€";
    }
    switch (unit) {
        case "EUR":
            return "€";
        case "USD":
            return "$";
        default:
            return "€";
    }
}

export const NO_COLOR = "NO_COLOR";
export const NO_CATEGORY = "NO_CATEGORY";
export const NUMBER_OF_ITEMS_PER_PAGE = 32;
export const TAX = 20;
export const EMPTY_STRING = "";

export function prepareVariations(inputVariations: any) {
    const variations: any = {};
    const variationsOrder: any = [];

    for (let i = 0; i < inputVariations.length; i++) {
        let currVar = inputVariations[i];
        let color = currVar.color.data ? currVar.color.data.id : NO_COLOR;

        if (!variations[color]) {
            variations[color] = [currVar];
            variationsOrder.push(color);
        } else {
            variations[color].push(currVar);
        }
    }
    return {variations, variationsOrder};
}

export function compareNavigationItemOrder(a: any, b: any) {
    if (a.order == null && b.order == null) {
        return 0;
    }
    if (a.order != null && b.order == null) {
        return -1;
    }
    if (a.order == null && b.order != null) {
        return 1;
    }
    const aOrder = parseInt(a.order);
    const bOrder = parseInt(b.order);

    return aOrder > bOrder ? 1 : -1;
}

export function calculatePriceWithoutTax(price: number) {
    return (price * 100) / (100 + TAX);
}

export function parseIntValue(value: any) {
    return _.isNumber(value) ? value : parseInt(value);
}

export function isSameNumber(first: any, second: any) {

    let firstFormatted = _.isNumber(first) ? first : parseInt(first);
    let secondFormatted = _.isNumber(second) ? second : parseInt(second);

    return firstFormatted === secondFormatted;
}

export function getTotalPrice(cart: any, products: any, wholesalePrice: boolean): number {
    if (PRICE_VISIBLE_FOR_WHOLESALE_ONLY && !wholesalePrice) {
        return 0;
    }

    let priceOfProducts = 0;
    cart.forEach((cartItem: any) => {
        const productData = products.find((product: any) => isSameNumber(product.id, cartItem.productID));
        if (productData) {
            const variation = productData.attributes.variations.find((variation: any) => isSameNumber(variation.id, cartItem.variationID));
            priceOfProducts += cartItem.quantity * (wholesalePrice ? variation.price.amountWholesale : variation.price.amount);
        }
    });
    return priceOfProducts;
}

export function validateEmail(email: string) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function returnSanitizedName(name: string) {
    name = name
        .replace(/^\s+/, "")
        .replace(/\s+$/, "")
        .replace(/\s+/, " ")
        .replace("<", "")
        .replace(">", "")
        .replace("/", "")
        .replace("\\", "");
    if (name === "") {
        return null;
    }
    return name;
}
