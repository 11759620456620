import React, {FunctionComponent} from "react";
import {GQL_EDUCATIONS} from "../api/GraphQL";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {Section} from "../components/section/Section";

interface Props {
    className?: string;
}

export const EducationSection: FunctionComponent<Props> = () => {
    return (
        <NavFooterWrapperSection>
            <Section url={GQL_EDUCATIONS} elementId={"educations"} prefixLink={"/vzdelavanie/"}/>
        </NavFooterWrapperSection>
    );
}
