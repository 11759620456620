import React, {FunctionComponent, ReactNode} from "react";

interface Props {
    className?: string;
    children?: ReactNode;
}

export const AdviceSection: FunctionComponent<Props> = () => {
    return (
        <>
        </>
    );
}
