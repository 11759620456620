import React, {FunctionComponent} from "react";
import {HeadingError} from "../error-pages/PageNotFound";

interface Props {
    title: string;
    description: any;
}

export const PageNotificationAlt: FunctionComponent<Props> = ({title, description}) => {
    return (
        <div className={"flex items-center justify-center w-full my-52"}>
            <div className="container flex flex-col items-center mx-auto">
                <div className={"text-center"}>
                    <HeadingError>{title}</HeadingError>
                    <p className="mt-4 max-w-2xl text-md text-gray-500 lg:mx-auto">
                        {description}</p>
                </div>
            </div>
        </div>
    )
}
