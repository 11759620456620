import React, {FunctionComponent, useState} from "react";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";

interface Props {

}

export const FAQItem: FunctionComponent<Props> = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="border-2 border-gray-100 rounded-lg w-[60rem]">
            <button className="flex items-center justify-between w-full p-8" onClick={() => setIsExpanded(!isExpanded)}>
                <h1 className="font-semibold text-gray-700">Doprava</h1>
                <span className="text-gray-400 bg-gray-200 rounded-full">
                    {
                        isExpanded ? <MinusIcon className={"w-6 h-6"}/> : <PlusIcon className={"w-6 h-6"}/>
                    }
                </span>
            </button>
            <hr className="border-gray-200"/>
            {
                isExpanded ?
                    <p className="p-8 text-sm text-gray-500">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas eaque nobis,
                        fugit odit omnis fugiat deleniti animi ab maxime cum laboriosam recusandae
                        facere dolorum veniam quia pariatur obcaecati illo ducimus?
                    </p> : null
            }
        </div>
    )
}