export enum OrderStateType {
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    PENDING = "PENDING",
    PAYED = "PAYED",
    PICKUP = "PICKUP",
    IN_TRANSFER = "IN_TRANSFER",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
    RETURNED = "RETURNED"
}
