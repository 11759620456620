import React, {Fragment, FunctionComponent} from "react";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {roundNumber} from "../../../../utils/EshopUtils";
import {CART_OPEN, CLEAR_CART_ITEM_STATE} from "../../../../redux/actions/action_types";
import {Dialog, Transition} from "@headlessui/react";
import {Currency} from "../../../../types/Common";

interface AddedItemStateProps {
    productDetails: any;
    selectedVariation: any;
    isOpen: any;
    setIsOpen: any;
    dispatch: any;
    quantity: any;
    currency: Currency;
    wholesalePrice: any;
}

export const AddedItemState: FunctionComponent<AddedItemStateProps> = ({
                                                                           productDetails,
                                                                           selectedVariation,
                                                                           isOpen,
                                                                           setIsOpen,
                                                                           dispatch,
                                                                           quantity,
                                                                           currency,
                                                                           wholesalePrice
                                                                       }) => {

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => {
                setIsOpen(false);
                dispatch({
                    type: CLEAR_CART_ITEM_STATE,
                })
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity md:block"/>
                </Transition.Child>
                <Dialog.Panel
                    className="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-gray-500 bg-opacity-75 h-full">
                    <div className={"w-full h-full flex flex-row justify-center items-center"}>
                        <div className="w-full h-auto max-w-md md:h-auto">
                            <div
                                className="justify-center md:justify-start w-full relative flex items-center bg-white flex-col max-w-md gap-5 p-6 rounded-md shadow-md bg-white">
                                <h2 className="flex items-center gap-2 text-xl font-semibold leading-tight tracking-wide">
                                    <CheckCircleIcon className={"w-6 h-6 text-gray-700"}/>
                                    Pridané do košíka
                                </h2>
                                <div className={"mt-4 flex flex-col text-left"}>
                                    <p className="flex-1 text-gray-800"><span
                                        className={"font-bold"}>{productDetails.name}</span></p>
                                    <p className="flex-1 text-gray-800 my-2">{productDetails.shortDescription}</p>
                                    <p className="flex-1 text-gray-800">{selectedVariation.volume ? selectedVariation.volume.amount : ''} {selectedVariation.volume ? selectedVariation.volume.unit.data.attributes.code : ''} | {selectedVariation.color.data ? (selectedVariation.color.data.attributes.name.toLowerCase() + " | ") : ''} {quantity} ks
                                        | {roundNumber(wholesalePrice ? selectedVariation.price.amountWholesale : selectedVariation.price.amount)} {currency.name} za
                                        kus</p>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-6 sm:flex-row">
                                    <button
                                        onClick={() => {
                                            setIsOpen(false);
                                            dispatch({
                                                type: CLEAR_CART_ITEM_STATE,
                                            })
                                        }}
                                        className="bg-green-600 hover:bg-green-700 text-white px-2.5 py-2.5 rounded-md">Pokračovať
                                        v nákupe
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsOpen(false);

                                            dispatch({
                                                type: CLEAR_CART_ITEM_STATE,
                                            })

                                            dispatch({
                                                type: CART_OPEN,
                                                cartIsOpenStateTo: true,
                                            })
                                        }}
                                        className="bg-btn-main hover:bg-btn-main-hover text-white px-2.5 py-2.5 rounded-md shadow-md">Otvoriť
                                        nákupný košík
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </Transition.Root>
    )
}