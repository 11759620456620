import styled from "@emotion/styled";

export function join(array: any[], delimiter: string = ' ') {
    return array.filter(Boolean).join(delimiter);
}

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const CkEditorDiv = styled.div`
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: revert;
    font-size: revert;
  }

  li {
    margin: 0.8rem;
  }

`