import React, {FunctionComponent} from "react";
import tw from "twin.macro";

interface Props {
    className?: string;
    children?: React.ReactNode;
}

let Heading2H = tw.h2`
 text-2xl
 underline
 text-heading-two
 font-semibold
 tracking-wide
 uppercase
 font-serif
 text-heading-two
`

export const H2: FunctionComponent<Props> = ({className, children}) => {
    return (
        <Heading2H className={className}>
            {children}
        </Heading2H>
    );
}
