import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {Main} from "../components/main/Main";
import {useQuery} from "@apollo/client";
import {GQL_MAIN} from "../api/GraphQL";
import {Testimonial} from "../components/testimonials/Testimonial";
import Contact from "../components/contact/Contact";
import Team from "../components/team/Team";
import {SectionPageLoader} from "../components/loading/SectionPageLoader";

interface Props {
    className?: string;
}

export const MainSection: FunctionComponent<Props> = () => {

    const {data, loading, error} = useQuery(GQL_MAIN);

    return (
        <NavFooterWrapperSection>
            {loading ?
                <SectionPageLoader/> :
                error ? null :
                <>
                    <Main header={data.main.data.attributes.header} image={data.main.data.attributes.image}/>
                    <Team team={data.team.data.attributes}/>
                    <Testimonial citation={data.main.data.attributes.citation}/>
                    <Contact contact={data.contact.data.attributes}/>
                </>
            }
        </NavFooterWrapperSection>
    );
}
