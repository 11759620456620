import {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";

interface Props {
    footer: any;
}

interface CategoryWithLinksProps {
    categoryItem: any;
}

const CategoryWithLinks: FunctionComponent<CategoryWithLinksProps> = ({categoryItem}) => {
    return (
        <div className="space-y-4">
            <h3 className={"font-sans tracking-wide uppercase text-gray-900 font-extrabold"}>{categoryItem.header}
            </h3>
            <ul className={"space-y-2.5 list-none"}>
                {categoryItem.links.map((link: any) => (
                    <CategoryLink key={nanoid()} item={link}/>
                ))}
            </ul>
        </div>
    )
}

const CategoryWithIconLinks: FunctionComponent<CategoryWithLinksProps> = ({categoryItem}) => {
    return (
        <div className="space-y-4">
            <h3 className={"tracking-wide uppercase text-gray-900 font-extrabold"}>{categoryItem.header}
            </h3>
            <ul className={"space-y-1 flex justify-start space-x-3 list-none"}>
                {categoryItem.links.map((link: any) => (
                    <CategoryIconLink key={nanoid()} item={link}/>
                ))}
            </ul>
        </div>
    )
}

interface CategoryLinkProps {
    item: any;
}

const CategoryLink: FunctionComponent<CategoryLinkProps> = ({item}) => {
    return (
        <li>
            <Link className={"font-normal"} rel="noopener noreferrer" to={item.link}>{item.name}</Link>
        </li>
    )
}

const CategoryIconLink: FunctionComponent<CategoryLinkProps> = ({item}) => {
    return (
        <li className={"flex items-center"}>
            <a rel="noopener noreferrer" target="_blank" className={"flex items-center p-1"} href={item.link}>
                <img className={"w-6 h-6 md:w-7 md:h-7"} title={item.name} src={item.icon.data.attributes.url}
                     alt={item.icon.data.attributes.alternativeText}/>
            </a>
        </li>
    )
}


export const Footer: FunctionComponent<Props> = ({footer}) => {

    return (
        <footer
            className="divide-y text-gray-900 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10 font-sans">
            <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
                <div className="lg:w-1/3 flex justify-center items-center">
                    <a rel="noopener noreferrer" href="/" className="flex justify-center space-x-3 lg:justify-start">
                        <img alt={footer && footer.logo.data.attributes.alternativeText}
                             className={"w-16 h-16 sm:w-[5rem] sm:h-[5rem] md:w-20 md:h-20 lg:w-24 lg:h-24 mb-5 md:mb-0"}
                             src={footer && footer.logo.data.attributes.url}/>
                    </a>
                </div>
                <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
                    {footer && footer.links.map((item: any) => (
                        <CategoryWithLinks key={nanoid()} categoryItem={item}/>
                    ))}
                    {
                        footer && <CategoryWithIconLinks categoryItem={footer.socialLinks}/>
                    }
                </div>
            </div>
            <div
                className="py-6 text-sm text-center border-gray-700 text-gray-700">{footer && footer.credits}
            </div>
        </footer>
    )
}
