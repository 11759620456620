import React, {FunctionComponent} from "react";
import {MAGAZINE_ROUTE} from "../../app";
import {Link} from "react-router-dom";
import {nanoid} from "nanoid";
import Color from "color";
import styled from "@emotion/styled";
import {
    DATE_OPTIONS,
    DATE_OPTIONS_TIME,
    toLocaleDateStringWithOption,
    toLocaleTimeStringWithOption
} from "../../utils/DateTimeUtils";

interface Props {
    article: any;
}

export const Fadeout = styled.div`
  bottom: 0;
  height: 4em;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%,
  rgba(255, 255, 255, 1) 100%);
  position: absolute;
  width: 100%;
`

const Article: FunctionComponent<Props> = ({article}) => {

    return (
        <Link state={{preventScroll: true}} to={"/" + MAGAZINE_ROUTE + article.slug}
              className="w-full h-full hover:scale-110 transition-transform">
            <article
                className="p-6 bg-white rounded-lg border border-gray-200 shadow-md">
                <div className="flex flex-row justify-between items-center mb-5 text-gray-500">
                    {
                        article.types ?
                            <div className={"grid gap-1 grid-cols-1 self-start"}>
                                {
                                    article.types.data.map((type: any) => {
                                        const color = Color(type.attributes.color);
                                        return (
                                            <span key={nanoid()} style={{background: type.attributes.color}}
                                                  className={"text-xs inline-flex items-center px-2.5 py-0.5 rounded " + (color?.luminosity() < 0.5 ? "text-white" : "text-black")}>
                                        <img className={"mr-1 w-3 h-3"} src={type.attributes.img.data.attributes.url}
                                             alt={type.attributes.img.data.attributes.alternativeText}/>
                                                {type.attributes.name}
                                    </span>
                                        )
                                    })
                                }
                            </div>
                            : null
                    }
                    <div className="flex flex-col text-sm self-start text-right">
                        <span>{toLocaleDateStringWithOption(article.publishedAt, DATE_OPTIONS)}</span>
                        <span>{toLocaleTimeStringWithOption(article.publishedAt, DATE_OPTIONS_TIME)}</span>
                    </div>

                </div>
                <h2 className="mb-2 text-xl font-bold tracking-tight text-gray-900">{article.heading}</h2>
                <div className={"relative"}>
                    <p className="font-sans font-normal mb-5 text-gray-500 max-h-32 overflow-clip">{article.description}</p>
                    <Fadeout/>
                </div>
            </article>
        </Link>
    )
}

export default Article;