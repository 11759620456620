import {adjustValue, checkForErrors, clearState, trimValues} from "./checkout";
import {
    ORDER_IDENTIFIER,
    ORDER_IDENTIFIER_CHECK_ERROR,
    ORDER_IDENTIFIER_CLEAR,
    ORDER_IDENTIFIER_ERROR,
    VALIDATE_ORDER
} from "../actions/action_types";
import {InputType} from "../../types/input-type";

export function orderReducer(state: any, action: any) {
    switch (action.type) {
        case ORDER_IDENTIFIER_CHECK_ERROR:
            let newState = {...state, identifier: {...adjustValue(state.identifier, action.value), trigger: true}};
            trimValues(newState);
            let fieldsToCheck = ["identifier"];
            checkForErrors(newState, fieldsToCheck);
            return {...newState};
        case ORDER_IDENTIFIER:
            return {...state, identifier: {...adjustValue(state.identifier, action.value), trigger: false}};
        case ORDER_IDENTIFIER_ERROR:
            return {...state, identifier: {...state.identifier, error: action.value, trigger: false}};
        case ORDER_IDENTIFIER_CLEAR:
            return {
                ...state,
                identifier: {...clearState({type: InputType.VALUE}), trigger: false},
            };
        case VALIDATE_ORDER:
            trimValues(state);
            let toCheck = ["identifier"];
            checkForErrors(state, toCheck);
            return {...state};
        default:
            return state;
    }
}
