import React, {Fragment, FunctionComponent, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {Product} from "./product/Product";
import {nanoid} from "nanoid";
import {useSelector} from "react-redux";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {GQL_CATEGORIES, GQL_PRODUCTS} from "../../api/GraphQL";
import {useQuery} from "@apollo/client";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {
    compareNavigationItemOrder,
    EMPTY_STRING,
    NO_CATEGORY,
    NUMBER_OF_ITEMS_PER_PAGE,
    parseIntValue
} from "../../utils/EshopUtils";
import {Link} from "react-router-dom";
import {SHOP_ROUTE} from "../../app";
import {EshopSideBarButton} from "./sidebar/EshopSideBarButton";
import {classNames} from "../../utils";
import {TextLoader} from "../loading/TextLoader";
import {ProductLoader} from "../loading/ProductLoader";
import {SearchProduct} from "../search/SearchProduct";
import {scrollTop} from "../../hooks/scrollToUtils";
import {PageNotificationAlt} from "../notification/PageNotificationAlt";

interface Props {
    categoryFromRoute?: any;
}

export const PRODUCT_GRID_ID = "product-grid";

export function hasVariationWithStockQuantity(product: any) {
    if (!product || !product.attributes.variations) {
        return false;
    }
    return !!product.attributes.variations.find((variation: any) => variation.stockQuantity && variation.stockQuantity > 0);
}

const Eshop: FunctionComponent<Props> = ({categoryFromRoute}) => {

    const currency = useSelector((state: any) => state.shop.currency);

    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(NO_CATEGORY);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [categoriesToUse, setCategoriesToUse] = useState([]);
    const [numberOfProducts, setNumberOfProducts] = useState(0);
    const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);

    const [loadingNewCategory, setLoadingNewCategory] = useState(false);

    const [allCategoryNavigations, setAllCategoryNavigations] = useState<any>(undefined);
    const [products, setProducts] = useState<any>(undefined);
    const [config, setConfig] = useState(undefined);

    const {loading: loadingCategories, data: dataCategories, error: errorCategories} = useQuery(GQL_CATEGORIES);
    const {loading: loadingProducts, data: dataProducts, error: errorProducts} = useQuery(GQL_PRODUCTS, {
        variables: {
            page: currentPage,
            pageSize: NUMBER_OF_ITEMS_PER_PAGE,
            arrayId: categoriesToUse
        }, refetchWritePolicy: "overwrite",
        skip: categoriesToUse?.length === 0 || currentPage === 0
    });

    useEffect(() => {
        if (loadingProducts || errorProducts || loadingCategories || errorCategories) {
            return;
        }

        const categoryNavigations = dataCategories.productNavigations.data;
        setAllCategoryNavigations(categoryNavigations);
        setConfig(dataCategories.config.data);

        const categoryFromRouteObject = categoryFromRoute?.id && categoryNavigations.find((categoryNavigation: any) => getCategoryId(categoryNavigation) === categoryFromRoute.id);
        const categoryIdToUse = categoryFromRouteObject ? [parseIntValue(categoryFromRoute.id)] : allCategoryNavigations?.map((categoryNavigation: any) => getCategoryId(categoryNavigation));

        setCategoriesToUse(categoryIdToUse);
        setSelectedCategory(categoryFromRouteObject ?? NO_CATEGORY);
    }, [loadingCategories]);

    useEffect(() => {
        if (errorProducts || loadingProducts || !dataProducts?.products) {
            return;
        }
        if (products && !loadingNewCategory) {
            const newData = products.data.concat(dataProducts.products.data);
            setProducts({...products, data: newData});
        } else {
            setProducts(dataProducts.products);
            setTotalNumberOfProducts(dataProducts.products.meta.pagination.total);
        }
        setLoadingNewCategory(false);
    }, [dataProducts]);

    useEffect(() => {
        setNumberOfProducts(products?.data?.length);
    }, [products])

    if (errorProducts) {
        return <PageNotificationAlt title={"Nemožno zobraziť produkty"}
                                    description={"Produkty nie je možné zobraziť, skúste to znovu neskôr prosím."}/>
    }


    function getCategoryId(categoryNavigation: any) {
        return categoryNavigation.attributes.category.data.id;
    }

    function getSlug(categoryNavigation: any) {
        if (categoryNavigation === NO_CATEGORY) {
            return EMPTY_STRING;
        }
        return categoryNavigation.attributes.category.data.attributes.slug;
    }

    function getCategoriesToUse(categoryNavigation: any) {
        if (categoryNavigation === NO_CATEGORY) {
            return allCategoryNavigations.map((categoryNavigation: any) => getCategoryId(categoryNavigation));
        }
        return [getCategoryId(categoryNavigation)];
    }

    function getProductsForGivenCategory(e: any, categoryNavigation: any) {
        if (e) {
            e.preventDefault();
        }

        if (selectedCategory === categoryNavigation) {
            return;
        }
        window.history.replaceState("", "", ("/" + SHOP_ROUTE + getSlug(categoryNavigation)));

        scrollTop();
        setProducts(null);
        setCurrentPage(1);
        setSelectedCategory(categoryNavigation);
        setCategoriesToUse(getCategoriesToUse(categoryNavigation));
        setMobileFiltersOpen(false);
    }

    console.log(selectedCategory);

    const parentCategoryStructure = allCategoryNavigations?.filter((categoryNavigation: any) => categoryNavigation.attributes.parentCategory.data?.id == null).sort((a: any, b: any) => compareNavigationItemOrder(a.attributes, b.attributes));

    return (
        <div className="bg-white">
            <div>
                {/* Mobile filter dialog */}
                <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25"/>
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div
                                className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                                <div className="px-4 flex items-center justify-between">
                                    <h2 className="text-lg font-bold text-gray-900">Kategórie</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                                        onClick={() => setMobileFiltersOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="w-7 h-7 text-gray-700" aria-hidden="true"/>
                                    </button>
                                </div>
                                {/* Filters, mobile categories*/}
                                {
                                    allCategoryNavigations != null ?
                                        <form className="mt-4 border-t border-gray-200">
                                            <h3 className="sr-only">Categories</h3>
                                            <div className="ml-2 py-4 overflow-y-auto">
                                                <ul className="list-none">
                                                    <Link
                                                        className={classNames("flex items-center w-full p-2 text-base rounded-lg text-gray-900 transition duration-75 group hover:bg-gray-100", selectedCategory === NO_CATEGORY ? "font-bold" : "font-normal")}
                                                        to={"/" + SHOP_ROUTE}
                                                        onClick={(e) => {
                                                            getProductsForGivenCategory(e, NO_CATEGORY);
                                                            window.history.replaceState("", "", ("/" + SHOP_ROUTE));
                                                        }}>Všetky produkty</Link>
                                                    {parentCategoryStructure?.map((categoryStructure: any) =>
                                                        <EshopSideBarButton
                                                            allCategoryStructures={allCategoryNavigations}
                                                            currentlyIteratedCategoryStructure={categoryStructure}
                                                            currentlySelectedCategory={selectedCategory}
                                                            getProductsForGivenCategory={getProductsForGivenCategory}
                                                            key={nanoid()}
                                                            level={0}
                                                        />
                                                    )}
                                                </ul>
                                            </div>
                                            {/*INSERT FILTERS HERE*/}
                                        </form> :
                                        <>
                                            {Array(10).fill(0).map(() => (
                                                <TextLoader key={nanoid()}/>
                                            ))
                                            }
                                        </>
                                }
                            </div>
                        </Transition.Child>
                    </Dialog>
                </Transition.Root>

                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div
                        className="relative z-10 flex flex-col justify-between mt-12 pb-6 border-b border-gray-200 gap-6">
                        <div className={"flex flex-row justify-between flex-wrap gap-6"}>
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 justify-start">{selectedCategory?.attributes?.category?.data?.attributes?.name ?? "Pozrite si našu ponuku"}</h1>
                            <SearchProduct/>
                        </div>
                        <div>
                            {/*INSERT GRID MENU here*/}
                            <button
                                type="button"
                                className="lg:hidden flex flex-row justify-center mb-4 bg-btn-main border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-btn-main"
                                onClick={() => setMobileFiltersOpen(true)}
                            >
                                <span className="sr-only">Categories</span>
                                <span>Kategórie</span>
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pt-6 pb-8">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10 h-full">
                            {/* Filters */}
                            <aside className="w-64 max-h-screen sticky top-0 hidden lg:block" aria-label="Sidebar">
                                <div className="py-4 overflow-y-auto max-h-[36rem]">
                                    <ul className="space-y-2 list-none">
                                        {
                                            allCategoryNavigations != null ?
                                                <>
                                                    <Link
                                                        className={classNames("flex items-center w-full p-2 text-base rounded-lg text-gray-900 transition duration-75 group hover:bg-gray-100", selectedCategory === NO_CATEGORY ? "font-bold" : "font-normal")}
                                                        to={"/" + SHOP_ROUTE}
                                                        onClick={(e) => {
                                                            getProductsForGivenCategory(e, NO_CATEGORY);
                                                            window.history.replaceState("", "", ("/" + SHOP_ROUTE));
                                                        }}>Všetky produkty</Link>
                                                    {parentCategoryStructure?.map((categoryStructure: any) =>
                                                        <EshopSideBarButton
                                                            allCategoryStructures={allCategoryNavigations}
                                                            currentlySelectedCategory={selectedCategory}
                                                            currentlyIteratedCategoryStructure={categoryStructure}
                                                            getProductsForGivenCategory={getProductsForGivenCategory}
                                                            level={0}
                                                            key={nanoid()}
                                                        />
                                                    )}
                                                </> :
                                                <>
                                                    {Array(10).fill(0).map(() => (
                                                        <TextLoader key={nanoid()}/>
                                                    ))
                                                    }
                                                </>
                                        }
                                    </ul>
                                </div>
                            </aside>

                            {/* Product grid */}
                            <div id={PRODUCT_GRID_ID} className="lg:col-span-3">
                                {
                                    selectedCategory?.attributes?.category?.data?.attributes?.description ?
                                        <p className={"mt-2 mb-6 text-base md:text-lg"}>{selectedCategory.attributes.category.data.attributes.description}</p> : null
                                }
                                <div
                                    className="grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
                                    {
                                        products?.data ?
                                            products?.data.map((product: any) => (
                                                hasVariationWithStockQuantity(product) ?
                                                    <Product config={config} currency={currency} key={nanoid()}
                                                             getProductsForGivenCategory={getProductsForGivenCategory}
                                                             product={product.attributes}
                                                             productId={product.id}/> : null
                                            ))
                                            : Array(8).fill(0).map(() => (
                                                <ProductLoader key={nanoid()}/>
                                            ))
                                    }
                                </div>
                                {
                                    products?.data ?
                                        <div className={"w-full mt-8 border-t-2"}>
                                            <p className={"font-sans mt-8 text-sm text-center"}>Zobrazuje
                                                sa {numberOfProducts} z {totalNumberOfProducts}</p>
                                            <div
                                                className="mt-4 w-1/4 mx-auto bg-gray-200 rounded-full h-1 mb-4 dark:bg-gray-700">
                                                <div className="bg-spinner-loader h-1 rounded-full"
                                                     style={{width: (numberOfProducts / totalNumberOfProducts) * 100 + "%"}}></div>
                                            </div>
                                            {numberOfProducts < totalNumberOfProducts ?
                                                <button type={"submit"}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (loadingProducts) {
                                                                return;
                                                            }
                                                            setCurrentPage(currentPage + 1);
                                                        }}
                                                        className={"flex flex-row mx-auto mt-5 rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                                    Zobraziť viac {loadingProducts ?
                                                    <SpinnerLoader
                                                        classname={"ml-2 w-5 h-5"}/> : null}
                                                </button> : null
                                            }
                                        </div> : null
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default Eshop;
