import React, {FunctionComponent, useState} from "react";
import {CheckoutInputField} from "../eshop/Checkout";
import {RESET_EMAIL, VALIDATE_RESET} from "../../redux/actions/action_types";
import {useDispatch, useSelector} from "react-redux";
import {canSubmit} from "../../redux/reducers/checkout";
import axios from "axios";
import {COMPANY_NAME_ALT, BACKEND_URL} from "../../config";
import {PageNotification} from "../notification/PageNotification";
import {Link} from "react-router-dom";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {LOGIN_ROUTE_TO} from "../../app";

interface Props {

}

export const PasswordReset: FunctionComponent<Props> = () => {

    const reset = useSelector((state: any) => state.reset);
    const dispatch = useDispatch();

    const [submittingReset, setSubmittingReset] = useState<boolean>(false);
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    if (wasSubmitted) {
        return <PageNotification title={"Odkaz na obnovu hesla bol odoslaný"} linkDescription={"Úvodnú stránku"}
                                 link={"/"}
                                 description={"Postup na obnovenia hesla bol poslaný na váš email."}/>
    }

    return (
        <div className={"min-h-full bg-gray-50"}>
            <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <a href={"/"}>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={"/logo.png"}
                            alt={COMPANY_NAME_ALT}
                        />
                    </a>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Obnovenie hesla</h2>

                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                                {<CheckoutInputField field={reset.email} autoComplete={"email"}
                                                     titleName={"Email"} type={"email"} required={true}
                                                     dispatch={{
                                                         dispatchMethod: dispatch,
                                                         typeName: RESET_EMAIL
                                                     }}/>}
                            </div>

                            <div>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    if (submittingReset) {
                                        return;
                                    }
                                    dispatch({type: VALIDATE_RESET});

                                    if (canSubmit(reset)) {
                                        setSubmittingReset(true);
                                        axios.post(BACKEND_URL + 'api/auth/forgot-password', {email: reset.email.value})
                                            .then(() => setWasSubmitted(true)).catch(() => setIsError(true)).finally(() => setSubmittingReset(false))
                                    }
                                }}
                                        className={"mx-auto mt-6 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                    Obnoviť heslo
                                    {submittingReset ? <SpinnerLoader
                                        classname={"ml-2 w-5 h-5"}/> : null}
                                </button>
                                {isError ?
                                    <p className="mt-2 text-center text-sm text-gray-600">Pri obnove hesla nastala
                                        chyba.</p> : null}
                            </div>
                            <div
                                className="mt-8 flex justify-center text-center text-sm text-gray-500">
                                <p>
                                    Naspäť na{' '}
                                    <Link
                                        to={LOGIN_ROUTE_TO}
                                        type="button"
                                        className="font-medium text-btn-main hover:text-btn-main-hover">
                                        Prihlásenie<span aria-hidden="true"> &rarr;</span>
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
