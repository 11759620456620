import React, {FunctionComponent, ReactNode} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import {useQuery} from "@apollo/client";
import {GQL_ARTICLES} from "../api/GraphQL";
import Magazine from "../components/article/Magazine";
import {SectionPageLoader} from "../components/loading/SectionPageLoader";

interface Props {
    className?: string;
    children?: ReactNode;
}

export const MagazineSection: FunctionComponent<Props> = () => {

    const {data, loading, error} = useQuery(GQL_ARTICLES);

    return (
        <NavFooterWrapperSection>
            {
                loading ? <SectionPageLoader/> :
                    error ? <></> :
                        <Magazine magazine={data.magazine.data.attributes}
                                  articles={data.articles.data}/>
            }
        </NavFooterWrapperSection>
    );
}
