import {adjustValue, checkForErrors, trimValues} from "./checkout";
import {CHANGE_PASSWORD, CHANGE_PASSWORD_CONFIRMATION, VALIDATE_CHANGE} from "../actions/action_types";

export function changeReducer(state: any, action: any) {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return {...state, password: adjustValue(state.password, action.value)};
        case CHANGE_PASSWORD_CONFIRMATION:
            return {...state, passwordConfirmation: adjustValue(state.passwordConfirmation, action.value)};
        case VALIDATE_CHANGE:
            trimValues(state);
            let toCheck = ["password", "passwordConfirmation"];
            checkForErrors(state, toCheck);

            if (state.password.value !== state.passwordConfirmation.value) {
                state.passwordConfirmation.error = "Heslá sa nezhodujú."
            }

            return {...state};
        default:
            return state;
    }
}