import {gql} from "@apollo/client";

export const GQL_HEADER_FOOTER = gql`
query HeaderFooter {
  navigation {
    data {
      id
      attributes {
        highlightedLink {
          name
          link
        }
        links {
          id
          name
          link
          icon {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
        logo {
          data {
            id
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
  }
  footer {
    data {
      id
      attributes {
        logo {
          data {
            id
            attributes {
              url
              alternativeText
            }
          }
        }
        credits
        socialLinks {
          id
          header
          links {
            name
            link
            icon {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
        links {
          id
          links {
            id
            name
            link
          }
          header
        }
      }
    }
  }
}
`

export const GQL_CONTACT = gql`
query Contact {
  contact {
    data {
      id
      attributes {
        header {
          heading
          subHeading
          description
        }
        contact {
          id
          items {
            id
            contact
            contactType
          }
          heading
        }
        location {
          id
          map
        }
      }
    }
  }
}
`

export const GQL_MAIN = gql`
query Main {
  main {
    data {
      id
      attributes {
        image {
          data {
            id
            attributes {
              url
              alternativeText
            }
          }
        }
        header {
          id
          heading
          subHeading
          description
        }
        citation {
          img {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          text
          author
          authorRole
        }
      }
    }
  }
  team {
    data {
      id
      attributes {
        mainPageHeader {
          heading
          subHeading
          description
        }
        members {
          id
          heading
          identifier
          role
          mainPageText
          img {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
  contact {
    data {
      id
      attributes {
        header {
          heading
          subHeading
          description
        }
        contact {
          id
          items {
            contact
            contactType
          }
          heading
        }
        location {
          id
          map
        }
      }
    }
  }
}
`

export const GQL_MEMBERS = gql`
query Team {
  team {
    data {
      id
      attributes {
        members {
          id
          text
          heading
          personalText
          identifier
          img {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          role
          mainPageText
          selector {
            id
            heading
            img {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
        pageHeader {
          id
          heading
          subHeading
          description
        }
      }
    }
  }
}
`

export const GQL_SERVICES = gql`
query Services {
  service {
    data {
      id
      attributes {
        items {
          id
          heading
          text
          introduction {
            id
            heading
            img {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
            text
          }
          products {
            data {
              id
              attributes {
                name
                description
                shortDescription
                slug
                images {
                  data {
                    id
                    attributes {
                      formats
                      alternativeText
                      url
                    }
                  }
                }
                variations {
                  id
                  stockQuantity
                  volume {
                    id
                    amount
                    additionalParameter
                    unit {
                      data {
                        id
                        attributes {
                          code
                        }
                      }
                    }
                  }
                  price {
                    id
                    amount
                    amountWholesale
                    discounts {
                      data {
                        id
                        attributes {
                          percentage
                        }
                      }
                    }
                  }
                  color {
                    data {
                      id
                      attributes {
                        name
                        hexCode
                        img {
                          data {
                            id
                            attributes {
                              url
                              alternativeText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          selector {
            id
            heading
            img {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          identifier
        }
        header {
          id
          heading
          subHeading
          description
        }
      }
    }
  }
}
`

export const GQL_EDUCATIONS = gql`
query Educations {
  education {
    data {
      id
      attributes {
        items {
          id
          heading
          text
          introduction {
            id
            heading
            img {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
            text
          }
          products {
            data {
              id
              attributes {
                name
                description
                shortDescription
                slug
                images {
                  data {
                    id
                    attributes {
                      formats
                      alternativeText
                      url
                    }
                  }
                }
                variations {
                  id
                  stockQuantity
                  volume {
                    id
                    amount
                    additionalParameter
                    unit {
                      data {
                        id
                        attributes {
                          code
                        }
                      }
                    }
                  }
                  price {
                    id
                    amount
                    amountWholesale
                    discounts {
                      data {
                        id
                        attributes {
                          percentage
                        }
                      }
                    }
                  }
                  color {
                    data {
                      id
                      attributes {
                        name
                        hexCode
                        img {
                          data {
                            id
                            attributes {
                              url
                              alternativeText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          selector {
            id
            heading
            img {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          identifier
        }
        header {
          id
          heading
          subHeading
          description
        }
      }
    }
  }
}
`

export const GQL_ARTICLES = gql`
query articles {
  magazine {
    data {
      id
      attributes {
        header {
          id
          heading
          subHeading
          description
        }
      }
    }
  }
  articles(sort: "createdAt:desc") {
    data {
      id
      attributes {
        heading
        description
        slug
        publishedAt
        createdAt
        types {
          data {
            id
            attributes {
              color
              name
              img {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_ARTICLE_SLUG = gql`
query articleSlug($slug: String!) {
  findSlug(modelName: "article", slug: $slug) {
    ... on ArticleEntityResponse {
      data {
        id
        attributes {
          heading
          publishedAt
          createdAt
          description
          slug
          text
          products {
            data {
              id
              attributes {
                name
                description
                shortDescription
                slug
                images {
                  data {
                    id
                    attributes {
                      formats
                      alternativeText
                      url
                    }
                  }
                }
                variations {
                  id
                  stockQuantity
                  volume {
                    id
                    amount
                    additionalParameter
                    unit {
                      data {
                        id
                        attributes {
                          code
                        }
                      }
                    }
                  }
                  price {
                    id
                    amount
                    amountWholesale
                    discounts {
                      data {
                        id
                        attributes {
                          percentage
                        }
                      }
                    }
                  }
                  color {
                    data {
                      id
                      attributes {
                        name
                        hexCode
                        img {
                          data {
                            id
                            attributes {
                              url
                              alternativeText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          types {
            data {
              id
              attributes {
                name
                color
                img {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_PRIVACY = gql`
query Privacy {
  config {
    data {
      id
      attributes {
        privacy
      }
    }
  }
}
`

export const GQL_GENERAL_BUSINESS_RULES = gql`
query Privacy {
  config {
    data {
      id
      attributes {
        generalBusinessRules
      }
    }
  }
}
`

export const GQL_DELIVERIES_PAYMENTS = gql`
query DeliveriesPayments {
  deliveries {
    data {
      id
      attributes {
        name
        description
        price
        method
        discounts {
          data {
            id
            attributes {
              name
              validFrom
              validUntil
              applyFrom
              percentage
            }
          }
        }
        possiblePaymentMethods(sort: "id:asc") {
          data {
            id
            attributes {
              name
              description
              price
              method
              discounts {
                data {
                  id
                  attributes {
                    name
                    applyFrom
                    validFrom
                    validUntil
                    percentage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_CATEGORIES = gql`
query Categories {
  productNavigations {
    data {
      id
      attributes {
        order
        parentCategory {
          data {
            id
          }
        }
        category {
          data {
            id
            attributes {
              name
              description
              slug
              products {
                data {
                  id
                }
              }
              img {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  config {
    data {
      id
      attributes {
        linkToWhenPartOf {
          data {
            id
            attributes {
              name
              description
              slug
            }
          }
        }
      }
    }
  }
}
`

export const GQL_PRODUCTS = gql`
query ProductsAndFilters($page: Int!, $pageSize: Int!, $arrayId: [ID]) {
  products(
    pagination: { page: $page, pageSize: $pageSize }
    filters: { categories: { id: { in: $arrayId } } }
  ) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        slug
        categories {
          data {
            id
          }
        }
        images {
          data {
            id
            attributes {
              formats
              url
              alternativeText
            }
          }
        }
        contains {
          data {
            id
            attributes {
              slug
              name
            }
          }
        }
        partOf {
          data {
            id
            attributes {
              slug
              name
            }
          }
        }
        description
        shortDescription
        name
        variations(sort: "stockQuantity:desc") {
          id
          price {
            id
            amount
            amountWholesale
            discounts {
              data {
                id
                attributes {
                  percentage
                }
              }
            }
          }
          stockQuantity
          variationIdentifier
          color {
            data {
              id
              attributes {
                hexCode
                name
                img {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          volume {
            id
            amount
            additionalParameter
            unit {
              data {
                id
                attributes {
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_PRODUCT_BY_SLUG = gql`
query ProductBySlug($slug: String!) {
  findSlug(modelName: "product", slug: $slug) {
    ... on ProductEntityResponse {
      data {
        id
        attributes {
          images {
            data {
              id
              attributes {
                formats
                url
                alternativeText
              }
            }
          }
          fullDescription
          description
          shortDescription
          name
          slug
          contains {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
          partOf {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
          variations(sort: "stockQuantity:desc") {
            id
            price {
              id
              amount
              amountWholesale
              discounts {
                data {
                  id
                  attributes {
                    percentage
                  }
                }
              }
            }
            stockQuantity
            variationIdentifier
            color {
              data {
                id
                attributes {
                  hexCode
                  name
                  img {
                    data {
                      id
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
            volume {
              id
              amount
              additionalParameter
              unit {
                data {
                  id
                  attributes {
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_SPECIFIC_PRODUCTS = gql`
query getProducts($arrayId: [ID]) {
  products(filters: { id: { in: $arrayId } }) {
    data {
      id
      attributes {
        name
        slug
        images {
          data {
            id
            attributes {
              formats
              alternativeText
              url
            }
          }
        }
        variations {
          id
          stockQuantity
          volume {
            id
            amount
            additionalParameter
            unit {
              data {
                id
                attributes {
                  code
                }
              }
            }
          }
          price {
            id
            amount
            amountWholesale
            discounts {
              data {
                id
                attributes {
                  percentage
                }
              }
            }
          }
          color {
            data {
              id
              attributes {
                name
                hexCode
                img {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_CHECK_CATEGORY_SLUG = gql`
query CheckCategoryBySlug($slug: String!) {
  findSlug(modelName: "category", slug: $slug) {
    ... on CategoryEntityResponse {
      data {
        id
      }
    }
  }
}
`;

export const GQL_FIND_PRODUCT_BY_NAME = gql`
query findProductByName($text: String!) {
  products(
    pagination: { limit: 5 }
    filters: {
      or: [{ name: { containsi: $text } }, { slug: { containsi: $text } }]
    }
  ) {
    data {
      attributes {
        name
        slug
        images {
          data {
            attributes {
              url
              alternativeText
              formats
            }
          }
        }
      }
    }
  }
}
`
