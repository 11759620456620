import {Link} from "react-router-dom";
import React, {FunctionComponent} from "react";
import {COMPANY_NAME_ALT} from "../../config";

interface Props {
    title: string;
    description: string;
    linkDescription: string;
    link: string;
    additionalLink?: any;
}

export const PageNotification: FunctionComponent<Props> = ({
                                                               title,
                                                               description,
                                                               linkDescription,
                                                               link,
                                                               additionalLink
                                                           }) => {
    return (
        <div className={"min-h-full bg-gray-50"}>
            <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <a href={"/"}>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={"/logo.png"}
                            alt={COMPANY_NAME_ALT}
                        />
                    </a>
                    <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">{title}</h2>
                    <p className="mt-2 text-center text-sm text-gray-600"></p>
                </div>

                <div className="mt-8 mx-auto w-[18rem] sm:w-full sm:max-w-3xl">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <p className={"text-center"}>{description}</p>
                        <div
                            className="mt-4 flex flex-col flex-wrap justify-center text-center text-sm text-gray-500 space-y-2">
                            {
                                additionalLink ?
                                    <p>
                                        {additionalLink.prefix}
                                        <Link
                                            to={additionalLink.link}
                                            type="button"
                                            className="font-medium text-btn-main hover:text-btn-main-hover">
                                            {additionalLink.description}<span aria-hidden="true"> &rarr;</span>
                                        </Link>
                                    </p> : null
                            }
                            <p>
                                naspäť na{' '}
                                <Link
                                    to={link}
                                    type="button"
                                    className="font-medium text-btn-main hover:text-btn-main-hover">
                                    {linkDescription}<span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
