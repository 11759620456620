import {SectionHeader} from "../common/SectionHeader";
import {Container} from "../common/Container";
import {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {TEAM_ROUTE} from "../../app";
import {CkEditorDiv} from "../../utils";

interface Team {
    team: any;
}

const Team: FunctionComponent<Team> = ({team}) => {
    return (
        <Container>
            <div id={"#tim"} className="bg-white">
                <div className="mx-auto mt-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-12 lg:mb-16">
                    <div className="space-y-12">
                        <SectionHeader heading={team.mainPageHeader.heading} subHeading={team.mainPageHeader.subHeading}
                                       description={team.mainPageHeader.description}/>
                        <ul
                            role="list"
                            className="space-y-12 sm:grid grid-cols-1 lg:grid-cols-2 items-start gap-x-8 lg:gap-y-12 lg:space-y-0 list-none"
                        >
                            {team.members.map((member: any) => (
                                <li key={nanoid()}>
                                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                        <div>
                                            <img className="object-cover shadow-lg rounded-lg w-44 h-44"
                                                 src={member.img.data.attributes.url}
                                                 alt={member.img.data.attributes.alternativeText}/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="space-y-4">
                                                <div className="text-lg leading-6 font-medium space-y-1">
                                                    <Link to={"/" + TEAM_ROUTE + member.identifier}>
                                                        <h3 className={"font-bold text-xl mb-2 font-serif font-bold hover:underline"}>{member.heading}</h3>
                                                    </Link>

                                                    <p className="text-btn-main text-base font-serif font-bold">{member.role}</p>
                                                </div>
                                                <CkEditorDiv className="text-base">
                                                    <span
                                                        className="text-gray-500">{parse(DOMPurify.sanitize(member.mainPageText))}</span>
                                                </CkEditorDiv>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Team;